.project-admin-con {
  position: fixed;
  left: 0px;
  width: 203vh;
}
.project-user-con {
  position: fixed;
  left: 0px;
  width: 203vh;
  top: 16%;
}
.navbar-closed {
  left: -4%;
  width: 195vh;
}
.navbar-open {
  left: 0%;
  width: 195vh;
}

.project-container {
  position: absolute;
  top: 686%;
  left: 3.6%;
  height: 76.5vh;
  width: 96.5vw;
  overflow: auto;
  padding-bottom: 30px;
  background-color: transparent;
}

.group-project-container {
  height: 771px;
  width: 100%;
  overflow: auto;
  padding-bottom: 30px;
  top: 12px;
  background-color: transparent;
  left: 0px;
  position: relative;
}
.group-project-container-close {
  height: 771px;
  width: 100%;
  overflow: auto;
  padding-bottom: 30px;
  top: 12px;
  background-color: transparent;
  left: 0px;
  position: relative;
}
.add-group-heading {
  font-weight: 600;
  cursor: pointer;
  margin-bottom: 10px;
  height: auto;
  width: auto;
  padding: 7px 10px;
  border: none;
  color: white;
  background-color: #6200ee;
  border-radius: 3px;
  position: fixed;
  top: 4%;
  left: 92.8%;
  margin-top: 80px;
  font-family: "Lato", sans-serif;
  font-size: 13px;
}
.project-container-close {
  position: absolute;
  top: 686%;
  left: 4.4%;
  height: 76.5vh;
  width: 99.8vw;
  overflow: auto;
  padding-bottom: 30px;
  background-color: transparent;
}
.select-projectlist {
  color: #010101;
  font-weight: 400;
  font-style: normal;
  margin-right: 16px;
  text-decoration: none;
  -webkit-user-select: none;
  user-select: none;
  position: relative;
  top: 2px;
  font-size: 14px;
  background-color: transparent;
}
.project-content-table {
  width: 98%;
  border-collapse: collapse;
  margin: 14px 0;
  position: absolute;
  top: -1%;
  left: 16px;
  margin-right: 15px;
  border-radius: 3px;
}
.project-content-table thead th {
  background-color: white;
  color: black;
  /* font-weight: 400; */
  text-wrap: nowrap;
  user-select: none;
  text-align: left;
  padding: 10px;
  position: relative;
  border: 1px solid #ddd;
}
.project-content-table th {
  border-bottom: 0.5px solid #ddd;
}
.project-content-table thead th .resize-handle {
  position: absolute;
  right: 0;
  top: 0;
  width: 15px;
  height: 100%;
  cursor: col-resize;
  background-color: transparent;
}
.project-content-table tbody tr {
  border: 1px solid #ddd;
  background-color: white;
  table-layout: fixed;
}
.edit-project-btn {
  background-color: transparent;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 10px;
  padding-left: 10px;
  border: none;
  border-radius: 7px;
  color: blue;
}
.project-content-table tbody td {
  padding: 12px;
  border: 1px solid #ddd;
  text-align: left;
  white-space: nowrap;
}
.project-content-table::-webkit-scrollbar {
  display: none;
}
.project-list-table {
  width: 123%;
  border-collapse: collapse;
  margin: 14px 0;
  position: absolute;
  top: -1%;
  left: 16px;
  margin-right: 15px;
  border-radius: 3px;
}
.project-list-table thead th {
  background-color: white;
  color: black;
  /* font-weight: 400; */
  text-wrap: nowrap;
  user-select: none;
  text-align: left;
  padding: 10px;
  position: relative;
  border: 1px solid #ddd;
  padding-top: 6px;
  padding-bottom: 6px;
}
.project-list-table th {
  border-bottom: 0.5px solid #ddd;
}
.project-list-table thead th .resize-handle {
  position: absolute;
  right: 0;
  top: 0;
  width: 15px;
  height: 100%;
  cursor: col-resize;
  background-color: transparent;
}
.project-list-table tbody tr {
  border: 1px solid #ddd;
  background-color: white;
  table-layout: fixed;
}
.project-list-table tbody td {
  border: 1px solid #ddd;
  text-align: left;
  white-space: nowrap;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 14px;
}
/* .project-list-table-close {
  width: 118.3%;
  border-collapse: collapse;
  margin: 14px 0;
  position: absolute;
  top: -1%;
  left: 16px;
  margin-right: 15px;
  border-radius: 3px;
}
.project-list-table-close thead th {
  background-color: white;
  color: black;
  text-wrap: nowrap;
  user-select: none;
  text-align: left;
  padding: 10px;
  position: relative;
  border: 1px solid #ddd;
}
.project-list-table-close th {
  border-bottom: 0.5px solid #ddd;
}
.project-list-table-close thead th .resize-handle {
  position: absolute;
  right: 0;
  top: 0;
  width: 15px;
  height: 100%;
  cursor: col-resize;
  background-color: transparent;
}
.project-list-table-close tbody tr {
  border: 1px solid #ddd;
  background-color: white;
  table-layout: fixed;
}
.project-list-table-close tbody td {
  border: 1px solid #ddd;
  text-align: left;
  white-space: nowrap;
  padding-left: 12px;
  padding-right: 12px;
} */

.project-list-table-close {
  width: 123%;
  border-collapse: collapse;
  margin: 14px 0;
  position: absolute;
  top: -1%;
  left: 16px;
  margin-right: 15px;
  border-radius: 3px;
}
.project-list-table-close thead th {
  background-color: white;
  color: black;
  text-wrap: nowrap;
  user-select: none;
  text-align: left;
  padding: 10px;
  position: relative;
  border: 1px solid #ddd;
  padding-top: 6px;
  padding-bottom: 6px;
}
.project-list-table-close th {
  border-bottom: 0.5px solid #ddd;
}
.project-list-table-close thead th .resize-handle {
  position: absolute;
  right: 0;
  top: 0;
  width: 15px;
  height: 100%;
  cursor: col-resize;
  background-color: transparent;
}
.project-list-table-close tbody tr {
  border: 1px solid #ddd;
  background-color: white;
  table-layout: fixed;
}
.project-list-table-close tbody td {
  border: 1px solid #ddd;
  text-align: left;
  white-space: nowrap;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 14px;
}
.project-list-table-close thead {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
  border-bottom: 2px solid rgb(217, 226, 236);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
/* 
.project-list-table-close {
  width: 118.3%;
  border-collapse: collapse;
  margin: 14px 0;
  position: absolute;
  top: -1%;
  left: 16px;
  margin-right: 15px;
  border-radius: 3px;
}
.project-list-table-close thead th {
  background-color: white;
  color: black;
  text-wrap: nowrap;
  user-select: none;
  text-align: left;
  padding: 10px;
  position: relative;
  border: 1px solid #ddd;
}
.project-list-table-close th {
  border-bottom: 0.5px solid #ddd;
}
.project-list-table-close thead th .resize-handle {
  position: absolute;
  right: 0;
  top: 0;
  width: 15px;
  height: 100%;
  cursor: col-resize;
  background-color: transparent;
}
.project-list-table-close tbody tr {
  border: 1px solid #ddd;
  background-color: white;
  table-layout: fixed;
}
.project-list-table-close tbody td {
  border: 1px solid #ddd;
  text-align: left;
  white-space: nowrap;
  padding-left: 12px;
  padding-right: 12px;
}
.project-list-table-close thead {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
  border-bottom: 2px solid rgb(217, 226, 236);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
} */

.project-list-table thead {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
  border-bottom: 2px solid rgb(217, 226, 236);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.add-project-container {
  display: flex;
  justify-content: flex-end;
  margin-right: 55px;
  position: relative;
  top: 55px;
  left: -190px;
}
.add-proj-heading {
  font-weight: 600;
  cursor: pointer;
  margin-bottom: 10px;
  height: auto;
  width: auto;
  padding: 7px 10px;
  border: none;
  color: white;
  background-color: #6200ee;
  border-radius: 3px;
  position: fixed;
  top: -2%;
  left: 91.8%;
  margin-top: 80px;
  font-family: "Lato", sans-serif;
  font-size: 13px;
}
.add-proj-heading-close {
  font-weight: 600;
  cursor: pointer;
  margin-bottom: 10px;
  height: auto;
  width: auto;
  padding: 7px 10px;
  border: none;
  color: white;
  background-color: #6200ee;
  border-radius: 3px;
  position: fixed;
  top: -3%;
  left: 97%;
  margin-top: 89px;
  margin-left: -99px;
  font-family: "Lato", sans-serif;
  font-size: 13px;
}
.add-exp-heading {
  left: 85%;
  /* height: 31px; */
  width: auto;
  padding: 5px;
  text-align: center;
  border: 1px solid #6200ee;
  color: #6200ee;
  border-radius: 3px;
  margin-right: 60px;
  /* margin-top: 53px; */
  text-wrap: nowrap;
  font-weight: 800;
  cursor: pointer;
  font-family: "Lato", sans-serif;
  font-size: 15px;
  position: fixed;
  top: 6.5%;
}
.add-exp-heading-close {
  left: 85%;
  /* height: 31px; */
  width: auto;
  padding: 5px;
  text-align: center;
  border: 1px solid #6200ee;
  color: #6200ee;
  border-radius: 3px;
  margin-right: 60px;
  /* margin-top: 53px; */
  text-wrap: nowrap;
  font-weight: 800;
  cursor: pointer;
  font-family: "Lato", sans-serif;
  font-size: 15px;
  position: fixed;
  top: 6.5%;
}
.delete-proj-heading {
  font-weight: 600;
  cursor: pointer;
  margin-bottom: 10px;
  height: auto;
  width: auto;
  padding: 7px 10px;
  border: none;
  color: white;
  background-color: #6200ee;
  border-radius: 3px;
  position: fixed;
  top: 0%;
  left: 81%;
  margin-top: 61px;
  font-family: "Lato", sans-serif;
  font-size: 13px;
}
.delete-proj-heading-close {
  font-weight: 600;
  cursor: pointer;
  margin-bottom: 10px;
  height: auto;
  width: auto;
  padding: 7px 10px;
  border: none;
  color: white;
  background-color: #6200ee;
  border-radius: 3px;
  position: fixed;
  top: 0%;
  left: 79.95%;
  margin-top: 61px;
  margin-left: 20px;
  font-family: "Lato", sans-serif;
  font-size: 13px;
}

@keyframes slideInRight {
  from {
    transform: translateX(100%); /* Start from right */
    opacity: 0;
  }
  to {
    transform: translateX(0); /* End in place with existing transform */
    opacity: 1;
  }
}
@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.add-project-dial {
  position: fixed;
  text-align: left;
  top: 5.4%;
  left: 0%;
  z-index: 16;
  transform: translate(-50%, -20%);
  padding-bottom: 0px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 95%;
  height: 91.3%;
  border-radius: 3px;
  /* animation: slideInRight 1s ease-out; */
  animation: slideIn 0.5s forwards;
  background: rgba(0, 0, 0, 0.7);
}
.add-new-project-content {
  position: relative;
  left: 70%;
  padding-left: 20px;
  height: 102%;
}
.add-new-project-button-container {
  border-top: 1px solid #616161;
  padding-top: 0px;
  position: fixed;
  top: 93%;
  height: 56px;
  width: 99.9%;
  left: 1297px;
}
.add-project-content {
  width: 101%;
  padding-bottom: 10px;
  height: 95.61%;
  overflow-y: auto;
}
.add-project-content::-webkit-scrollbar {
  display: none;
}

.add-project-user-heading {
  color: black;
  margin-top: 0px;
  margin-bottom: 1px;
  font-weight: 500;
  font-size: 16px;
  background-color: transparent;
}
.add-project-user-header {
  color: black;
  position: fixed;
  margin-top: -20px;
  margin-bottom: 0px;
  font-weight: 500;
  font-size: 16px;
  padding: 1px;
  background-color: #f5f5f5;
  width: 100%;
  margin-left: -20px;
  padding: 4px;
  z-index: 1;
}
.box-heading {
  color: black;
  font-size: 12px;
  font-weight: 400;
  font-size: 18px;
  background-color: transparent;
  margin-bottom: 5px;
}
.box-p-heading {
  color: black;
  font-size: 12px;
  margin-left: 25px;
  font-weight: 400;
  font-size: 16px;
  background-color: transparent;
  margin-top: -1px;
  margin-bottom: 5px;
}
.box-l-heading {
  color: black;
  font-size: 12px;
  margin-top: 1px;
  margin-left: 5px;
  font-weight: 500;
  font-size: 16px;
  background-color: transparent;
  margin-bottom: 5px;
}
.label-heading {
  color: black;
  font-size: 12px;
  background-color: transparent;
  margin-bottom: 5px;
}
.billing-select {
  background-color: none;
}
.start-date-due-date-container {
  display: flex;
  flex-direction: row;
  background-color: transparent;
  margin-bottom: 5px;
}
.project-name-number-container {
  display: flex;
  flex-direction: row;
  background-color: transparent;
}
.name-container {
  background-color: transparent;
}
.start-dat-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: transparent;
}
.due-date-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: transparent;
  margin-left: 14px;
}
.project-owner-budget-container {
  display: flex;
  flex-direction: row;
  background-color: transparent;
  margin-top: -5px;
}
.template-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 29px;
  width: 174px;
}
.owner-dropdown {
  background-color: white;
}
.box-input,
.select-input {
  padding-left: 5px;
  border: 0.5px solid;
  margin-bottom: 5px;
  background-color: white;
  border-radius: 3px;
  height: 25px;
  width: 272px;
  font-family: "Lato", sans-serif;
  font-size: 15px;
}
.box-des-input {
  padding-left: 5px;
  border: 0.5px solid;
  margin-bottom: 5px;
  background-color: white;
  border-radius: 3px;
  /* width: 566px;
  height: 51px; */
  width: 271px;
  height: 21px;
  font-family: "Lato", sans-serif;
  font-size: 15px;
  max-width: 271px;
  max-height: 100px;
}
.select-d-input {
  padding-left: 5px;
  border: 0.5px solid;
  margin-bottom: 5px;
  background-color: white;
  border-radius: 3px;
  height: 30px;
  width: 281px;
  font-family: "Lato", sans-serif;
  font-size: 15px;
  padding-bottom: 2px;
  cursor: pointer;
}
.owner-template-container {
  display: flex;
  flex-direction: row;
  background-color: transparent;
  margin-bottom: 5px;
}
.owner-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: transparent;
}
.template-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: transparent;
  margin-left: 18px;
}
.project-strict-container {
  display: flex;
  flex-direction: row;
  background-color: transparent;
  margin-bottom: 5px;
}
.box-heading-label {
  color: black;
  font-size: 15px;
  background-color: transparent;
  margin-bottom: 5px;
  margin-left: 10px;
}
.add-description-container {
  flex-direction: column;
  background-color: transparent;
  margin-bottom: 5px;
}
.project-select-container {
  display: flex;
  flex-direction: column;
  background-color: transparent;
  margin-bottom: 10px;
  margin-top: -12px;
}
.project-new-project-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: transparent;
}
.tags-container {
  flex-direction: column;
  background-color: transparent;
  margin-bottom: 5px;
  margin-left: 15px;
  margin-top: -1px;
}
.add-project-hr-line {
  margin-bottom: 10px;
}
.project-details-container {
  display: flex;
  flex-direction: column;
  background-color: transparent;
  margin-bottom: 10px;
  margin-left: 1px;
}
.rmsc .member-select-option {
  position: relative;
  outline: 0;
  background-color: white;
  border: 1px solid;
  border-radius: 3px;
  width: 670px;
  margin-left: -1px;
}
.currency-billing-method-container {
  display: flex;
  flex-direction: row;
  background-color: transparent;
  margin-bottom: 5px;
}
.currency-container {
  display: flex;
  flex-direction: column;
  background-color: transparent;
}
.billing-method-container {
  display: flex;
  flex-direction: column;
  background-color: transparent;
  margin-left: 18px;
}
.budget-container {
  background-color: transparent;
}
.customize-row-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: tr;
  background-color: transparent;
}
.customize-container {
  justify-content: space-around;
  background-color: transparent;
}
.individual-task-container {
  background-color: white;
  border: 1px solid #616161;
  padding: 10px;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 5px;
  width: 110px;
}
.check-box-content {
  margin-left: 20px;
}

.privacy-container {
  background-color: transparent;
}
.member-select-option .dropdown-container {
  position: relative;
  outline: 0;
  background-color: white;
  border: 1px solid;
  border-radius: 3px;
  width: 100%;
  margin-left: -1px;
  max-width: 100%;
}
.dropdown-content {
  background-color: transparent;
  margin-left: -7px;
}
.dropdown-heading,
.dropdown-heading-value,
.gray {
  background-color: white;
}
html::-webkit-scrollbar,
body::-webkit-scrollbar {
  display: none;
}
.edit-proj-dial {
  position: fixed;
  text-align: left;
  top: 4.7%;
  left: -0.1%;
  z-index: 2;
  transform: translate(-50%, -20%);
  padding-bottom: 0px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 98%;
  height: 91.9%;
  border-radius: 3px;
  animation: slideIn 0.5s forwards;
  background-color: rgba(0, 0, 0, 0.7);
}
.edit-project-user-header1 {
  color: black;
  position: fixed;
  margin-top: -30px;
  margin-left: -30px;
  margin-bottom: 0px;
  font-weight: 500;
  font-size: 16px;
  padding: 1px;
  background-color: #f5f5f5;
  width: 100%;
  padding: 4px;
  z-index: 1;
}
.dialog-title {
  background-color: transparent;
  margin-bottom: 0px;
  margin-top: 0px;
  margin-left: 20px;
  font-weight: 600;
}

.edit-proj-btn-con {
  border-top: 1px solid #616161;
  padding-top: 0px;
  position: fixed;
  top: 93%;
  height: 46px;
  width: 99.9%;
  left: 1293px;
  background-color: white;
}
.edit-input {
  background-color: white;
  border: 0.5px solid;
  border-radius: 3px;
  font-family: sans-serif;
  margin-left: -6px;
  margin-top: 3px;
  font-family: "Lato", sans-serif;
  font-size: 15px;
  height: 30px;

  padding-left: 5px;
}
.edit-proj-label {
  font-weight: 400;
  margin-bottom: 3px;
  margin-left: -6px;
}
.edit-description {
  min-height: 50px;
  font-family: sans-serif;
  font-size: 14px;
  width: auto;
  background-color: white;
  padding: 5px;
  border-radius: 3px;
  margin-left: -6px;
  margin-top: 3px;
  font-family: "Lato", sans-serif;
  font-size: 15px;
  max-width: 577px;
  max-height: 150px;
}
.delete-header {
  background-color: #f5f5f5;
  margin-top: -30px;
  margin-left: -15px;
  width: 106%;
  height: 26px;
}
.project-nav-bar-details {
  position: fixed;
  margin-left: 92px;
  top: 5.4%;
  width: 100%;
}
.projectlist-nav-bar-details {
  position: fixed;
  margin-left: 92px;
  top: 7.4%;
  width: 100%;
}
.projectlist-nav-bar-details-close {
  position: fixed;
  margin-left: 100px;
  top: 7.4%;
  width: 100%;
}
.proj-links {
  text-decoration: none;
  color: black;
  margin-right: 25px;
}
.proj-links.active {
  color: #6200ee;
  font-weight: 600;
}
.proj-line {
  margin-left: -13px;
  border-top: 1px solid;
}
.group-name {
  text-decoration: none;
  color: black;
}
.group-name:hover {
  color: #6200ee;
}
.group-main-container {
  height: 735px;
}

.edit-icon-wrapper {
  visibility: hidden;
  display: inline-block;
  cursor: pointer;
}

td:hover .edit-icon-wrapper {
  visibility: visible;
}
.upload-project-container {
  top: -122px;
  left: 1340px;
  background-color: transparent;
  position: relative;
}
.pagination-controls {
  /*top: 902px; */
  position: fixed;
  /* left: 1545px; */
  display: flex;
  height: 40px;
  background-color: transparent;
  bottom: 0.5rem;
  right: 1rem;
}
.pagination-controls-user {
  /* top: 902px; */
  position: fixed;
  /* left: 1500px; */
  display: flex;
  height: 40px;
  background-color: transparent;
  bottom: 0.5rem;
  right: 1rem;
}
.arrow-project-btn {
  border: none;
  margin-top: 17px;
  cursor: pointer;
}
.dialog-edit-content {
  display: flex;
  flex-direction: column;
  padding: 15px;
  overflow-y: scroll;
  height: 824px;
  padding-bottom: 52px;
  margin-left: 1273px;
  width: 31%;
  padding-left: 30px;
  padding-right: 20px;
}
.dialog-edit-content::-webkit-scrollbar {
  display: none;
}
.project-search {
  border: 0.5px solid;
  border-radius: 3px;
  width: 300px;
  height: 30px;
  margin-left: 87px;
  padding-left: 15px;
  position: fixed;
  font-family: "Lato", sans-serif;
  font-size: 13px;
  top: 110px;
}
.project-search-close {
  border: 0.5px solid;
  border-radius: 3px;
  width: 300px;
  height: 30px;
  margin-left: 97px;
  padding-left: 15px;
  position: fixed;
  font-family: "Lato", sans-serif;
  font-size: 13px;
  top: 110px;
}
.column-names {
  position: relative;
  left: 91px;
  top: 27px;
  width: 272px;
}
.column-icon {
  /* position: fixed; */
  left: 97.8%;
  height: 19px;
  /* width: 30px; */
  /* top: 20.4%; */
  z-index: 2;
  cursor: pointer;
  background-color: transparent;
}

.project-column-swap {
  cursor: pointer;
}

.close-column-icon {
  position: fixed;
  left: 1891px;
  height: 20px;
  width: 20px;
  color: #6200ee;
  cursor: pointer;
  z-index: 2;
  top: 15px;
  background-color: transparent;
}

.hide-column {
  border: none;
  margin-left: 5px;
  background-color: transparent;
}
.hide-icon {
  height: 20px;
  width: 20px;
  position: relative;
  top: 5px;
  background-color: transparent;
}
.hide-column-span {
  padding: 5px;
}
.hide-column-span:hover {
  background-color: #ddd;
}
.group-btn-container {
  border-top: 0.5px solid;
  margin-top: 510px;
}
.project-column-hide {
  position: fixed;
  text-align: left;
  top: 3.9%;
  left: -0.6%;
  z-index: 2;
  transform: translate(-50%, -20%);
  padding-bottom: 0px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 92.9%;
  border-radius: 3px;
  animation: slideIn 0.5s forwards;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.7);
}
.hide-column-com {
  position: absolute;
  top: 87px;
  left: 1225px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px;
  padding: 8px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
}
/* .column-container {
  height: 39px;
  width: 56px;
  left: 97.2%;
  position: fixed;
  /* background-color: black; */
/* top: 19.4%;
  z-index: 10;
  border-left: 1px solid #ddd;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
} */
.project-column-hide-container {
  margin-left: 81.5%;
  height: 101%;
  margin-top: -0.4%;
  overflow-y: auto;
  padding-left: 10px;
  padding-right: 20px;
  padding-bottom: 10px;
}
.project-column-hide-container::-webkit-scrollbar {
  display: none;
}
.add-group-d-dial {
  position: fixed;
  text-align: left;
  left: -0.1%;
  z-index: 2;
  transform: translate(-50%, -20%);
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  animation: slideIn 0.5s forwards;
  height: 877px;
  top: 52px;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 10px;
  width: 99%;
  background-color: rgba(0, 0, 0, 0.7);
}
.proj-group-multi {
  position: relative;
  left: -4px;
  height: auto;
}
.user-profile-dp {
  background-color: rgb(108, 160, 220);
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  font-size: 17px;
  text-align: center;
  margin-top: 0px;
  font-weight: 800;
}
.user-profile-name {
  position: relative;
  left: 9px;
  top: 5px;
}
.project-group-list {
  position: relative;
  left: 76px;
  top: -13px;
}
.project-active-status {
  border: none;
  border-radius: 3px;
  padding-left: 15px;
  padding-right: 15px;
  width: 85px;
  height: 34px;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  cursor: pointer;
  background-color: transparent;
  color: rgb(0, 0, 0);
  appearance: none;
}

.project-active-status:focus {
  border-color: white;
  outline: 3px solid transparent;
  outline-offset: 2px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
}
.project-p-display-box {
  width: 281px;
  border-radius: 3px;
  background-color: grey;
  max-height: 200px;
  position: relative;
  top: -22px;
  overflow: auto;
  border: 1px solid;
}
.scheduled-date {
  border: none;
  width: 86px;
  height: 25px;
  color: rgb(0, 0, 0);
  padding-left: 5px;
  cursor: pointer;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  background-color: transparent;
}
.scheduled-date:focus {
  border-color: transparent;
  outline: 3px solid transparent;
  outline-offset: 2px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
  background-color: transparent;
}
.issue-date {
  border: none;
  width: 86px;
  height: 25px;
  color: rgb(0, 0, 0);
  padding-left: 5px;
  cursor: pointer;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  background-color: transparent;
}
.issue-date:focus {
  border-color: transparent;
  outline: 3px solid transparent;
  outline-offset: 2px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
  background-color: transparent;
}
.react-datepicker-wrapper {
  background-color: transparent !important;
}
.react-datepicker__input-container {
  background-color: transparent;
}
.project-remarks {
  height: 32px;
  width: 230px;
  border: none;
  padding-left: 15px;
  cursor: pointer;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  background-color: transparent;
  color: rgb(0, 0, 0);
}
.project-remarks:focus {
  border-color: transparent;
  outline: 3px solid transparent;
  outline-offset: 2px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
}
.priority-container {
  position: absolute;
  left: 317px;
  top: 236px;
}
.project-tags-dropdown {
  border: none;
  padding: 3px;
  border-radius: 3px;
  background-color: transparent;
  color: rgb(0, 0, 0);
  font-family: "Lato", sans-serif;
  font-size: 14px;
  appearance: none;
  width: 85px;
  height: 34px;
  text-align: center;
  cursor: pointer;
}

.project-priority-dropdown {
  border: none;
  padding: 3px;
  border-radius: 3px;
  background-color: transparent;
  color: rgb(0, 0, 0);
  font-family: "Lato", sans-serif;
  font-size: 14px;
  appearance: none;
  width: 75px;
  height: 34px;
  text-align: center;
  cursor: pointer;
}
.project-tags-dropdown:focus,
.project-priority-dropdown:focus {
  border-color: white;
  outline: 3px solid transparent;
  outline-offset: 2px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
}
.project-table-con {
  background-color: transparent;
}
.project-col-header-container {
  padding: 7px;
  border: 0.5px solid #ddd;
  margin-top: 7px;
  border-radius: 3px;
}
.project-col-header-container:hover {
  border-color: #999; /* Change border color on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add background shadow */
  cursor: pointer; /* Optional: changes the cursor to a pointer */
}
.project-col-swap-header {
  color: black;
  position: fixed;
  margin-top: 1px;
  margin-bottom: 0px;
  font-weight: 500;
  font-size: 16px;
  padding: 1px;
  background-color: #f5f5f5;
  width: 100%;
  margin-left: -10px;
  padding: 4px;
  z-index: 1;
}
.project-list-row-count {
  border: 0.5px solid rgb(255 255 255 / 0%);
  padding: 5px;
  border-radius: 3px;
  margin-top: 12px;
  text-align: center;
  font-family: "Lato", sans-serif;
  font-size: 13px;
  cursor: pointer;
  outline: none;
  background-color: transparent;
}
@media (min-width: 1440px) and (max-width: 1919px) {
  .proj-group-multi .dropdown-content {
    margin-left: -7px;
    height: auto;
    overflow: auto;
  }
  .add-group-d-dial {
    left: -0.4%;
    height: 657px;
    width: 99.9%;
  }
  .pagination-controls {
    top: 683px;
    left: 1100px;
  }
  .pagination-controls-user {
    top: 689px;
    left: 1290px;
  }
  .project-container {
    top: 155px;
    left: 74px;
    height: 505px;
    width: 95%;
  }

  .project-container-close {
    top: 155px;
    left: 6px;
    height: 505px;
    width: 99.5%;
  }

  .project-content-table {
    width: 97%;
    border-collapse: collapse;
    margin: 14px 0;
    position: absolute;
    top: -2%;
    left: 16px;
    margin-right: 15px;
    border-radius: 3px;
  }
  .project-content-table thead th {
    background-color: white;
    color: black;
    /* font-weight: 400; */
    text-wrap: nowrap;
    user-select: none;
    text-align: left;
    padding: 10px;
    position: relative;
    border: 1px solid #ddd;
  }
  .project-content-table th {
    border-bottom: 0.5px solid #ddd;
  }
  .project-content-table thead th .resize-handle {
    position: absolute;
    right: 0;
    top: 0;
    width: 15px;
    height: 100%;
    cursor: col-resize;
    background-color: transparent;
  }
  .project-content-table tbody tr {
    border: 1px solid #ddd;
    background-color: white;
    table-layout: fixed;
  }
  .add-proj-heading {
    margin-top: 76px;
  }
  .add-proj-heading-close {
    margin-top: 84px;
    margin-left: -80px;
  }
  .add-exp-heading {
    top: 8.6%;
    height: 29px;
    left: 84%;
  }
  .add-exp-heading-close {
    top: 8.5%;
    height: 29px;
    left: 84%;
  }
  .delete-proj-heading {
    margin-top: 62px;
    margin-left: -20px;
  }
  .delete-proj-heading-close {
    margin-top: 62px;
    margin-left: -4px;
  }
  .edit-proj-dial {
    position: fixed;
    text-align: left;
    top: 5.7%;
    left: -1.6%;
    z-index: 2;
    transform: translate(-50%, -20%);
    padding-bottom: 0px;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 99%;
    height: 88.9%;
    border-radius: 3px;
  }
  .edit-project-user-header {
    color: black;
    position: fixed;
    margin-top: -30px;
    margin-left: -35px;
    margin-bottom: 0px;
    font-weight: 500;
    font-size: 16px;
    padding: 1px;
    background-color: #f5f5f5;
    width: 100%;
    padding: 4px;
    z-index: 1;
  }
  .edit-project-btn {
    background-color: transparent;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 10px;
    padding-left: 10px;
    border: none;
    border-radius: 7px;
    color: blue;
  }
  .project-content-table tbody td {
    padding: 12px;
    border: 1px solid #ddd;
    text-align: left;
    white-space: nowrap;
  }

  .add-project-container {
    display: flex;
    justify-content: flex-end;
    margin-right: 55px;
    position: relative;
    top: 55px;
    left: -190px;
  }

  .add-project-dial {
    position: fixed;
    text-align: left;
    top: 7%;
    left: -0.6%;
    z-index: 16;
    transform: translate(-50%, -20%);
    padding-bottom: 0px;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 75%;
    height: 87.8%;
    border-radius: 3px;
  }
  .add-project-content {
    width: 100%;
    padding-bottom: 10px;
    height: 95.61%;
    overflow-y: auto;
  }
  .add-project-content::-webkit-scrollbar {
    display: none;
  }
  .add-new-project-content {
    width: 93%;
    padding-bottom: 10px;
    height: 100%;
    position: fixed;
  }
  .add-new-project-content::-webkit-scrollbar {
    display: none;
  }
  .add-new-project-button-container {
    border-top: 1px solid #616161;
    padding-top: 0px;
    position: fixed;
    top: 92%;
    height: 56px;
    width: 99.9%;
    left: 835px;
  }
  .add-project-user-heading {
    color: black;
    margin-top: 0px;
    margin-bottom: 1px;
    font-weight: 500;
    font-size: 16px;
    background-color: transparent;
  }
  .add-project-user-header {
    color: black;
    position: fixed;
    margin-top: -20px;
    margin-bottom: 0px;
    font-weight: 500;
    font-size: 16px;
    padding: 1px;
    background-color: #f5f5f5;
    width: 100%;
    margin-left: -20px;
    padding: 4px;
    z-index: 1;
  }
  .box-heading {
    font-size: 16px;
    margin-bottom: 5px;
  }
  .box-p-heading {
    color: black;
    font-size: 12px;
    margin-left: 25px;
    font-weight: 400;
    font-size: 16px;
    background-color: transparent;
    margin-top: -1px;
    margin-bottom: 5px;
  }
  .box-l-heading {
    color: black;
    font-size: 12px;
    margin-top: 1px;
    margin-left: 5px;
    font-weight: 500;
    font-size: 16px;
    background-color: transparent;
    margin-bottom: 5px;
  }
  .label-heading {
    color: black;
    font-size: 12px;
    background-color: transparent;
    margin-bottom: 5px;
  }
  .billing-select {
    background-color: none;
  }
  .start-date-due-date-container {
    display: flex;
    flex-direction: row;
    background-color: transparent;
    margin-bottom: 5px;
  }
  .project-name-number-container {
    display: flex;
    flex-direction: row;
    background-color: transparent;
  }
  .name-container {
    background-color: transparent;
  }
  .start-dat-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: transparent;
  }
  .due-date-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: transparent;
    margin-left: 14px;
  }
  .project-owner-budget-container {
    display: flex;
    flex-direction: row;
    background-color: transparent;
    margin-top: -5px;
  }
  .template-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 29px;
    width: 174px;
  }
  .owner-dropdown {
    background-color: white;
  }
  .box-input,
  .select-input {
    padding-left: 5px;
    border: 0.5px solid;
    margin-bottom: 5px;
    background-color: white;
    border-radius: 3px;
    height: 30px;
    width: 320px;
  }
  .box-des-input {
    padding-left: 5px;
    border: 0.5px solid;
    margin-bottom: 5px;
    background-color: white;
    border-radius: 3px;
    width: 323px;
    height: 28px;
    max-width: 323px;
    max-height: 100px;
  }
  .select-d-input {
    padding-left: 5px;
    border: 0.5px solid;
    margin-bottom: 5px;
    background-color: white;
    border-radius: 3px;
    height: 30px;
    width: 327px;
  }
  .owner-template-container {
    display: flex;
    flex-direction: row;
    background-color: transparent;
    margin-bottom: 5px;
  }
  .owner-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: transparent;
  }
  .template-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: transparent;
    margin-left: 18px;
  }
  .project-strict-container {
    display: flex;
    flex-direction: row;
    background-color: transparent;
    margin-bottom: 5px;
  }
  .box-heading-label {
    color: black;
    font-size: 15px;
    background-color: transparent;
    margin-bottom: 5px;
    margin-left: 10px;
  }
  .add-description-container {
    flex-direction: column;
    background-color: transparent;
    margin-bottom: 5px;
  }
  .project-select-container {
    display: flex;
    flex-direction: column;
    background-color: transparent;
    margin-bottom: 10px;
    margin-top: -12px;
  }
  .project-new-project-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: transparent;
  }
  .tags-container {
    flex-direction: column;
    background-color: transparent;
    margin-bottom: 5px;
    margin-left: 15px;
    margin-top: -1px;
  }
  .add-project-hr-line {
    margin-bottom: 10px;
  }
  .project-details-container {
    display: flex;
    flex-direction: column;
    background-color: transparent;
    margin-bottom: 10px;
    margin-left: 1px;
  }
  .rmsc .member-select-option {
    position: relative;
    outline: 0;
    background-color: white;
    border: 1px solid;
    border-radius: 3px;
    width: 670px;
    margin-left: -1px;
  }
  .currency-billing-method-container {
    display: flex;
    flex-direction: row;
    background-color: transparent;
    margin-bottom: 5px;
  }
  .currency-container {
    display: flex;
    flex-direction: column;
    background-color: transparent;
  }
  .billing-method-container {
    display: flex;
    flex-direction: column;
    background-color: transparent;
    margin-left: 18px;
  }
  .budget-container {
    background-color: transparent;
  }
  .customize-row-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background-color: tr;
    background-color: transparent;
  }
  .customize-container {
    justify-content: space-around;
    background-color: transparent;
  }
  .individual-task-container {
    background-color: white;
    border: 1px solid #616161;
    padding: 10px;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 5px;
    width: 110px;
  }
  .check-box-content {
    margin-left: 20px;
  }

  .privacy-container {
    background-color: transparent;
  }
  .member-select-option .dropdown-container {
    position: relative;
    outline: 0;
    background-color: white;
    border: 1px solid;
    border-radius: 3px;
    width: 100%;
    margin-left: -1px;
  }
  .member-select-option .dropdown-content {
    margin-left: -7px;
    height: 100px;
    overflow: auto;
  }
  .dropdown-content::-webkit-scrollbar {
    display: none;
  }
  .dropdown-heading,
  .dropdown-heading-value,
  .gray {
    background-color: white;
  }
  html::-webkit-scrollbar,
  body::-webkit-scrollbar {
    display: none;
  }

  .dialog-title {
    background-color: transparent;
    margin-bottom: 0px;
    margin-top: 0px;
    margin-left: 20px;
    font-weight: 600;
  }
  .dialog-edit-content {
    max-height: 572px;
    position: relative;
    left: -245px;
  }
  .dialog-edit-content::-webkit-scrollbar {
    display: none;
  }
  .edit-proj-btn-con {
    border-top: 1px solid #616161;
    padding-top: 0px;
    position: fixed;
    top: 93%;
    height: 46px;
    width: 99.9%;
    left: 1048px;
    background-color: white;
  }
  .edit-input {
    background-color: white;
    padding: 5px;
    border: 0.5px solid;
    border-radius: 3px;
    font-family: sans-serif;
  }
  .edit-proj-label {
    font-weight: 400;
  }
  .edit-description {
    min-height: 50px;
    font-family: sans-serif;
    font-size: 14px;
    width: auto;
    background-color: white;
    padding: 10px;
    border-radius: 3px;
    max-width: 456px;
  }

  .project-nav-bar-details {
    position: fixed;
    margin-left: 92px;
    top: 5.4%;
    width: 100%;
  }
  .proj-links {
    text-decoration: none;
    color: black;
    margin-right: 25px;
  }
  .proj-links.active {
    color: #6200ee;
    font-weight: 600;
  }
  .proj-line {
    margin-left: -13px;
    border-top: 1px solid;
  }
  .group-name {
    text-decoration: none;
    color: black;
  }
  .group-name:hover {
    color: #6200ee;
  }
  .group-main-container {
    height: 735px;
  }

  .edit-icon-wrapper {
    visibility: hidden;
    display: inline-block;
  }

  td:hover .edit-icon-wrapper {
    visibility: visible;
  }
  .close-column-icon {
    left: 1512px;
    top: 25px;
  }
  .group-btn-container {
    margin-top: 240px;
    border-top: 0.5px solid;
  }
  .add-group-heading {
    left: 91.8%;
  }
  /* .column-container {
    top: 23%;
  } */
  .column-icon {
    top: 24.4%;
  }
  .delete-header {
    background-color: #f5f5f5;
    margin-top: -30px;
    margin-left: -15px;
    width: 108%;
  }
  .project-column-hide-container {
    height: 100.1%;
    overflow-x: auto;
    margin-left: 79.5%;
  }
  .priority-container {
    left: 362px;
  }
  .project-col-swap-header {
    margin-top: 7px;
    width: 19%;
  }
  .projectlist-nav-bar-details {
    top: 9.4%;
  }
}
