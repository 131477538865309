.project-navbar {
  width: 100%;
  left: 79px;
  position: fixed;
  margin-top: 58px;
  top: 32px;
  height: 30px;
  padding: 5px;
  text-align: left;
  font-size: var(--text-md-medium-size);
  color: var(--black);
  font-family: var(--body-text-body-text-7);
  margin-left: 0px;
}
.project-navbar-close {
  width: 100%;
  left: 0px;
  position: fixed;
  margin-top: 58px;
  top: 32px;
  height: 30px;
  padding: 5px;
  text-align: left;
  font-size: var(--text-md-medium-size);
  color: var(--black);
  font-family: var(--body-text-body-text-7);
  margin-left: 0px;
}
.project-name-container {
  position: fixed;
  display: flex;
  top: 49px;
  width: 100%;
  left: 91px;
  justify-content: flex-start;
  gap: var(--gap-base);
  text-align: center;
  color: var(--gray-2);
}
.project-name-container-close {
  position: fixed;
  display: flex;
  top: 49px;
  width: 100%;
  left: 22px;
  justify-content: flex-start;
  gap: var(--gap-base);
  text-align: center;
  color: var(--gray-2);
}
.project-layout-name {
  font-size: 16px;
  color: #010101;
  font-weight: 600;
  margin-right: 16px;
}
.open-details-btn {
  display: inline-flex;
  padding: 8px;
  align-items: center;
  border-radius: 3px;
  border: 1px solid #6200ee;
  background-color: transparent;
  color: #6200ee;
  font-size: 13px;
  text-wrap: nowrap;
  font-weight: 500;
  height: 30px;
  width: auto;
  margin-top: 7px;
  cursor: pointer;
}
.meatballs-button {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  width: 24px;
  position: relative;
  height: 38px;
  margin-left: 16px;
  font-weight: 900;
  font-family: "Lato", sans-serif;
  font-size: 15px;
  cursor: pointer;
}
.project-layout-parent {
  position: fixed;
  top: 86px;
  left: 76px;
  height: 43px;
  width: 100%;
  justify-content: flex-start;
  gap: var(--gap-base);
  text-align: center;
  color: var(--gray-2);
  display: flex;
  flex-direction: row;
  align-items: center;
}
.project-layout-parent-close {
  position: fixed;
  top: 86px;
  left: 6px;
  height: 43px;
  width: 100%;
  justify-content: flex-start;
  gap: var(--gap-base);
  text-align: center;
  color: var(--gray-2);
  display: flex;
  flex-direction: row;
  align-items: center;
}
.dashboard {
  margin: 0;
  color: black;
  font-weight: 400;
  margin-left: 23px;
  font-size: 16px;
  color: var(--black);
  text-align: left;
  cursor: pointer;
}
.layout-name {
  color: black;
  font-weight: 400;
  font-size: 16px;
  margin-left: 16px;
  text-decoration: none;
}
.layout-name.active {
  color: #6200ee;
  font-weight: 600;
}
.horizontal-project-layout-line {
  top: 31px;
  left: -19px;
  position: absolute;
  border-top: 1px solid;
  width: 102%;
}
.project-individual-details-container {
  box-shadow: 5px 5px 14px 1px rgba(0, 0, 0, 0.25);
  width: 1756px;
  margin-top: 30px;
  margin-right: 40px;
  margin-left: 15px;
  padding: 14px;
  border-radius: 7px;
}
.project-individual-details {
  position: fixed;
  bottom: 80px;
  top: 90px;
  height: 100%;
  padding-bottom: 59px;
  margin-left: 80px;
}
.project-individual-details-close {
  position: fixed;
  bottom: 80px;
  top: 90px;
  height: 100%;
  padding-bottom: 59px;
  left: 6px;
}
.left-right-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: -44px;
}
.left-container {
  margin-left: 140px;
  background-color: transparent;
}
.name-btn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.proj-name {
  position: relative;
  margin-left: -125px;
  margin-top: 24px;
  font-size: 18px;
  font-weight: 500;
  display: flex;
}
.information-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  margin-top: 45px;
  margin-left: -126px;
  background-color: transparent;
}
.completed {
  margin-top: -6px;
  padding: 7px 14px;
  background-color: #6ca0dc;
  color: black;
  width: auto;
  border-radius: 3px;
  font-size: 16px;
  text-wrap: nowrap;
  font-weight: 500;
  border: none;
  position: relative;
  left: 10px;
  height: auto;
}

.info-icon {
  margin-left: 30px;
  margin-top: 18px;
  color: #6200ee;
  width: 24px;
  height: 24px;
  background-color: transparent;
}
.right-container {
  margin-top: 15px;
  margin-right: 30px;
}
.close-btn {
  height: 30px;
  width: 30px;
  color: #6200ee;
  left: 1744px;
  position: relative;
  top: -82px;
  cursor: pointer;
}
.close-btn-close {
  height: 30px;
  width: 30px;
  color: #6200ee;
  left: 1817px;
  position: relative;
  top: -82px;
  cursor: pointer;
}
.description-container {
  display: flex;
  flex-direction: column;
  padding: 14px;
  margin-top: 43px;
  margin-left: 15px;
  margin-right: 40px;
  text-align: left;
  justify-content: flex-start;
  box-shadow: 5px 5px 14px 1px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  width: 1756px;
}
.description-heading {
  font-weight: 400;
  text-align: left;
  margin-top: 0px;
}

.description-para {
  border-radius: 3px;
  margin-top: 5px;
  text-align: left;
  width: 400px;
  height: 70px;
  padding: 5px;
  border: 0.5px solid;
  max-width: 1520px;
  max-height: 250px;
  font-family: "Lato", sans-serif;
  font-size: 15px;
}
.project-table-line {
  margin-top: 10px;
  margin-left: -139px;
  border: 0.5px solid #a3a3a3;
  width: 110%;
}
.project-information-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 7px;
  margin-left: 15px;
  margin-right: 40px;
  margin-top: 50px;
  padding: 14px;
  box-shadow: 5px 5px 14px 1px rgba(0, 0, 0, 0.25);
  width: 1756px;
}
.proj-info-heading {
  margin-top: 25px;
  text-align: left;
  color: #010101;
  font-weight: 500;
}

.project-owner-status-container,
.tags-completed-date-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.project-owner-container,
.project-status-container,
.project-tags-container,
.project-completed-date-container {
  flex: 1; /* Each container takes up equal space */
  margin-right: 10px; /* Optional: adds some spacing between them */
  margin-bottom: 5px;
}

.project-owner-container {
  display: flex;
  margin-top: 15px;
}
.project-status-container {
  display: flex;
  margin-right: 0px;
}
.owner-heading {
  color: black;
  font-weight: 400;
  margin-right: 103px;
  margin-top: 0px;
}

.status-heading {
  color: black;
  font-weight: 400;
  margin-right: 103px;
  margin-top: 3px;
}
.status-options-dropdown {
  margin-top: 0px;
  margin-right: 120px;
  height: 30px;
  width: 333px;
  height: 30px;
  border: 0.5px solid;
  margin-left: 79px;
  padding: 4px;
  font-family: "Lato", sans-serif;
  font-size: 15px;
}
.project-startdate-enddate-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.project-startdate-container,
.project-enddate-container {
  flex: 1; /* Each container takes up equal space */
  margin-right: 10px; /* Optional: adds some spacing between them */
}
.project-startdate-container {
  display: flex;
  margin-top: 15px;
}
.start-date-heading {
  margin-top: 10px;
  color: black;
  font-weight: 400;
  margin-right: 80px;
  margin-top: 1px;
  text-wrap: nowrap;
}

input.date-input {
  background-color: white;
  border-radius: 3px;
  width: 320px;
  height: 30px;
  border: 0.5px solid;
  padding-left: 8px;
  margin-left: 80px;
  font-family: "Lato", sans-serif;
  font-size: 15px;
}
.project-enddate-container {
  display: flex;
  margin-right: 0px;
}
.end-date-heading {
  margin-top: 10px;
  color: black;
  font-weight: 400;
  margin-right: 82px;
  margin-top: 5px;
}
.project-tags-container {
  margin-top: 15px;
  text-align: left;
  display: flex;
  flex-direction: row;
}

.remarks-input {
  text-align: left;
  height: 27px;
  width: 264px;
  margin-left: -12px;
  border: 0.5px solid;
}
.group-completion-time-container {
  display: flex;
  flex-direction: row;
  margin-right: 35px;
  margin-top: -6px;
  background-color: transparent;
}
.project-group-heading {
  margin-top: 1px;
  color: black;
  font-weight: 400;
  margin-right: 57px;
}

.project-completed-date-container {
  text-align: left;
  display: flex;
  flex-direction: row;
}
.completed-date-heading {
  margin-top: 10px;
  color: black;
  font-weight: 400;
  margin-right: 36px;
  margin-top: 3px;
}
.completed-date-input {
  background-color: white;
  border-radius: 3px;
  width: 321px;
  height: 30px;
  border: 0.5px solid;
  padding-left: 8px;
  margin-left: 77px;
  font-family: "Lato", sans-serif;
  font-size: 15px;
}
.phase-no-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 11px;
  margin-left: 1322px;
}
.proj-no-heading {
  text-align: left;
  margin-left: 0px;
  margin-right: 29px;
  color: black;
  font-weight: 400;
  margin-top: 7px;
}
.cus-name-heading {
  text-align: left;
  margin-left: 0px;
  margin-right: 26px;
  color: black;
  font-weight: 400;
  margin-top: 7px;
}
.cus-id-heading {
  text-align: left;
  margin-left: 0px;
  margin-right: 71px;
  color: black;
  font-weight: 400;
  margin-top: 7px;
}
.phase-no-heading {
  margin-top: 10px;
  text-align: left;
  font-weight: 400;
  color: black;
  margin-right: 30px;
  margin-top: 5px;
  text-wrap: nowrap;
}
.input-element-phase-no {
  text-align: left;
  margin-left: -122px;
  height: 27px;
  width: 264px;
  border: 0.5px solid;
}
.client-ref-container {
  margin-top: 13px;
  margin-left: 877px;
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 450px;
}
.client-no-heading {
  margin-top: 4px;
  text-align: left;
  color: black;
  font-weight: 400;
  margin-right: 23px;
}
.input-element-ref-no {
  text-align: left;
  margin-left: -1px;
  margin-right: 106px;
  height: 27px;
  width: 266px;
  border: 0.5px solid;
}
.project-member-container {
  margin-top: 15px;
  margin-left: 10px;
  text-align: left;
  display: flex;
  flex-direction: row;
}
.members-heading {
  margin-top: 5px;
  color: black;
  font-weight: 400;
  margin-right: 30px;
}
.rmsc .dropdown-container {
  padding-left: 7px;
  position: relative;
  outline: 0;
  height: 30px;
  background-color: white;
  border: 1px solid;
  border-radius: 3px;
  margin-left: 80px;
  border: 0.5px solid;
  min-width: 332px;
  font-family: "Lato", sans-serif;
  font-size: 15px;
  max-width: 547px;
  cursor: pointer;
}
.rmsc .dropdown-heading {
  position: relative !important;
  padding: 0 var(--rmsc-p) !important;
  display: flex !important;
  align-items: center !important;
  width: 100% !important;
  height: 27px !important;
  cursor: pointer !important;
  margin-top: 0px !important;
  outline: 0 !important;
  background-color: transparent;
  font-family: "Lato", sans-serif !important;
  font-size: 15px !important;
}
.rmsc * {
  box-sizing: border-box;
  transition: all 0.2s ease;
  background-color: transparent;
}
.multi-select-option .react-select__control {
  border: none;
  box-shadow: none;
}

.multi-p-select-option {
  margin-bottom: 10px;
  width: 330px;
  height: 30px;
  margin-left: -11px;
}

.remarks-container {
  display: flex;
}
.remark-heading {
  color: black;
  margin-top: 3px;
  font-weight: 400;
  margin-right: 105px;
}
.budget-individual-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 5px 5px 14px 1px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  margin-top: 30px;
  margin-left: 15px;
  margin-right: 40px;
  padding: 14px;
  width: 1756px;
}
.budget-heading {
  font-weight: 500;
  color: #010101;
  margin-top: 10px;
  margin-right: 35px;
}
.curr-budget-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.curr-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 5px;
  margin-left: 0px;
}
.currency-heading {
  color: black;
  font-weight: 400;
  margin-top: 5px;
  margin-right: 79px;
}
.curr-select-container {
  height: 27px;
  width: 273px;
  border: 0.5px solid;
  padding: 5px;
  height: 30px;
}
.budget-type-heading {
  color: black;
  font-weight: 400;
  margin-top: 3px;
  text-wrap: nowrap;
}
.budget-select-container {
  margin-top: 06px;
  margin-left: -26px;
  border: 0.5px solid;
  height: 30px;
  width: 269px;
  padding: 5px;
}
.billing-heading {
  color: black;
  font-weight: 400;
  margin-top: 1px;
  margin-right: 42px;
}
.billing-method-select {
  height: 27px;
  border: 0.5px solid;
  width: 274px;
  margin-left: -2px;
  padding: 5px;
  height: 30px;
}
.comments-container {
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: flex-start;
  margin-top: 30px;
  margin-left: 15px;
  margin-right: 40px;
  padding: 14px;
  margin-bottom: 25px;
  box-shadow: 5px 5px 14px 1px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  width: 1756px;
}

.individual-btn-container {
  background-color: white;
  position: fixed;
  bottom: 0px;
  width: 100%;
  height: 45px;
  margin-left: 78px;
  border-top: 0.5px solid grey;
  z-index: 1;
}
.individual-btn-container-close {
  background-color: white;
  position: fixed;
  bottom: 0px;
  width: 100%;
  height: 45px;
  left: 6px;
  border-top: 0.5px solid grey;
  z-index: 1;
}
.save-project-btn {
  background-color: #6200ee;
  color: white;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 6px;
  padding-bottom: 6px;
  margin-top: 6px;
  margin-left: 12px;
  font-size: 16px;
  width: 79px;
  height: 33px;
  bottom: 5px;
  border-radius: 3px;
  border: none;
  margin-right: 60px;
  font-weight: 600;
  cursor: pointer;
}
.cancel-project-btn {
  background-color: transparent;
  border: 1px solid #6200ee;
  color: #6200ee;
  padding-top: 6px;
  padding-bottom: 6px;
  font-size: 16px;
  width: 79px;
  margin-left: -46px;
  margin-top: -11px;
  height: 33px;
  bottom: 5px;
  border-radius: 3px;
  font-weight: 400;
  cursor: pointer;
}
.select-owner-dropdown {
  background-color: white;
  width: 333px;
  height: 30px;
  border-radius: 3px;
  border: 0.5px solid;
  padding: 4px;
  margin-left: 80px;
  font-family: "Lato", sans-serif;
  font-size: 15px;
}
.start-date-input {
  background-color: white;
  border-radius: 3px;
  width: 320px;
  height: 30px;
  border: 0.5px solid;
  padding-left: 8px;
  margin-left: 79px;
  font-family: "Lato", sans-serif;
  font-size: 15px;
  cursor: pointer;
}
.tags-heading {
  margin-top: 10px;
  color: black;
  font-weight: 400;
  margin-right: 124px;
  margin-top: 4px;
}
.tags-input {
  background-color: white;
  width: 321px;
  height: 30px;
  border-radius: 3px;
  border: 0.5px solid;
  margin-left: 117px;
  padding-left: 7px;
  font-family: "Lato", sans-serif;
  font-size: 15px;
}
.group-dropdown {
  margin-top: 0px;
  margin-right: 120px;
  width: 333px;
  height: 31px;
  border: 0.5px solid;
  margin-left: 78px;
  padding: 5px;
  font-family: "Lato", sans-serif;
  font-size: 15px;
}
.project-number-input {
  height: 27px;
  width: 264px;
  margin-left: 8px;
  border-radius: 3px;
  border: 0.5px solid;
  padding-left: 5px;
}
.cus-name-input {
  height: 27px;
  width: 264px;
  border: 0.5px solid;
  border-radius: 3px;
  margin-left: 9px;
  padding-left: 5px;
}
.status-options-dropdown,
.date-input,
.cus-name,
.completed-date-input,
.project-number,
.input-element-phase-no,
.input-element-ref-no,
.remarks-input,
.curr-select-container,
.budget-select-container,
.billing-method-select,
.group-dropdown {
  border-radius: 3px;
  border-radius: 3px;
  padding-left: 7px;
}

.project-details-popup {
  background-color: transparent;
  color: black;
  font-weight: 400;
  height: auto;
  position: fixed;
  top: 35px;
  left: 341px;
}
.project-details-heading {
  font-size: 12px;
  background-color: transparent;
}
.non-editable-textarea {
  cursor: not-allowed;
}
.project-left-navbar {
  position: relative;
  background-color: transparent;
  height: 95vh;
  width: 115px;
  top: -30px;
  padding: 5px 10px;
}
.group-ul {
  position: relative;
  left: 77px;
  height: 150px;
  width: 300px;
  overflow: auto;
  top: -10px;
}
@media (min-width: 1440px) and (max-width: 1919px) {
  .status-heading {
    margin-left: 31px;
  }
  .close-btn {
    left: 1380px;
    top: -82px;
  }
  .close-btn-close {
    left: 1454px;
    top: -82px;
  }
  .rmsc .dropdown-container {
    min-width: 255px;
  }
  .description-para {
    max-width: 1190px;
  }
  .save-project-btn {
    font-size: 15px;
    padding-top: 9px;
    padding-bottom: 6px;
  }
  .cancel-project-btn {
    font-size: 15px;
    padding-top: 7px;
    padding-bottom: 6px;
  }
  .cus-id-heading {
    margin-right: 57px;
  }
}
