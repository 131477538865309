.inputBox {
  font-size: 16px;
  padding: 5px 8px 4px 8px;
  border-radius: 3px;
  border: 1px solid #666;
  background-color: transparent;
  text-align: center;
  cursor: pointer;
}

.calendarWrap {
  position: absolute;
  top: 5px;
  left: 140px;
  background-color: transparent;
}

.calendarElement {
  position: absolute;
  left: 100%;
  transform: translateX(-50%);
  top: 50px;
  border: 1px solid #ccc;
  background-color: rgb(52, 241, 131);
  height: 50px;
  z-index: 999;
}
.date-range-picker {
  z-index: 999;
  top: -30px;
  border: 1px solid #ccc;
  height: 370px;
  position: relative;
  margin-top: -30px;
  margin-left: -80%;
  left: 40%;
}
.closeButton {
  margin-top: 69px;
  position: relative;
  z-index: 1000;
  width: 54px;
  margin-left: -6px;
  background-color: white;
  height: 30px;
  padding: 7px;
  cursor: pointer;
}
.searchButton {
  margin-top: -30px;
  position: relative;
  z-index: 6;
  height: 30px;
  width: 60px;
  margin-left: -146px;
  border: none;
  background-color: #6200ee;
  color: white;
  border-radius: 3px;
  padding: 7px 7px;
  z-index: 1000;
  margin-right: 15px;
}
.span-btn {
  margin-top: -29px;
  position: relative;
  z-index: 6;
  margin-left: 358px;
  font-size: 12px;
  text-wrap: nowrap;
  border: none;
  background-color: transparent;
  color: Black;
  border-radius: 3px;
  cursor: pointer;
  font-family: "Arial";
}
.buttonContainer {
  margin-top: -134px;
  z-index: 1000;
  position: relative;
  height: 0;
  width: 361px;
  display: flex;
  flex-direction: column;
  margin-left: -579px;
}
.rdrDateDisplayWrapper {
  background-color: white;
}
.rdrNextPrevButton {
  display: block;
  width: 24px;
  height: 24px;
  margin: 0 0.833em;
  padding: 0;
  border: 0;
  border-radius: 5px;
  background-color: white;
}
.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
  font-weight: bold;
  background-color: white;
  color: #6200ee;
}
@media (min-width: 1440px) and (max-width: 1919px) {
  .searchButton {
    width: 64px;
  }
}
