.group-details-container {
  padding: 20px;
  margin-left: 81px;
  position: fixed;
  top: 30px;
  width: 90vw;
}
.group-details-container-close {
  padding: 20px;
  margin-left: 6px;
  position: fixed;
  top: 30px;
  width: 90vw;
}

.group-users,
.group-projects {
  margin-top: 20px;
  margin-left: 132px;
}

ul {
  list-style-type: none;
  padding: 0;
}

.scrollable-container {
  max-height: 200px;
  /* Set a maximum height */
  overflow-y: auto;
  /* Enable vertical scrolling */
  border: 1px solid #ccc;
  /* Optional: Add a border */
  border-radius: 4px;
  /* Optional: Add border radius */
  margin-bottom: 16px;
  /* Space between elements */
}
.save-btn {
  margin-top: 25px;
  margin-left: 132px;
}
.group-list {
  list-style-type: decimal;
  margin-left: 150px;
}
.users-list,
.projects-list {
  overflow: auto;
  min-height: auto;
  max-height: 220px;
  margin-top: 10px;
  border: 0.5px solid #ddd;
  width: 452px;
  padding-left: 10px;
}

.users-list::-webkit-scrollbar,
.projects-list::-webkit-scrollbar {
  display: none;
}
.group-users-table {
  width: auto;
  position: relative;
  height: 520px;
  overflow-y: scroll;
  left: -16px;
  top: 15px;
  width: 100vw;
  display: flex;
}
.group-users-table::-webkit-scrollbar {
  display: none;
}
.multi-select-group {
  width: 330px;
}
.group-table {
  position: relative;
  bottom: 516px;
  overflow: auto;
  height: 527px;
  left: 675px;
  background-color: transparent;
}
.exit-arrow {
  color: blue;
  height: 20px;
  width: 20px;
  position: relative;
  top: 10px;
  left: 1740px;
}
.group-exit-arrow {
  color: blue;
  height: 20px;
  width: 20px;
  position: fixed;
  top: 68px;
  left: 1872px;
}
.group-parent {
  position: relative;
  left: -131px;
}
@media (min-width: 1440px) and (max-width: 1919px) {
  .exit-arrow {
    left: 1353px;
  }
  .group-exit-arrow {
    top: 59px;
    left: 1508px;
  }
}
