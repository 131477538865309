.estimation-container {
  position: absolute;
  top: 12%;
  left: 3.7%;
  height: 81vh;
  width: 96.1%;
  overflow: auto;
  padding-bottom: 30px;
}
.estimation-container-close {
  position: absolute;
  top: 12%;
  left: 0%;
  height: 81vh;
  width: 100%;
  overflow: auto;
  padding-bottom: 30px;
}

.estimation-i-container {
  position: fixed;
  top: 49px;
  left: 90px;
  height: 100vh;
  width: 98%;
  overflow: auto;
  padding-bottom: 30px;
}
.estimation-container-i-close {
  position: fixed;
  top: 49px;
  left: 0px;
  height: 100vh;
  width: 101.6%;
  overflow: auto;
  padding-bottom: 30px;
}
.estimation-content-table {
  width: 113%;
  border-collapse: collapse;
  margin: 14px 0;
  position: absolute;
  top: -1.5%;
  left: 16px;
  margin-right: 15px;
  border-radius: 3px;
  /* overflow: auto; */
}
.estimation-content-table thead th {
  background-color: white;
  color: black;
  /* font-weight: 400; */
  text-wrap: nowrap;
  -webkit-user-select: none;
  user-select: none;
  text-align: left;
  padding: 10px;
  position: relative;
  border: 1px solid #ddd;
  padding-top: 6px;
  padding-bottom: 6px;
}

.estimation-content-table th {
  border-bottom: 0.5px solid #ddd;
}
.estimation-content-table thead th .resize-handle {
  position: absolute;
  right: 0;
  top: 0;
  width: 15px;
  height: 100%;
  cursor: col-resize;
  background-color: transparent;
}
.estimation-content-table tbody td {
  border: 1px solid #ddd;
  text-align: left;
  white-space: nowrap;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 14px;
}
.estimation-content-table thead {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
  border-bottom: 2px solid rgb(217, 226, 236);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.estimation-content-table-close {
  width: 113%;
  border-collapse: collapse;
  margin: 14px 0;
  position: absolute;
  top: -1.5%;
  left: 16px;
  margin-right: 15px;
  border-radius: 3px;
  /* overflow: auto; */
}
.estimation-content-table-close thead th {
  background-color: white;
  color: black;
  /* font-weight: 400; */
  text-wrap: nowrap;
  -webkit-user-select: none;
  user-select: none;
  text-align: left;
  padding: 10px;
  position: relative;
  border: 1px solid #ddd;
  padding-top: 6px;
  padding-bottom: 6px;
}
.estimation-content-table-close th {
  border-bottom: 0.5px solid #ddd;
}
.estimation-content-table-close thead th .resize-handle {
  position: absolute;
  right: 0;
  top: 0;
  width: 15px;
  height: 100%;
  cursor: col-resize;
  background-color: transparent;
}
.estimation-content-table-close tbody td {
  border: 1px solid #ddd;
  text-align: left;
  white-space: nowrap;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 14px;
}
.estimation-content-table-close thead {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
  border-bottom: 2px solid rgb(217, 226, 236);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.estimation-content-table-close tbody tr {
  border: 1px solid #ddd;
  background-color: white;
  table-layout: fixed;
}

.estimation-content-table tbody tr {
  border: 1px solid #ddd;
  background-color: white;
  table-layout: fixed;
}
.create-estimate-btn {
  font-weight: 600;
  cursor: pointer;
  margin-bottom: 10px;
  height: auto;
  width: auto;
  padding: 7px 10px;
  border: none;
  color: white;
  background-color: #6200ee;
  border-radius: 3px;
  position: fixed;
  top: -1%;
  margin-top: 80px;
  left: 91.7%;
  font-family: "Lato", sans-serif;
  font-size: 13px;
}
.estimation-content-table .convert-column {
  /* border: 0.5px solid white; */
  width: 2px;
  text-align: center;
}
.estimation-pagination {
  /* top: 906px; */
  position: fixed;
  /* left: 1537px; */
  display: flex;
  height: 40px;
  background-color: transparent;
  bottom: 0.3rem;
  right: 1rem;
}
.estimation-table {
  width: 98%;
  border-collapse: collapse;
  margin: 14px 0;
  position: absolute;
  left: 16px;
  margin-right: 15px;
  border-radius: 3px;
  top: 30px;
  left: 9px;
}
.estimation-table thead th {
  background-color: white;
  color: black;
  /* font-weight: 400; */
  text-wrap: nowrap;
  user-select: none;
  text-align: left;
  padding: 10px;
  position: relative;
  border: 1px solid #ddd;
}
.estimation-table th {
  border-bottom: 0.5px solid #ddd;
}
.estimation-table thead th .resize-handle {
  position: absolute;
  right: 0;
  top: 0;
  width: 15px;
  height: 100%;
  cursor: col-resize;
  background-color: transparent;
}
.estimation-table tbody tr {
  border: 1px solid #ddd;
  background-color: white;
  table-layout: fixed;
}
.convert-btn {
  height: auto;
  width: auto;
  padding: 4px 5px;
  text-align: center;
  color: #6200ee;
  font-size: 22px;
  border: none;
  border-radius: 3px;
  font-weight: 750;
  cursor: pointer;
  position: absolute;
  top: 3px;
  right: 47px;
  background-color: transparent;
}
.scrollable-e-content {
  width: 31%;
  padding-bottom: 10px;
  height: 85.61%;
  overflow-y: auto;
  margin-left: 68.6%;
  padding-left: 20px;
}
.add-estimation-heading {
  color: black;
  margin-top: 4px;
  margin-bottom: 1px;
  font-weight: 600;
  font-size: 16px;
  background-color: transparent;
  margin-left: 14px;
}
.task-hours-container {
  display: flex;
  justify-content: space-between;
  margin-right: 10px;
  margin-bottom: 15px;
}
.hours-input {
  border: 0.5px solid;
  border-radius: 3px;
  padding: 5px;
}
/* .convert-dial {
  position: relative;
  top: 48%;
  left: 53%;
  right: 0%;
  bottom: 0%;
  width: 829px;
  border: 0.5px solid #ddd;
  height: 770px;
  animation: fadeInCenter 1s forwards;
} */
.convert-dial {
  position: fixed;
  top: 13%;
  left: 32%;
  transform: translate(-50%, -50%); /* Center the element */
  width: 829px;
  height: 770px;
  border: 0.5px solid #ddd;
  animation: fadeInCenter 1s forwards;
}
@keyframes fadeInCenter {
  0% {
    opacity: 0;
    transform: scale(0.95); /* Slightly scale the element down initially */
  }
  100% {
    opacity: 1;
    transform: scale(1); /* Scale the element back to its original size */
  }
}
.convert-main-dial {
  position: relative;
  z-index: 6;
  background-color: rgb(0, 0, 0, 0.7);
  height: 100vh;
  width: 101vw;
  left: -74px;
  top: -2px;
}
.mapping-main-container {
  margin-top: 20px;
  overflow: auto;
  height: 625px;
}
/* .convert-main-dial {
  position: relative;
  z-index: 6;
  background-color: rgb(0, 0, 0, 0.28);
  height: 100vh;
  width: 101vw;
  left: 880px;
  top: 458px;
  animation: fadeInCenter 1s forwards;
} */

.convert-dial-header {
  background-color: #f5f5f5;
  background-color: #f5f5f5;
  width: 100%;
  height: 52px;
  position: relative;
  top: -17px;
}
.convert-heading {
  position: relative;
  top: 16px;
  background-color: transparent;
  margin-left: 20px;
}
.estimate-map-content {
  position: relative;
  left: 1px;
  background-color: transparent;
  top: -12px;
  display: flex;
  justify-content: space-between;
  border-bottom: 0.5px solid;
}
.tt-input {
  border: 0.5px solid;
  border-radius: 3px;
  padding: 5px;
  width: 200px;
  margin-left: 100px;
}
.task-mapping {
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
}
.task-dd {
  border: 0.5px solid;
  border-radius: 3px;
  width: 250px;
  padding: 5px;
}
.convert-btn-container {
  position: relative;
  top: -23px;
  left: 635px;
  border-radius: 3px;
  background-color: transparent;
}
.form-content {
  margin-left: 22px;
  width: 96%;
}
.new-estimation-log {
  font-weight: 400px;
  background-color: #f5f5f5;
  padding: 1px;
  width: 100%;
  margin-top: -0.5%;
  margin-left: 68.6%;
}
.selected-templates-list {
  overflow: auto;
  height: 286px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.est-planned-hours {
  border: 0.5px solid;
  border-radius: 3px;
  padding: 5px;
  height: 20px;
  width: 80px;
  text-align: center;
}
.task-template-map-container {
  display: flex;
  position: relative;
  width: 96%;
  height: 550px;
  overflow-x: hidden;
  padding-right: 10px;
  border: 0.5px solid #ddd;
  border-radius: 3px;
}
.task-template {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  margin: 20px;
  margin-top: 0px;
}
.task-ph-ul {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  position: relative;
  top: -30px;
}
.task-ph-li {
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.add-est-btn {
  height: auto;
  width: auto;
  padding: 5px 10px;
  text-align: center;
  border: none;
  color: white;
  border-radius: 3px;
  background-color: #6200ee;
  margin-left: 22px;
  margin-top: 15px;
  font-weight: 750;
  cursor: pointer;
  position: relative;
  left: 1651px;
}
.status-est-con {
  display: flex;
}
.input-est {
  border: 0.5px solid;
  border-radius: 3px;
  height: 15px;
  padding: 5px;
  width: 150px;
  position: relative;
  left: 80px;
}
.status-est-dropdown {
  border: 0.5px solid;
  border-radius: 3px;
  height: 28px;
  width: 162px;
  padding: 5px;
  position: relative;
  top: 14px;
  left: 119px;
}
.est-no-content {
  margin-left: 20px;
}
.est-name-content {
  margin-left: 64px;
}
.est-hours-content {
  margin-left: 18px;
}
.est-exit-icon {
  position: relative;
  top: 16px;
  left: 1731px;
}
.total-template-hours {
  position: relative;
  left: 540px;
}
.input-d-est {
  position: relative;
  left: 128px;
  height: 25px;
  width: 163px;
  border-radius: 3px;
  border: 0.5px solid;
}
.estimate-phase-table {
  width: 94vw;
  border-collapse: collapse;
  margin: 20px 0;
  position: fixed;
  left: 85px;
  right: 12px;
  overflow-x: auto;
  margin-right: 54px;
  top: 106px;
}
.estimate-phase-table-close {
  width: 97.6%;
  border-collapse: collapse;
  margin: 20px 0;
  position: relative;
  left: 15px;
  right: 12px;
  overflow-x: auto;
  margin-right: 54px;
  margin-top: 1.7%;
}
.es-pj-name-close {
  position: relative;
  left: 20px;
  top: 21px;
  background-color: transparent;
}
.es-pj-number {
  position: fixed;
  left: 86px;
  top: 75px;
  margin-top: 22px;
}
.es-pj-number-close {
  position: relative;
  left: 20px;
  top: 21px;
}
.estimate-phase-table thead th {
  background-color: white;
  color: black;
  /* font-weight: 400; */
  text-wrap: nowrap;
  text-align: left;
  padding: 10px;
  position: relative;
  border: 1px solid #ddd;
}
.estimate-phase-table tbody tr {
  text-align: left;
  border: 1px solid #ddd;
  background-color: white;
}
.estimate-phase-table tbody tr:last-child {
  border-bottom: 1px solid #ddd; /* Adjust the border style and color as needed */
}

.estimate-phase-table tbody tr:last-child {
  border-bottom: none;
}

.estimate-phase-table-close thead th {
  background-color: white;
  color: black;
  /* font-weight: 400; */
  text-wrap: nowrap;
  text-align: left;
  padding: 10px;
  position: relative;
  border: 1px solid #ddd;
}
.estimate-phase-table-close tbody tr {
  text-align: left;
  border: 1px solid #ddd;
  background-color: white;
}
.estimate-phase-table-close tbody tr:last-child {
  border-bottom: 1px solid #ddd; /* Adjust the border style and color as needed */
}

.estimate-phase-table-close tbody tr:last-child {
  border-bottom: none;
}
.add-estimate-phase-btn {
  width: auto;
  padding: 5px 10px;
  text-align: center;
  border: none;
  color: white;
  border-radius: 3px;
  background-color: #6200ee;
  font-weight: 750;
  cursor: pointer;
  position: fixed;
  top: 68px;
  height: 25px;
  left: 1764px;
  font-family: "Lato", sans-serif;
  font-size: 13px;
}
.delete-estimate-phase-btn {
  width: auto;
  padding: 5px 10px;
  text-align: center;
  border: none;
  color: white;
  border-radius: 3px;
  background-color: red;
  font-weight: 750;
  cursor: pointer;
  position: fixed;
  top: 68px;
  height: 25px;
  left: 1647px;
}
.task-e-btn-con {
  border-top: 0.5px solid;
  margin-left: -15px;
  position: relative;
  top: 665px;
  width: 105%;
}
.es-pj-name {
  position: fixed;
  left: 86px;
  top: 46px;
  background-color: transparent;
  margin-bottom: 5px;
}
.estimate-task-main-container {
  position: relative;
  left: 71px;
  top: 41px;
  overflow: auto;
  height: 95vh;
}
.estimate-task-main-container-close {
  position: relative;
  left: 1px;
  top: 41px;
  overflow: auto;
  height: 95vh;
  width: 99.9%;
}
.e-tt-container {
  position: relative;
  top: -816px;
  left: 24px;
  margin-bottom: 13px;
}
.e-tt-container-close {
  position: relative;
  top: -889px;
  left: 30px;
  margin-bottom: 13px;
}
.estimate-task-main-container::-webkit-scrollbar {
  display: none;
}
.estimate-task-main-container-close::-webkit-scrollbar {
  display: none;
}
.e-group-tasks {
  position: absolute;
  left: 103.8%;
  top: -4%;
  background-color: transparent;
  width: 40%;
}
.e-group-tasks-close {
  position: absolute;
  left: 103.8%;
  top: -4%;
  background-color: transparent;
  width: 40%;
}

.e-grouped-tasks {
  position: relative;
  left: 17px;
  top: 10px;
  background-color: transparent;
  top: 2px;
}
.e-group-template {
  position: relative;
  left: 9px;
  top: -22px;
  background-color: transparent;
}
.label-estimate-element {
  margin-bottom: 3px;
  font-weight: 400;
  margin-top: 10px;
}
.estimate-phase-dial {
  width: 99%;
  height: 94%;
  right: 1px;
  left: -0.1%;
  position: fixed;
  top: 49px;
  display: flex;
  padding: 15px;
  flex-direction: column;
  border: 1px solid grey;
  border-radius: 3px;
  animation: slideIn 0.5s forwards;
  background-color: rgba(0, 0, 0, 0.7);
}
.ephase-header {
  position: relative;
  margin-top: -12px;
  margin-bottom: 0px;
  font-weight: 500;
  font-size: 16px;
  /* padding: 1px; */
  background-color: #f5f5f5;
  width: 615px;
  margin-left: -2.5%;
  padding: 4px;
  z-index: 1;
  padding-top: 5px;
  height: 16px;
  padding-bottom: 10px;
}
.e-header {
  margin-top: 0px;
  margin-bottom: 10px;
  font-weight: 600;
  background-color: transparent;
  margin-left: 10px;
}
.e-phase-content {
  background-color: white;
  padding: 15px;
  margin-right: -8px;
  margin-left: 68%;
  margin-top: -1%;
  height: 98%;
}
.estimate-task-templaes {
  border: 0.5px solid;
  border-radius: 3px;
  padding-left: 20px;
  width: 95%;
  margin-bottom: 10px;
}
.estimate-task-templates {
  border: 0.5px solid #ddd;
  width: 33%;
  position: relative;
  left: 67%;
  margin-bottom: 10px;
  top: -3%;
  height: 109%;
}

.estimate-task-templates-close {
  border: 0.5px solid #ddd;
  width: 33%;
  position: relative;
  left: 67%;
  margin-bottom: 10px;
  top: -3%;
  height: 109%;
}

.estimate-close {
  position: relative;
  top: 18px;
  left: 600px;
  color: blue;
  font-size: 20px;
  z-index: 3;
  cursor: pointer;
}
.estimate-close-close {
  position: relative;
  top: -7px;
  left: 1872px;
  color: blue;
  font-size: 20px;
  z-index: 3;
  cursor: pointer;
}
.estimate-phase-close {
  position: relative;
  top: 70px;
  left: 1870px;
  color: blue;
  font-size: 20px;
}
.estimate-task-table thead th {
  background-color: white;
  color: black;
  /* font-weight: 400; */
  white-space: nowrap; /* Fix for text wrapping issues */
  text-align: left;
  padding: 10px;
  border: 1px solid #ddd;
}

.estimate-task-table tbody tr {
  text-align: left;
  background-color: white;
  border: 1px solid #ddd;
}

.estimate-task-table tbody tr:last-child {
  border-bottom: 1px solid #ddd;
}
.estimate-task-table-close {
  width: 93vw;
  max-width: 90%;
  border-collapse: collapse;
  margin: 20px auto;
  overflow-x: auto;
  table-layout: fixed;
  position: relative;
  left: -17px;
  top: -10px;
}
.estimate-task-table-close thead th {
  background-color: white;
  color: black;
  /* font-weight: 400; */
  white-space: nowrap; /* Fix for text wrapping issues */
  text-align: left;
  padding: 10px;
  border: 1px solid #ddd;
}

.estimate-task-table-close tbody tr {
  text-align: left;
  background-color: white;
  border: 1px solid #ddd;
}

.estimate-task-table-close tbody tr:last-child {
  border-bottom: 1px solid #ddd;
}
.estimate-details-table {
  width: 50vw;
  border-collapse: collapse;
  margin: 20px auto;
  overflow-x: auto;
  table-layout: fixed;
  position: relative;
  left: -3%;
  top: 0px;
  margin-right: 42px;
}
.estimate-details-container {
  display: flex;
  margin-left: 70px;
  width: 97%;
  position: relative;
  top: 57px;
}
.estimate-details-container-close {
  display: flex;
  margin-left: 82px;
  width: 100.2%;
  position: relative;
  top: 57px;
}
.estimate-details-table thead th {
  background-color: white;
  color: black;
  min-width: 2px;
  max-width: auto;
  /* font-weight: 400; */
  white-space: nowrap; /* Fix for text wrapping issues */
  text-align: left;
  padding: 10px;
  border: 1px solid #ddd;
}

.estimate-details-table tbody tr {
  text-align: left;
  background-color: white;
  border: 1px solid #ddd;
}

.estimate-details-table tbody tr:last-child {
  border-bottom: 1px solid #ddd;
}
.est-phase-hours-summary {
  position: fixed;
  top: 96.8%;
  display: flex;
  justify-content: space-between;
  width: 108%;
  /* left: 5%; */
}

.delete-est-heading {
  font-weight: 600;
  cursor: pointer;
  margin-bottom: 10px;
  height: auto;
  width: auto;
  padding: 7px 10px;
  border: none;
  color: white;
  background-color: red;
  border-radius: 3px;
  position: fixed;
  top: -1.9%;
  left: 87.8%;
  margin-top: 89px;
}

.est-phase-hours-summary-close {
  position: fixed;
  top: 915px;
  display: flex;
  justify-content: space-between;
  width: 1952px;
  left: 81px;
}

.phase-details-text-close {
  font-weight: 500;
  position: relative;
  margin-top: 1px;
  margin-left: 15px;
}
.grouped-estimate-tasks {
  position: relative;
  top: 12px;
  left: 13px;
}
.est-delete-icon {
  border: none;
  cursor: pointer;
  position: relative;
  left: 86%;
  top: 21px;
  z-index: 2;
}
.e-select-template {
  padding: 10px;
}
.estimate-column-icon {
  position: relative;
  left: 2px;
  height: 19px;
  width: 30px;
  top: 2px;
  z-index: 2;
  cursor: pointer;
  background-color: transparent;
}

.estimate-status-dropdown {
  border: none;
  padding: 2px;
  color: rgb(255, 255, 255);
  width: 130px;
  height: 25px;
  font-weight: 400;
  font-family: "Lato", sans-serif;
  font-size: 15px;
  border-radius: 3px;
  background-color: transparent;
  cursor: pointer;
}

.estimate-status-dropdown option {
  color: black;
  background-color: white;
}
.est-delete-icon-close {
  border: none;
  cursor: pointer;
  position: relative;
  left: 86%;
  top: 21px;
  z-index: 2;
}
.est-task-close {
  position: absolute;
  left: 1811px;
  top: 32px;
  font-size: 20px;
  color: blue;
}
.est-task-close-close {
  position: absolute;
  left: 1881px;
  top: 32px;
  font-size: 20px;
  color: blue;
}
.estimate-status-dropdown-con.Awarded {
  background-color: rgb(255, 255, 255);
}

.estimate-status-dropdown-con.Yet_to_quote {
  background-color: rgb(255, 255, 255); /* Example for in-progress status */
}

.estimate-status-dropdown-con.Waiting {
  background-color: rgb(255, 255, 255); /* Example for completed status */
}

.estimate-status-dropdown-con.Lost {
  background-color: #ffffff; /* Example for cancelled status */
}
.estimate-status-dropdown.Draft {
  background-color: #d2b48c;
}
.estimate-status-dropdown.Awarded {
  background-color: green;
}
.estimate-status-dropdown.Yet_to_quote {
  background-color: grey;
}
.estimate-status-dropdown.Lost {
  background-color: #d9534f;
}
.estimate-status-dropdown.Waiting {
  background-color: Blue;
}
.estimate-status-dropdown:focus {
  border-color: white;
  outline: none;
}
.estimate-status-dropdown .dropdown-selected {
  background-color: black; /* Default background */
  border: 1px solid #ccc;
  padding: 10px;
  cursor: pointer;
}
.phase-header {
  width: 200px;
}
.phase-hr-header {
  width: 100px;
  text-align: center;
}
.est-phase-message {
  position: relative;
  top: -10px;
  left: 18px;
}
.est-select-template {
  position: relative;
  top: 16px;
  left: 20px;
}
.select-estimated-by {
  width: 200px;
  height: 24px;
  border: 0.5px solid;
  border-radius: 3px;
  position: relative;
  left: 14px;
  top: -1px;
  padding: 3px;
  margin-right: 50px;
  cursor: pointer;
  font-family: "Lato", sans-serif;
  font-size: 13px;
}
.est-description {
  border: none;
  width: 150px;
  padding: 5px;
  height: 25px;
  font-weight: 400;
  font-family: "Lato", sans-serif;
  font-size: 15.5px;
  color: #000;
  background-color: transparent;
}
.est-description:focus {
  border-color: white;
  outline: 3px solid #ffffff;
  outline-offset: 2px;
  height: 25px;
  width: 150px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
}
.Toastify__toast-container {
  top: 11% !important;
  left: 50% !important;
  height: 100px;
  transform: translate(
    -50%,
    -50%
  ) !important; /* Center horizontally and vertically */
  position: fixed !important;
  z-index: 15;
  background-color: transparent !important;
}
.estimate-column-icon-con {
  height: 39px;
  width: 56px;
  left: 97.1%;
  position: fixed;
  top: 12.2%;
  z-index: 10;
  border-left: 1px solid #ddd;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
.estimate-task-table {
  width: 93vw;
  max-width: 90%;
  border-collapse: collapse;
  margin: 20px auto;
  overflow-x: auto;
  table-layout: fixed;
  position: relative;
  left: -17px;
  top: -10px;
}
.estimate-task-table thead th {
  background-color: white;
  color: black;
  /* font-weight: 400; */
  white-space: nowrap; /* Fix for text wrapping issues */
  text-align: left;
  padding: 10px;
  border: 1px solid #ddd;
}
.estimate-task-table tbody tr:last-child {
  border-bottom: 1px solid #ddd;
}
.est-exclusion {
  min-width: 232px;
  max-width: 232px;
  border: none;
  flex-grow: 1;
  font-family: "Lato", sans-serif;
  font-size: 15px;
  max-height: 30px;
}
.est-exclusion-close {
  min-width: 212px;
  max-width: 212px;
  border: none;
  flex-grow: 1;
  font-family: "Lato", sans-serif;
  font-size: 15px;
  max-height: 30px;
}
.est-exclusion:focus {
  border-color: white;
  outline: 3px solid #ffffff;
  outline-offset: 2px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
}
.est-exclusion-close:focus {
  border-color: white;
  outline: 3px solid #ffffff;
  outline-offset: 2px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
}
.task-project-details {
  position: absolute;
  top: 5px;
  left: 23px;
  background-color: transparent;
  display: flex;
}
.task-project-details-close {
  position: absolute;
  top: 5px;
  left: 28px;
  background-color: transparent;
  display: flex;
}
.revise-button {
  height: auto;
  width: auto;
  padding: 6px 10px;
  text-align: center;
  border: none;
  color: white;
  border-radius: 3px;
  background-color: #6200ee;
  /* margin-left: 22px; */
  /* margin-top: 15px; */
  font-weight: 750;
  cursor: pointer;
  font-family: "Lato", sans-serif;
  font-size: 13px;
  position: relative;
  left: 527px;
  top: -35px;
}
.revision-para {
  /* position: absolute;
  top: 830px;
  left: 18px; */
  color: #000;
  text-decoration: none;
}
.revision-main-container {
  position: relative;
  top: 70px;
  left: -769px;
}

.revision-details-container {
  position: fixed;
  top: 41px;
  left: 94px;
  background-color: transparent;
  display: flex;
}
.revision-details-container-close {
  position: fixed;
  top: 41px;
  left: 24px;
  background-color: transparent;
  display: flex;
}
.revise-task-close {
  position: relative;
  left: 2641px;
  font-size: 20px;
  color: blue;
  cursor: pointer;
}
.revision-summary {
  display: flex;
  justify-content: space-between;
  width: 56%;
}
.estimate-table-con {
  margin-top: 2px;
  display: flex;
  justify-content: space-between;
}
.estimate-details-table-close {
  width: 50vw;
  border-collapse: collapse;
  margin: 20px auto;
  overflow-x: auto;
  table-layout: fixed;
  position: relative;
  left: -6.5%;
  top: 0px;
  margin-right: 42px;
}
.estimate-details-table-close thead th {
  background-color: white;
  color: black;
  min-width: 2px;
  max-width: auto;
  /* font-weight: 400; */
  white-space: nowrap; /* Fix for text wrapping issues */
  text-align: left;
  padding: 10px;
  border: 1px solid #ddd;
}

.estimate-details-table-close tbody tr {
  text-align: left;
  background-color: white;
  border: 1px solid #ddd;
}

.estimate-details-table-close tbody tr:last-child {
  border-bottom: 1px solid #ddd;
}

.revision-details-table {
  width: 31vw;
  border-collapse: collapse;
  margin: 20px auto;
  overflow-x: auto;
  table-layout: fixed;
  position: relative;
  left: -21.9%;
  top: 0px;
  margin-right: 42px;
}
.revision-details-table thead th {
  background-color: white;
  color: black;
  min-width: 2px;
  max-width: auto;
  /* font-weight: 400; */
  white-space: nowrap; /* Fix for text wrapping issues */
  text-align: left;
  padding: 10px;
  border: 1px solid #ddd;
}

.revision-details-table tbody tr {
  text-align: left;
  background-color: white;
  border: 1px solid #ddd;
}

.revision-details-table tbody tr:last-child {
  border-bottom: 1px solid #ddd;
}
.revision-details-table-close {
  width: 31vw;
  border-collapse: collapse;
  margin: 20px auto;
  overflow-x: auto;
  table-layout: fixed;
  position: relative;
  left: -25.5%;
  top: 0px;
  margin-right: 42px;
}
.revision-details-table-close thead th {
  background-color: white;
  color: black;
  min-width: 2px;
  max-width: auto;
  /* font-weight: 400; */
  white-space: nowrap; /* Fix for text wrapping issues */
  text-align: left;
  padding: 10px;
  border: 1px solid #ddd;
}

.revision-details-table-close tbody tr {
  text-align: left;
  background-color: white;
  border: 1px solid #ddd;
}

.revision-details-table-close tbody tr:last-child {
  border-bottom: 1px solid #ddd;
}
.estimate-rev-details-table {
  width: 50vw;

  border-collapse: collapse;

  margin: 20px auto;

  overflow-x: auto;

  table-layout: fixed;

  position: relative;

  left: -2.8%;

  top: 0px;

  margin-right: 42px;
}

.estimate-rev-details-table thead th {
  background-color: white;

  color: black;

  min-width: 2px;

  max-width: auto;

  /* font-weight: 400; */

  white-space: nowrap; /* Fix for text wrapping issues */

  text-align: left;

  padding: 10px;

  border: 1px solid #ddd;
}

.estimate-rev-details-table tbody tr {
  text-align: left;

  background-color: white;

  border: 1px solid #ddd;
}

.estimate-rev-details-table tbody tr:last-child {
  border-bottom: 1px solid #ddd;
}

.estimate-rev-details-table-close {
  width: 50vw;
  border-collapse: collapse;
  margin: 20px auto;
  overflow-x: auto;
  table-layout: fixed;
  position: relative;
  left: -6.4%;
  top: 0px;
  margin-right: 42px;
}

.estimate-rev-details-table-close thead th {
  background-color: white;
  color: black;
  min-width: 2px;
  max-width: auto;
  /* font-weight: 400; */
  white-space: nowrap; /* Fix for text wrapping issues */
  text-align: left;
  padding: 10px;
  border: 1px solid #ddd;
}

.estimate-rev-details-table-close tbody tr {
  text-align: left;
  background-color: white;
  border: 1px solid #ddd;
}

.estimate-rev-details-table-close tbody tr:last-child {
  border-bottom: 1px solid #ddd;
}
.est-edit-button-con {
  border-top: 1px solid #616161;
  padding-top: 0px;
  position: fixed;
  top: 93%;
  height: 56px;
  width: 99.9%;
  left: 1297px;
}
.est-export-btn {
  height: 28px;
  width: auto;
  padding: 4px;
  position: absolute;
  text-align: center;
  border: 1px solid #6200ee;
  color: #6200ee;
  border-radius: 3px;
  text-wrap: nowrap;
  font-weight: 800;
  cursor: pointer;
  font-family: "Lato", sans-serif;
  font-size: 15px;
  top: 28px;
  left: 1681px;
}

.est-export-btn-close {
  height: 28px;
  width: auto;
  padding: 4px;
  position: absolute;
  text-align: center;
  border: 1px solid #6200ee;
  color: #6200ee;
  border-radius: 3px;
  text-wrap: nowrap;
  font-weight: 800;
  cursor: pointer;
  font-family: "Lato", sans-serif;
  font-size: 15px;
  top: 28px;
  left: 1751px;
}
.edit-est-projectmanager {
  top: 20px;
  position: relative;
  left: 4px;
}

.edit-est-admin {
  top: -4px;
  position: relative;
  left: 4px;
  height: 50px;
}
.est-list-row-count {
  border: 0.5px solid rgb(255 255 255 / 0%);
  padding: 5px;
  border-radius: 3px;
  margin-top: 12px;
  text-align: center;
  font-family: "Lato", sans-serif;
  font-size: 13px;
  cursor: pointer;
  outline: none;
  background-color: transparent;
}
.est-search-open {
  position: fixed;
  top: 75px;
  padding: 5px;
  border-radius: 3px;
  border: 0.5px solid;
  left: 88px;
  width: 280px;
}
.est-search-close {
  position: fixed;
  top: 75px;
  padding: 5px;
  border-radius: 3px;
  border: 0.5px solid;
  left: 17px;
  width: 280px;
}
@media (min-width: 1440px) and (max-width: 1919px) {
  .Toastify__toast-container {
    top: 13.5% !important;
    left: 50% !important;
    height: 100px;
    transform: translate(
      -50%,
      -50%
    ) !important; /* Center horizontally and vertically */
    position: fixed !important;
  }
  .est-delete-icon {
    left: 512px;
    top: 21px;
  }
  .est-delete-icon-close {
    left: 512px;
    top: 21px;
  }
  .delete-est-heading {
    top: -3.9%;
    left: 85.8%;
  }
  .est-phase-hours-summary {
    left: 81px;
    top: 705px;
    width: 1541px;
  }
  .convert-main-dial {
    width: 105%;
    left: -8px;
    top: -2px;
  }
  .estimation-pagination {
    top: 690px;
    left: 1269px;
  }
  .create-estimate-btn {
    top: -3%;
    left: 90.8%;
  }
  .estimation-container {
    width: 95.5%;
    height: 86vh;
    overflow-y: hidden;
    overflow-x: auto;
  }
  .estimation-container-close {
    height: 86vh;
    overflow-y: hidden;
    overflow-x: auto;
  }
  .scrollable-e-content {
    width: 100%;
    padding-bottom: 10px;
    height: 81.61%;
    overflow-y: auto;
    position: relative;
    left: 305px;
  }
  .add-estimation-heading {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .convert-dial {
    top: 9%;
    left: 22%;
    height: 657px;
    width: 60%;
  }
  .estimate-map-content {
    overflow: auto;
    height: 551px;
    width: 99%;
    padding-right: 5px;
    padding-bottom: 10px;
  }
  .convert-btn-container {
    top: -20px;
    left: 734px;
  }
  .form-content {
    width: 100%;
  }
  .task-hours-container {
    width: 65%;
  }
  .new-estimation-log {
    margin-left: 102.6%;
    margin-top: -0.9%;
  }
  .estimate-close {
    left: 1489px;
    top: 5px;
  }
  .estimate-close-close {
    left: 1489px;
    top: 5px;
  }
  .estimate-phase-close {
    left: 1502px;
    top: 74px;
  }
  .add-estimate-phase-btn {
    top: 72px;
    left: 1401px;
  }
  .task-e-btn-con {
    top: 447px;
  }
  .delete-estimate-phase-btn {
    top: 72px;
    left: 1288px;
  }
  .estimate-task-table {
    width: 37vw;
    left: -510px;
    top: -8px;
  }
  .estimate-task-table-close {
    width: 37vw;
    left: -510px;
    top: -8px;
  }
  .estimate-task-templates {
    width: 104vw;
    left: 926px;
    top: -30px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-left: 11px;
    height: 102%;
  }
  .estimate-task-templates-close {
    width: 104vw;
    left: 926px;
    top: -30px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-left: 11px;
    height: 102%;
  }
  .estimation-content-table {
    width: 130%;
  }

  /* //verify */
  .est-task-close {
    left: 1418px;
    z-index: 2;
    position: absolute;
    top: 19px;
  }
  .est-task-close-close {
    left: 1488px;
    z-index: 2;
    position: absolute;
    top: 19px;
  }
  .estimate-column-icon-con {
    top: 14%;
  }
  .es-pj-number-close {
    top: 22px;
  }
  .estimate-phase-table-close {
    margin-top: 2.2%;
    width: 98.5%;
  }
  .est-exclusion-close {
    min-width: 166px;
    max-width: 166px;
  }
  .estimation-content-table-close .convert-column {
    text-align: center;
  }
  .task-project-details {
    position: absolute;
    top: 10px;
    left: 21px;
    background-color: transparent;
    display: flex;
  }
  .task-project-details-close {
    position: absolute;
    top: 7px;
    left: 30px;
    background-color: transparent;
    display: flex;
  }
  .e-group-tasks {
    position: relative;
    left: 23px;
    top: -955px;
  }
  .e-group-tasks-close {
    position: relative;
    left: 31px;
    top: -999px;
  }
  .est-exclusion {
    min-width: 180px;
    max-width: 180px;
  }
  .e-group-template {
    left: 1px;
    top: -22px;
  }
  .estimate-details-container {
    width: 95%;
    top: 80px;
    margin-left: 80px;
  }
  .estimate-details-container-close {
    width: 100%;
    left: -14px;
    position: relative;
    top: 17px;
    margin-left: 97px;
  }
  .e-tt-container {
    position: absolute;
    top: 52px;
    left: 21px;
  }
  .e-tt-container-close {
    position: absolute;
    top: 52px;
    left: 30px;
  }
  .estimate-task-main-container {
    height: 94.3vh;
  }
  .estimate-task-main-container-close {
    height: 94.3vh;
  }
  .revise-task-close {
    left: 2261px;
  }

  .revision-details-table {
    left: -3%;
    width: 38vw;
  }

  .revision-details-table-close {
    left: -7.8%;
    width: 38vw;
  }

  .revision-details-container {
    position: absolute;
    top: -14px;
    left: 864px;
    width: 52%;
  }

  .revision-details-container-close {
    position: absolute;
    top: -14px;
    left: 792px;
    width: 52%;
  }

  .estimate-rev-details-table {
    left: 9%;
    margin-right: 42px;
  }

  .estimate-rev-details-table-close {
    left: 4.4%;
    margin-right: 42px;
  }
  .est-edit-button-con {
    border-top: 1px solid #616161;
    padding-top: 0px;
    position: fixed;
    top: 92%;
    height: 56px;
    width: 99.9%;
    left: 946px;
  }
  .edit-est-projectmanager {
    top: 20px;
    position: relative;
    left: 4px;
  }

  .edit-est-admin {
    top: -4px;
    position: relative;
    left: 4px;
    height: 50px;
  }

  .ephase-header {
    margin-top: -15px;
    margin-left: -3.1%;
  }
  .est-export-btn {
    top: 15px;
    left: 1291px;
  }
  .est-export-btn-close {
    top: 15px;
    left: 1361px;
  }
}
