.customer-form {
  margin: 5px;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 15px;
  overflow: auto;
}
.customer-label {
  font-size: 16px;
  margin-bottom: 5px;
  font-weight: 500;
  margin-top: 5px;
}
.customer-input {
  border: 0.5px solid;
  height: 30px;
  margin-bottom: 5px;
  padding-left: 5px;
  border-radius: 3px;
  font-family: "Lato", sans-serif;
  font-size: 15px;
  width: 560px;
}
.customer-input-dropdown {
  border: 0.5px solid;
  height: 30px;
  margin-bottom: 5px;
  padding-left: 5px;
  border-radius: 3px;
  font-family: "Lato", sans-serif;
  font-size: 15px;
  width: 567px;
}
.customer-btn-container {
  border-top: 0.5px solid;
  margin-top: 397px;
}
.customer-table-input {
  border: 0.5px solid;
  height: 30px;
  width: 70%;
}
.customer-contact {
  list-style: number;
}
.customer-details {
  display: flex;
  width: 25%;
}
.cus-name {
  width: 100px;
  margin-right: 45px;
}

.delete-customer-dial {
  position: fixed;
  text-align: left;
  top: 10%;
  left: 81.99%;
  z-index: 2;
  transform: translate(-50%, -20%);
  padding: 20px;
  border: 1px solid #ddd;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 35%;
  height: 21.61%;
  border-radius: 3px;
  animation: slideIn 1.5s forwards;
}
.edit-contact-dial {
  padding-left: 0px;
  height: 276px;
  top: 50px;
  left: 1599px;
  width: 300px;
}
.customer-pagination {
  left: -173px;
  top: -94px;
  margin-top: 762px;
  position: relative;
  margin-left: 1568px;
  display: flex;
  height: 40px;
  background-color: transparent;
  width: 260px;
}
.customer-pagination-close {
  left: -102px;
  top: -94px;
  margin-top: 762px;
  position: relative;
  margin-left: 1568px;
  display: flex;
  height: 40px;
  background-color: transparent;
  width: 260px;
}
.contact-pagination {
  top: 900px;
  left: 97px;
  position: relative;
  margin-left: 1568px;
  display: flex;
  height: 40px;
  background-color: transparent;
}
.customer-container {
  top: 15px;
  background-color: transparent;
  left: 0px;
  position: relative;
  width: 86vw;
  height: 72vh;
  overflow: hidden;
  padding-bottom: 30px;
}
.customer-container-close {
  top: 15px;
  background-color: transparent;
  left: 0px;
  position: relative;
  width: 89.8vw;
  height: 71vh;
  overflow: hidden;
  padding-bottom: 30px;
}
.customer-container::-webkit-scrollbar {
  display: none;
}
.customer-container-close::-webkit-scrollbar {
  display: none;
}
.customer-table {
  width: 99%;
  left: 0px;
  top: -13px;
  border-collapse: collapse;
  margin: 14px 0;
  position: absolute;
  margin-right: 15px;
  border-radius: 3px;
}
.customer-table thead th {
  background-color: white;
  color: black;
  /* font-weight: 400; */
  text-wrap: nowrap;
  user-select: none;
  text-align: left;
  padding: 10px;
  position: relative;
  border: 1px solid #ddd;
}
.customer-table tbody tr td {
  padding: 12px;
  border: 1px solid #ddd;
  text-align: left;
  white-space: nowrap;
}
.contact-table {
  top: 165px;
  position: fixed;
  left: 74px;
  height: 771px;
  width: 100%;
  overflow: auto;
  padding-bottom: 30px;
}
.contact-table::-webkit-scrollbar {
  display: none;
}
.contact-table-close {
  top: 165px;
  position: fixed;
  left: 74px;
  height: 771px;
  width: 100%;
  overflow: auto;
  padding-bottom: 30px;
}
.contact-table-close::-webkit-scrollbar {
  display: none;
}
.project-content-table-contacts {
  width: 94.2%;
  border-collapse: collapse;
  margin: 14px 0;
  position: absolute;
  top: -1%;
  left: 16px;
  margin-right: 15px;
  border-radius: 3px;
}
.project-content-table-contacts thead th {
  background-color: white;
  color: black;
  /* font-weight: 400; */
  text-wrap: nowrap;
  user-select: none;
  text-align: left;
  padding: 10px;
  position: relative;
  border: 1px solid #ddd;
}
.project-content-table-contacts th {
  border-bottom: 0.5px solid #ddd;
}
.project-content-table-contacts thead th .resize-handle {
  position: absolute;
  right: 0;
  top: 0;
  width: 15px;
  height: 100%;
  cursor: col-resize;
  background-color: transparent;
}
.project-content-table-contacts tbody tr {
  border: 1px solid #ddd;
  background-color: white;
  table-layout: fixed;
}
.project-content-table-contacts tbody td {
  padding: 12px;
  border: 1px solid #ddd;
  text-align: left;
  white-space: nowrap;
}

.project-content-table-contacts-close {
  width: 98%;
  border-collapse: collapse;
  margin: 14px 0;
  position: absolute;
  top: -1%;
  left: 16px;
  margin-right: 15px;
  border-radius: 3px;
}
.project-content-table-contacts-close thead th {
  background-color: white;
  color: black;
  /* font-weight: 400; */
  text-wrap: nowrap;
  user-select: none;
  text-align: left;
  padding: 10px;
  position: relative;
  border: 1px solid #ddd;
}
.project-content-table-contacts-close th {
  border-bottom: 0.5px solid #ddd;
}
.project-content-table-contacts-close thead th .resize-handle {
  position: absolute;
  right: 0;
  top: 0;
  width: 15px;
  height: 100%;
  cursor: col-resize;
  background-color: transparent;
}
.project-content-table-contacts-close tbody tr {
  border: 1px solid #ddd;
  background-color: white;
  table-layout: fixed;
}
.project-content-table-contacts-close tbody td {
  padding: 12px;
  border: 1px solid #ddd;
  text-align: left;
  white-space: nowrap;
}
.add-customer-dial {
  position: fixed;
  text-align: left;
  left: -0.1%;
  z-index: 2;
  transform: translate(-50%, -20%);
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  animation: slideIn 0.5s forwards;
  height: 92.9%;
  top: 5%;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 10px;
  width: 99%;
  background-color: rgba(0, 0, 0, 0.7);
}
.add-customer-content {
  width: 33%;
  padding-bottom: 10px;
  height: 100%;
  overflow-y: auto;
  margin-left: 69%;
}
.add-contact-dial {
  position: fixed;
  text-align: left;
  left: 67.9%;
  z-index: 2;
  transform: translate(-50%, -20%);
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  animation: slideIn 1s forwards;
  height: 877px;
  top: 52px;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 10px;
  width: 607px;
}
.edit-customer-dial {
  padding-left: 0px;
  margin-top: -46px;
  width: 98.1%;
  left: -0.1%;
  height: 95%;
  position: fixed;
  text-align: left;
  top: 9.9%;
  z-index: 2;
  transform: translate(-50%, -20%);
  padding: 20px;
  border: 1px solid #ddd;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  animation: slideIn 0.5s forwards;
  background-color: rgba(0, 0, 0, 0.7);
}
.edit-customer-content {
  width: 33%;
  padding-bottom: 10px;
  height: 101%;
  overflow-y: auto;
  margin-left: 68.8%;
}
.add-contact-dial {
  position: fixed;
  text-align: left;
  left: -0.1%;
  z-index: 2;
  transform: translate(-50%, -20%);
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  animation: slideIn 0.5s forwards;
  height: 877px;
  top: 49px;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 10px;
  width: 99.5%;
  background-color: rgba(0, 0, 0, 0.7);
}

.edit-customer-btn-container {
  margin-top: 665px;
  border-top: 0.5px solid;
}
.add-contact-btn-container {
  border-top: 0.5px solid;
  margin-left: -15px;
  margin-top: 527px;
}
.edit-contact-btn-container {
  margin-top: 535px;
  border-top: 0.5px solid;
}
.contacts-exit-arrow {
  color: blue;
  height: 20px;
  width: 20px;
  position: fixed;
  top: 68px;
  left: 1872px;
}
.add-new-customer {
  font-weight: 600;
  cursor: pointer;
  margin-bottom: 10px;
  height: auto;
  width: auto;
  padding: 7px 10px;
  border: none;
  color: white;
  background-color: #6200ee;
  border-radius: 3px;
  position: fixed;
  top: 4%;
  left: 91.8%;
  margin-top: 80px;
  font-family: "Lato", sans-serif;
  font-size: 13px;
}
.delete-group {
  font-weight: 600;
  cursor: pointer;
  margin-bottom: 10px;
  height: auto;
  width: auto;
  padding: 7px 10px;
  border: none;
  color: white;
  border-radius: 3px;
  position: fixed;
  top: 1%;
  left: 91.8%;
  margin-top: 80px;
  top: 38px;
  background-color: red;
  margin-left: -50px;
  margin-right: 16px;
  font-family: "Lato", sans-serif;
  font-size: 13px;
}
.delete-customer {
  font-weight: 600;
  cursor: pointer;
  margin-bottom: 10px;
  height: auto;
  width: auto;
  padding: 7px 10px;
  border: none;
  color: white;
  border-radius: 3px;
  position: fixed;
  top: 1%;
  left: 90.8%;
  margin-top: 80px;
  top: 38px;
  background-color: red;
  margin-left: -50px;
  margin-right: 16px;
}
.add-contact-heading {
  font-weight: 600;
  cursor: pointer;
  margin-bottom: 10px;
  height: auto;
  width: auto;
  padding: 7px 10px;
  border: none;
  color: white;
  background-color: #6200ee;
  border-radius: 3px;
  position: fixed;
  top: -1%;
  left: 90.8%;
  margin-top: 80px;
  top: 14px;
  margin-left: 49px;
}
.delete-contact-heading {
  font-weight: 600;
  cursor: pointer;
  margin-bottom: 10px;
  height: auto;
  width: auto;
  padding: 7px 10px;
  border: none;
  color: white;
  background-color: #6200ee;
  border-radius: 3px;
  position: fixed;
  top: 1%;
  left: 89.8%;
  margin-top: 85px;
}
.customer-status-dd {
  border: none;
  width: 220px;
  padding: 4px;
  height: 25px;
  font-weight: 400;
  font-family: "Lato", sans-serif;
  font-size: 15px;
  color: #000;
  background-color: transparent;
  border-radius: 3px;
  cursor: pointer;
}
.customer-status-dd:focus {
  border-color: white;
  outline: 3px solid #ffffff;
  outline-offset: 2px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
}
.customer-search-input {
  border: 0.5px solid;
  border-radius: 3px;
  padding: 5px;
  width: 200px;
  margin-top: 5px;
}
@media (min-width: 1440px) and (max-width: 1919px) {
  .delete-contact-heading {
    font-weight: 600;
    cursor: pointer;
    margin-bottom: 10px;
    height: auto;
    width: auto;
    padding: 7px 10px;
    border: none;
    color: white;
    background-color: #6200ee;
    border-radius: 3px;
    position: fixed;
    top: 0.4%;
    left: 88.5%;
    margin-top: 80px;
  }
  .contacts-exit-arrow {
    top: 59px;
    left: 1508px;
  }
  .add-contact-heading {
    left: 89.8%;
    top: 3px;
  }
  .delete-customer {
    left: 88.5%;
    top: 29px;
  }
  .delete-group {
    left: 90.5%;
    top: 29px;
  }
  .delete-customer-dial {
    top: 13.5%;
    height: 28.61%;
    left: 81.7%;
  }
  .edit-contact-dial {
    left: 65.6%;
    height: 657px;
    width: 30.6%;
  }
  .customer-pagination {
    left: -261px;
    margin-top: 584px;
    position: relative;
    margin-left: 1282px;
    display: flex;
    height: 40px;
    background-color: transparent;
    width: 260px;
  }
  .customer-container {
    top: 8px;
    width: 100%;
  }
  .customer-container-close {
    top: 8px;
    width: 100%;
  }
  .customer-table {
    top: 0px;
    width: 100%;
  }
  .contact-pagination {
    top: 682px;
    left: -275px;
    width: 258px;
  }
  .exit-arrow {
    top: 23px;
    left: 1353px;
  }
  .add-customer-dial {
    left: -1.4%;
    height: 93%;
    width: 100.6%;
    top: 6.5%;
  }
  .add-contact-dial {
    left: -0.4%;
    height: 660px;
    width: 99.6%;
  }
  .contact-table {
    width: 99%;
  }
  .contact-table-close {
    width: 100%;
  }
  .edit-customer-dial {
    width: 97.9%;
    height: 89%;
    left: -0.1%;
    top: 94px;
  }
  .customer-btn-container {
    margin-top: 183px;
  }
  .edit-customer-btn-container {
    margin-top: 452px;
  }
  .add-contact-btn-container {
    margin-top: 315px;
    border-top: 0.5px solid;
  }
  .edit-contact-btn-container {
    margin-top: 316px;
    border-top: 0.5px solid;
  }
  .add-new-customer {
    left: 89.8%;
  }
  .customer-pagination-close {
    left: -189px;
    margin-top: 584px;
    position: relative;
    margin-left: 1282px;
    display: flex;
    height: 40px;
    background-color: transparent;
    width: 260px;
  }
  .customer-input {
    width: 440px;
  }
}
