.project-phase-details.open {
  display: none;
}
.project-phase-details {
  position: fixed;
  background-color: transparent;
  top: 130px;
  left: 80px;
}
.project-phase-details-close {
  position: fixed;
  background-color: transparent;
  top: 130px;
  left: 6px;
}
.project-name-phase {
  font-weight: 400;
  font-size: 16px;
}
.project-name {
  font-weight: 400;
  font-size: 16px;
  margin-top: -3px;
  margin-left: 85px;
}
.customer-name {
  font-weight: 400;
  font-size: 16px;
  margin-top: -3px;
  margin-left: 64px;
}
.phase-count {
  margin-left: 10px;
}
.table-container {
  position: relative;
  margin-top: 7px;
  margin-left: -100px;
  top: 28%;
  left: 0px;
  height: 706px;
  width: 100vw;
  overflow-y: auto;
  overflow-x: auto;
  padding-bottom: 30px;
}
.table-container-close {
  position: relative;
  margin-top: 7px;
  margin-left: -100px;
  top: 28%;
  left: 0px;
  height: 706px;
  width: 100vw;
  overflow-y: auto;
  overflow-x: auto;
  padding-bottom: 30px;
}
.table-container::-webkit-scrollbar {
  display: none;
}
.table-container-close::-webkit-scrollbar {
  display: none;
}
.phase-table {
  width: 93vw;
  border-collapse: collapse;
  margin: 20px 0;
  position: relative;
  left: 5px;
  right: 12px;
  overflow-x: auto;
  margin-right: 54px;
}
.phase-table thead th {
  background-color: white;
  color: black;
  /* font-weight: 400; */
  text-wrap: nowrap;
  -webkit-user-select: none;
  user-select: none;
  text-align: left;
  padding: 10px;
  position: relative;
  border: 1px solid #ddd;
  padding-top: 6px;
  padding-bottom: 6px;
}

.phase-table tbody tr {
  text-align: left;
  border: 1px solid #ddd;
  background-color: white;
}
.phase-table tbody tr:last-child {
  border-bottom: 1px solid #ddd; /* Adjust the border style and color as needed */
}

.phase-table tbody tr:last-child {
  border-bottom: none;
}
.phase-table tbody td {
  /* remove this to resize the table back to normal*/
  border: 1px solid #ddd;
  text-align: left;
  white-space: nowrap;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 14px;
}
.phase-table-close {
  width: 96.8vw;
  border-collapse: collapse;
  margin: 20px 0;
  position: relative;
  left: 5px;
  right: 12px;
  overflow-x: auto;
  margin-right: 54px;
}
.phase-table-close thead th {
  background-color: white;
  color: black;
  /* font-weight: 400; */
  text-wrap: nowrap;
  -webkit-user-select: none;
  user-select: none;
  text-align: left;
  padding: 10px;
  position: relative;
  border: 1px solid #ddd;
  padding-top: 6px;
  padding-bottom: 6px;
}
.phase-table-close tbody tr {
  text-align: left;
  border: 1px solid #ddd;
  background-color: white;
}
.phase-table-close tbody tr:last-child {
  border-bottom: 1px solid #ddd; /* Adjust the border style and color as needed */
}

.phase-table-close tbody tr:last-child {
  border-bottom: none;
}
.phase-table-close tbody td {
  /* remove this to resize the table back to normal*/
  border: 1px solid #ddd;
  text-align: left;
  white-space: nowrap;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 14px;
}
.action-container {
  display: flex;
  justify-content: space-evenly;
  border: none;
}
.add-phase-dial {
  position: fixed;
  top: 13.7%;
  height: 38%;
  width: 13%;
  left: 86.9%;
  border: 1px solid #ddd;
  border-radius: 3px;
  overflow: auto;
  animation: slideIn 1s forwards;
}
.add-phase-dial::-webkit-scrollbar {
  display: none;
}
.add-phase-dial,
.phase-content-container,
.phase-btn-container,
.add-phase-heading,
.phase-input {
  background-color: white;
}
.dial-header {
  background-color: #f5f5f5;
  width: 100%;
  height: 28px;
  margin-top: -15px;
}
.dial-d-header {
  background-color: #f5f5f5;
  width: 106%;
  height: 28px;
  margin-top: -30px;
  margin-left: -15px;
}
.phase-input {
  border: 0.5px solid;
  padding: 8px;
  border-radius: 3px;
  font-family: "Lato", sans-serif;
  font-size: 15px;
}
.add-phase-heading {
  text-align: left;
  font-weight: 400;
  background-color: transparent;
  margin-bottom: 3px;
}
.phase-content-container {
  margin-left: 25px;
  margin-right: 25px;
  padding-bottom: 10px;
}
.phase-btn-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 10px;
  margin-left: 25px;
  margin-right: 25px;
}
.add-phase-dial-btn {
  height: auto;
  width: auto;
  margin-top: 14px;
  text-wrap: nowrap;
  padding: 5px 10px;
  font-weight: 600;
  text-align: center;
  border: none;
  color: white;
  border-radius: 3px;
  background-color: #6200ee;
  cursor: pointer;
}
/* @keyframes fadeInCenter {
  from {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.8);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
} */

.delete-phase-dial {
  position: fixed;
  width: 100%;
  top: 3.58%;
  left: -0.7%;
  background-color: rgba(0, 0, 0, 0.7);
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 3px;
  animation: fadeInCenter 1s forwards;
  height: 94%;
  z-index: 11;
}
.delete-dial {
  width: 25%;
  margin-left: 38%;
  margin-top: 17%;
  background-color: white;
  padding: 16px;
  border-radius: 3px;
}
.delete-phase-dial.open {
  display: block;
}
.delete-dial-btn-con {
  background-color: white;
  margin-top: 10px;
  margin-left: -14px;
  border-top: 0.5px solid;
  width: 105%;
}
.add-phase-btn {
  position: relative;
  height: auto;
  width: auto;
  right: -1439px;
  text-wrap: nowrap;
  padding: 7px 10px;
  text-align: center;
  border: none;
  color: white;
  border-radius: 3px;
  background-color: #6200ee;
  margin-right: 30px;
  margin-left: 40px;
  font-weight: 600;
  margin-top: 17px;
  font-family: "Lato", sans-serif;
  font-size: 13px;
  cursor: pointer;
}
.add-phase-btn-close {
  position: relative;
  height: auto;
  width: auto;
  right: -1557px;
  text-wrap: nowrap;
  padding: 7px 10px;
  text-align: center;
  border: none;
  color: white;
  border-radius: 3px;
  background-color: #6200ee;
  margin-right: 30px;
  margin-left: 40px;
  font-weight: 600;
  margin-top: 17px;
  font-family: "Lato", sans-serif;
  font-size: 13px;
  cursor: pointer;
}

.phase-tile {
  text-decoration: none;
  background-color: white;
  color: black;
  text-wrap: nowrap;
  margin-top: 4px;
}
.error-message {
  position: relative;
  top: 267px;
  left: 101px;
}
.edit-phase-details-dial {
  position: fixed;
  top: 13.8%;
  height: 37%;
  width: 13%;
  left: 86.9%;
  border: 1px solid #ddd;
  border-radius: 3px;
  overflow: auto;
  animation: slideIn 1.5s forwards;
}
.edit-phase-details-dial::-webkit-scrollbar {
  display: none;
}
.edit-phase-label {
  font-weight: 400;
  margin-left: 25px;
}
.phase-edit-input {
  border: 0.5px solid black;
  padding: 10px;
  margin-left: 25px;
  border-radius: 3px;
}
.edit-phase-btn-container {
  display: flex;
  border-top: 0.5px solid;
  margin-top: 5px;
}
.phase-hours-summary {
  display: flex;
  justify-content: space-between;
  border-top: 0.5px solid #ddd;
  width: 105%;
  position: fixed;
  left: 77px;
  top: 921px;
}
.phase-hours-summary-close {
  margin-left: -112px;
  display: flex;
  justify-content: space-between;
  border-top: 0.5px solid #ddd;
  width: 106.2%;
  position: fixed;
  top: 97.5%;
}
.phase-details-text {
  font-weight: 500;
  position: relative;
  margin-top: 1px;
}
.phase-details-text-close {
  font-weight: 500;
  position: relative;
  margin-top: 1px;
  margin-left: 15px;
}
.delete-phase-btn {
  background-color: red;
  margin-right: -27px;
  margin-left: -29px;
}
.phase-image {
  height: 300px;
  width: 300px;
  position: relative;
  left: 518px;
  top: -38px;
  width: fit-content;
}
.phase-image-container {
  margin-top: 50px;
  margin-left: 90px;
  width: fit-content;
}
.phase-message {
  position: relative;
  left: 548px;
  top: -55px;
}
.phase-image-variations {
  height: 300px;
  width: 300px;
  position: relative;
  left: 609px;
  top: 12px;
  width: fit-content;
}
.phase-message-variations {
  position: relative;
  left: 618px;
  top: -6px;
}
.phase-image-issues {
  height: 300px;
  width: 300px;
  position: relative;
  left: 549px;
  top: -38px;
  width: fit-content;
}
.phase-message-issues {
  position: relative;
  left: 658px;
  top: -55px;
}
.phase-pagination {
  display: flex;
  margin-top: 16px;
  margin-right: 177px;
}
.phase-pagination-close {
  display: flex;
  margin-top: 16px;
  margin-right: 120px;
}
.pagination-total {
  margin-top: -14px;
}

.phase-arrow-btn {
  border: none;
  margin-top: -26px;
  background-color: transparent;
  cursor: pointer;
}
.action-box {
  background-color: white;
  position: relative;
  top: -772px;
  left: 18px;
  display: flex;
  flex-direction: column;
  width: 196px;
  border: 0.5px solid black;
  padding-right: 22px;
  padding-bottom: 17px;
  border-radius: 3px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
}
.mass-update-phase {
  position: relative;
  left: -116px;
}
.bulk-select {
  border: 0.5px solid black;
  padding: 5px;
  border-radius: 3px;
  margin-left: 20px;
  margin-top: 13px;
  text-align: center;
  font-family: "Lato", sans-serif;
  font-size: 13px;
  cursor: pointer;
}
.add-phase-content {
  background-color: white;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 67%;
  margin-top: -10px;
  width: 31%;
  height: 84.5%;
  padding-left: 15px;
  padding-right: 15px;
}
.edit-btn-con-second {
  background-color: white;
  display: flex;
  flex-direction: row;
  margin-left: 67%;
  border-top: 0.5px solid;
  margin-top: -3%;
  padding-bottom: 14px;
}
.invoice-input-dial {
  position: fixed;
  /* display: flex
; */
  /* flex-direction: column; */
  top: 491px;
  width: 668px;
  left: 611px;
  padding: 10px;
  border-top: 0.5px solid black;
  /* height: 170px; */
  /* border-radius: 3px; */
  background-color: #ffffff;
  padding-left: 30px;
}
.invoice-input-dial-con {
  position: fixed;
  width: 100%;
  top: 3.58%;
  left: -0.7%;
  background-color: rgba(0, 0, 0, 0.7);
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 3px;
  animation: fadeInCenter 1s forwards;
  height: 94%;
  z-index: 11;
}
.invoice-d-header {
  background-color: #f5f5f5;
  width: 102%;
  height: 26px;
  margin-top: -25px;
  margin-left: -8px;
  padding-left: 9px;
  padding-bottom: 1px;
}
.enter-invoice-details-close {
  position: relative;
  top: -2px;
  left: 270px;
  color: blue;
  font-size: 18px;
  background-color: transparent;
  cursor: pointer;
}
.invoice-details-button {
  position: fixed;
  width: 50px;
  top: 498px;
  left: 1164px;
}
.invoice-details-can-button {
  position: fixed;
  width: 63px;
  top: 498px;
  left: 1240px;
}
.enter-invoice-details-input {
  padding-left: 5px;
  border: 0.5px solid;
  margin-bottom: 5px;
  background-color: white;
  border-radius: 3px;
  height: 25px;
  width: 132px;
  font-family: "Lato", sans-serif;
  font-size: 15px;
  margin-left: 8px;
}
.enter-invoice-details-date {
  padding-left: 5px;
  border: 0.5px solid;
  margin-bottom: 5px;
  background-color: white;
  border-radius: 3px;
  height: 25px;
  width: 92px;
  font-family: "Lato", sans-serif;
  font-size: 15px;
  margin-left: 9px;
}
.invoice-table-container {
  position: fixed;
  width: 100%;
  top: 3.58%;
  left: -0.7%;
  background-color: rgba(0, 0, 0, 0.7);
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 3px;
  animation: fadeInCenter 1s forwards;
  height: 94%;
  z-index: 11;
}
.phase-invoice-table {
  width: 33vw;
  border-collapse: collapse;
  position: relative;
  left: 36px;
  right: 12px;
  overflow-x: auto;
  margin-right: 54px;
  top: 15px;
}

.phase-invoice-table thead th {
  background-color: white;
  color: black;
  /* font-weight: 400; */
  text-wrap: nowrap;
  text-align: left;
  padding: 10px;
  position: relative;
  border: 1px solid #ddd;
}
.phase-invoice-table tbody tr {
  text-align: left;
  border: 1px solid #ddd;
  background-color: white;
}
.phase-invoice-table tbody tr:last-child {
  border-bottom: 1px solid #ddd; /* Adjust the border style and color as needed */
}

.phase-invoice-table tbody tr:last-child {
  border-bottom: none;
}
.phase-invoice-table-con {
  width: 36.8%;
  left: 596px;
  top: 150px;
  position: relative;
  height: 44%;
  overflow-y: auto;
  border: solid 0.5px #ddd;
  border-radius: 3px;
}
.phase-invoice-table-con-close {
  position: relative;
  top: 12px;
  left: 660px;
  color: blue;
  font-size: 18px;
  background-color: transparent;
  cursor: pointer;
  z-index: 3;
}
.phase-invoice-table-2 {
  background-color: transparent;
  position: relative;
  top: 8%;
  overflow-y: auto;
  height: 74%;
}
.invoice-project-details {
  display: flex;
  top: -6px;
  left: 34px;
  position: absolute;
}
.invoice-percentage-input {
  margin-left: 165px;
}
.invoice-icon {
  font-size: 23px;
  position: relative;
  left: 15px;
}
.delete-invoice-btn {
  height: 30px;
  width: auto;
  padding: 6px 10px;
  text-align: center;
  border: none;
  color: white;
  border-radius: 3px;
  background-color: red;
  font-weight: 750;
  cursor: pointer;
  font-family: "Lato", sans-serif;
  font-size: 13px;
  position: relative;
  top: 11px;
  left: 150px;
}
.invoice-no-input {
  text-align: left;
  border: none;
  width: 135px;
  padding: 5px;
  font-weight: 400;
  font-family: "Lato", sans-serif;
  font-size: 15px;
  color: #000;
  background-color: transparent;
  height: 34px;
  margin-left: 5px;
}
.invoice-no-input:focus {
  border-color: white;
  outline: 3px solid #ffffff;
  outline-offset: 2px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
  background-color: transparent;
  border-radius: 3px;
}
.invoice-percentage-input-dial {
  text-align: center;
  border: none;
  width: 38px;
  padding: 5px;
  font-weight: 400;
  font-family: "Lato", sans-serif;
  font-size: 13px;
  color: #000;
  background-color: transparent;
  height: 34px;
  margin-left: 2px;
}
.invoice-percentage-input-dial:focus {
  border-color: white;
  outline: 3px solid #ffffff;
  outline-offset: 2px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
  background-color: transparent;
  border-radius: 3px;
}

.enter-invoice-details-date-table {
  padding-left: 5px;
  border: none;
  margin-bottom: 5px;
  background-color: white;
  border-radius: 3px;
  height: 25px;
  width: 92px;
  font-family: "Lato", sans-serif;
  font-size: 15px;
  margin-left: 2px;
}
.enter-invoice-details-date-table:focus {
  border-color: white;
  outline: 3px solid #ffffff;
  outline-offset: 2px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
  background-color: transparent;
  border-radius: 3px;
}

.phase-stage-dropdown {
  border-radius: 3px;
  padding: 9px;
  appearance: none;
  width: 140px;
  border: none;
  text-align: center;
  color: black;
  font-weight: normal;
}
.phase-stage-dropdown:focus {
  outline: 3px solid #ffffff;
  outline-offset: 2px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
  background-color: transparent;
  border-radius: 3px;
}
.phase-stage-dropdown {
  padding: 9px;
  appearance: none;
  width: 140px;
  border: none;
  text-align: center;
  color: rgb(255, 255, 255);
  font-weight: normal;
  font-family: "Lato", sans-serif;
  font-size: 14px;
}
.phase-stage-dropdown:focus {
  outline: 3px solid #ffffff;
  outline-offset: 2px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
  background-color: transparent;
  color: rgb(0, 0, 0);
}

@media (min-width: 1440px) and (max-width: 1919px) {
  .dial-d-header {
    width: 108%;
  }
  .phase-table-close {
    width: 97.8vw;
  }
  .add-phase-content {
    height: 53.5%;
    width: 31.3%;
  }
  .edit-btn-con-second {
    width: 33.5%;
    margin-top: -4.5%;
  }
  .add-phase-btn {
    right: -1080px;
  }
  .table-container {
    height: 490px;
  }
  .table-container-close {
    height: 485px;
  }
  .add-phase-btn-close {
    right: -1204px;
  }
  .edit-phase-details-dial {
    top: 17.7%;
    height: 49%;
    width: 15%;
    left: 85.4%;
  }
  .add-phase-dial {
    top: 17.7%;
    height: 49%;
    width: 15%;
    left: 85.4%;
  }
  .phase-table {
    width: 93vw;
  }
  .phase-image-container {
    margin-left: 0px;
  }
  .phase-pagination {
    margin-right: 156px;
  }
  .phase-pagination-close {
    margin-right: 99px;
  }
  .action-box {
    top: -557px;
  }
  .phase-hours-summary-close {
    margin-top: -10px;
  }
  .phase-hours-summary {
    position: fixed;
    top: 701px;
    left: 71px;
  }
  .delete-dial-btn-con {
    width: 107.7%;
  }
  .phase-invoice-table-con {
    width: 44.4%;
    left: 416px;
    top: 110px;
    height: 47%;
  }

  .phase-invoice-table-con-close {
    left: 638px;
    top: 7px;
  }

  .invoice-project-details {
    top: -6px;
    left: 34px;
  }

  .phase-invoice-table-2 {
    height: 70%;
    overflow-x: hidden;
  }

  .invoice-input-dial {
    top: 385px;
    width: 643px;
    padding-left: 29px;
    left: 432px;
    height: 40px;
  }

  .enter-invoice-details-date {
    width: 120px;
  }

  .enter-invoice-details-input {
    width: 120px;
  }

  .invoice-details-button {
    top: 389px;
    left: 961px;
  }
  .invoice-details-can-button {
    top: 390px;
    left: 1034px;
  }

  .invoice-details-header {
    font-size: 14px;
  }

  .invoice-percentage-input {
    margin-left: 194px;
  }

  .phase-invoice-table {
    width: 91%;
  }
}
