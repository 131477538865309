.project-users-main-container {
  margin-left: 79px;
  margin-top: 131px;
}
.project-users-main-container-close {
  margin-left: 6px;
  margin-top: 131px;
}
.users-table-container {
  position: relative;
  top: 48px;
  margin: 0px;
  padding-bottom: 50px;
  overflow-y: auto;
  overflow-x: auto;
  height: 683px;
}
.user-search-filter {
  height: 27px;
  width: 264px;
  text-align: left;
  padding: 10px;
  border-radius: 3px;
  border: 0.5px solid;
  font-family: "Lato", sans-serif;
  font-size: 13px;
}
.user-content-table {
  width: 94vw;
  border-collapse: collapse;
  margin: 20px 0;
  position: absolute;
  top: -18px;
  left: 13px;
  text-wrap: nowrap;
  overflow-x: auto;
}
.user-content-table thead th {
  background-color: white;
  user-select: none;
  color: black;
  font-weight: 600;
  text-wrap: nowrap;
  text-align: center;
  padding: 10px;
  position: relative;
  border: 1px solid #ddd;
}
.user-content-table-close {
  width: 97.8vw;
  border-collapse: collapse;
  margin: 20px 0;
  position: absolute;
  top: -18px;
  left: 13px;
  text-wrap: nowrap;
  overflow-x: auto;
}
.user-content-table-close thead th {
  background-color: white;
  user-select: none;
  color: black;
  font-weight: 600;
  text-wrap: nowrap;
  text-align: center;
  padding: 10px;
  position: relative;
  border: 1px solid #ddd;
}
.user-content-table-close thead th .resize-handle {
  position: absolute;
  right: 0;
  top: 0;
  width: 15px;
  height: 100%;
  cursor: col-resize;
  background-color: transparent;
}
.user-content-table-close tbody tr {
  text-align: left;
  border: 1px solid #ddd;
  background-color: white;
}
.user-content-table-close td {
  font-weight: 500;
}
.resize-handle {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 5px;
  cursor: col-resize;
}
.table-icon {
  margin-right: 5px;
  margin-left: 5px;
  margin-top: 0px;
  height: 16px;
  width: 16px;
}
.user-select-dropdown {
  padding: 10px;
  left: -120px;
  border: none;
  position: relative;
  color: #6200ee;
  font-weight: 100;
  font-size: 16px;
  background-color: transparent;
}

.user-dropdown-container {
  position: fixed;
  top: 200px;
}
.add-user-container {
  position: fixed;
  display: flex;
  justify-content: flex-end;
  margin-right: 55px;
  top: 249px;
  left: 1100px;
}
.user-dropdown-add-user-container {
  background-color: #616161;
  z-index: 1;
}
.add-user-heading {
  font-weight: 100;
  position: relative;
  top: -45px;
  cursor: pointer;
  padding: 10px;
  text-align: center;
  border: none;
  background-color: #6200ee;
  color: white;
  border-radius: 7px;
  margin-right: 30px;
  margin-left: 40px;
}
.add-user-dialog {
  width: 500px;
  position: fixed;
  top: 200px;
  right: 13px;
  display: flex;
  padding: 15px;
  flex-direction: column;
  background-color: white;
  border: 1px solid grey;
  border-radius: 7px;
}
.dial-box-seperator,
.add-user-dialog-header {
  background-color: white;
}
.add-new-user-heading {
  margin-bottom: 5px;
}
.label-element {
  background-color: white;
  margin-bottom: 05px;
  font-weight: 400;
}
.label-header-element {
  margin-bottom: 05px;
  font-weight: 400;
}
element {
  background-color: white;
  margin-bottom: 05px;
  font-weight: 400;
}
.btn-label {
  margin-top: 05px;
  background-color: white;
  align-items: center;
  margin-bottom: 10px;
}
.btn-contaier {
  display: flex;
  flex-direction: row;
}
.add-user-dialog-header {
  position: relative;
  text-wrap: nowrap;
  top: -24px;
  height: 26px;
}
.dial-box-seperator {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 396px;
}
.add-new-user-btn {
  color: #6200ee;
  background-color: transparent;
  margin-top: -8px;
  margin-left: 284px;
  border-radius: 7px;
  height: 31px;
  text-wrap: nowrap;
  border: 1px solid #6200ee;
}
.add-new-user-container {
  position: relative;
  bottom: 483px;
  left: -494px;
  width: 360px;
  margin-left: 830px;
  display: flex;
  padding: 15px;
  flex-direction: column;
  border: 1px solid grey;
  border-radius: 7px;
}
.add-new-user-button-container {
  display: flex;
  padding-top: 10px;
}
.existing-user-add-dial {
  background-color: white;
  max-height: 10px;
}
.existing-user-table-details {
  border-collapse: none;
  margin-left: -447px;
  width: 100%;
  margin-top: 24px;
  overflow: scroll;
  background-color: white;
  text-wrap: 24px;
  box-sizing: border-box;
}
.existing-user-table-details thead tr th {
  margin-right: 5px;
  background-color: white;
}
.existing-user-table-details tr td {
  padding: 5px;
  border: none;
}
.existing-user-btn-dial-container {
  position: fixed;
  top: 580px;
  right: 21px;
  display: flex;
  justify-content: space-evenly;
  background-color: white;
  flex-direction: row;
  width: 522px;
}
.user-table-container.open,
.add-user-dialog.open,
.add-new-user-container.open,
.filters-container.open {
  display: none; /* when dialBoxOpen is true */
}
.filters-container {
  position: fixed;
  top: 146px;
  left: 90px;
}
.filters-container-close {
  position: fixed;
  top: 146px;
  left: 19px;
}
.delete-user-dial {
  position: fixed;
  width: 100%;
  top: 3.58%;
  left: -0.7%;
  background-color: rgba(0, 0, 0, 0.7);
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 3px;
  animation: fadeInCenter 1s forwards;
  height: 94%;
  z-index: 11;
}
.delete-con {
  width: 25%;
  margin-left: 38%;
  margin-top: 17%;
  background-color: white;
  padding: 16px;
  border-radius: 3px;
}
.delete-user-header {
  background-color: #f5f5f5;
  margin-top: -31px;
  margin-left: -15px;
  width: 106.2%;
  height: 26px;
}
.delete-btn-container {
  background-color: white;
  margin-top: 10px;
  margin-left: -14px;
  border-top: 0.5px solid;
  width: 106%;
}

.project-user-pagination-controls {
  margin-top: 762px;
  position: relative;
  margin-left: 1582px;
  display: flex;
  height: 40px;
  background-color: transparent;
}
.project-user-pagination-controls-close {
  margin-top: 762px;
  position: relative;
  margin-left: 1655px;
  display: flex;
  height: 40px;
  background-color: transparent;
}

@media (min-width: 1440px) and (max-width: 1919px) {
  .users-table-container {
    height: 456px;
  }
  .user-content-table {
    width: 92vw;
  }
  .user-content-table-close {
    width: 96.7vw;
  }
  .project-user-pagination-controls {
    margin-left: 1182px;
  }
  .project-user-pagination-controls-close {
    margin-left: 1255px;
  }
}
