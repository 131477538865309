.settings-container {
  position: relative;
  top: -13px;
  left: -4px;
  height: 765px;
  width: 101%;
  padding-bottom: 30px;
}

.settings-container::-webkit-scrollbar {
  display: none;
  height: 12px;
}
.settings-container::-webkit-scrollbar-thumb {
  background-color: lightgray;
}
.settings-container::-webkit-scrollbar-track {
  background: white; /* Track color */
}
.user-settings-tab-details {
  position: fixed;
  top: 137px;
  border-bottom: 0.5px solid;
  width: 86.3%;
  background-color: transparent;
  padding-bottom: 12px;
  left: 232px;
}
.user-settings-tab-details-close {
  position: fixed;
  top: 137px;
  border-bottom: 0.5px solid;
  width: 90%;
  background-color: transparent;
  padding-bottom: 12px;
  left: 159px;
}
.settings-content-table {
  width: 100%;
  border-collapse: collapse;
  margin: 14px 0;
  position: absolute;
  top: 7%;
  left: 0px;
  margin-right: 15px;
  border-radius: 3px;
}
.settings-content-table thead th {
  background-color: white;
  color: black;
  /* font-weight: 400; */
  user-select: none;
  text-align: left;
  padding: 10px;
  position: relative;
  border: 1px solid #ddd;
  min-width: 82px;
}
.settings-content-table th {
  border-bottom: 0.5px solid #ddd;
}
.settings-content-table thead th .resize-handle {
  position: absolute;
  right: 0;
  top: 0;
  width: 15px;
  height: 100%;
  cursor: col-resize;
  background-color: transparent;
}
.settings-content-table tbody tr {
  border: 1px solid #ddd;
  background-color: white;
  table-layout: fixed;
}
.settings-content-table-close {
  width: 100%;
  border-collapse: collapse;
  margin: 14px 0;
  position: absolute;
  top: -87%;
  left: 0px;
  margin-right: 15px;
  border-radius: 3px;
}
.settings-content-table-close thead th {
  background-color: white;
  color: black;
  /* font-weight: 400; */
  user-select: none;
  text-align: left;
  padding: 10px;
  position: relative;
  border: 1px solid #ddd;
}
.settings-content-table-close th {
  border-bottom: 0.5px solid #ddd;
}
.settings-content-table-close thead th .resize-handle {
  position: absolute;
  right: 0;
  top: 0;
  width: 15px;
  height: 100%;
  cursor: col-resize;
  background-color: transparent;
}
.settings-content-table-close tbody tr {
  border: 1px solid #ddd;
  background-color: white;
  table-layout: fixed;
}
.settings-content-table-close tbody td {
  /* padding: 12px; */
  border: 1px solid #ddd;
  text-align: left;
  white-space: nowrap;
}
.edit-project-btn {
  background-color: transparent;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 10px;
  padding-left: 10px;
  border: none;
  border-radius: 7px;
  color: blue;
}
.settings-content-table tbody td {
  /* padding: 12px; */
  border: 1px solid #ddd;
  text-align: left;
  white-space: nowrap;
}

.add-project-container {
  display: flex;
  justify-content: flex-end;
  margin-right: 55px;
  position: relative;
  top: 55px;
  left: -190px;
}
.add-project-heading {
  font-weight: 600;
  cursor: pointer;
  margin-bottom: 10px;
  height: auto;
  width: auto;
  padding: 10px 10px;
  border: none;
  color: white;
  background-color: #6200ee;
  border-radius: 3px;
  position: relative;
  top: 35%;
  left: 91%;
  margin-top: 130px;
}
@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.add-project-dialog {
  position: fixed;
  text-align: left;
  top: 23%;
  left: 81.4%;
  z-index: 2;
  transform: translate(-50%, -20%);
  padding-bottom: 0px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 35%;
  height: 85.71%;
  border-radius: 3px;
  animation: slideIn 1s forwards;
}
.add-group-dial {
  height: 421px;
  margin-left: 20px;
  top: 50px;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 10px;
  width: 335px;
}
.add-project-content {
  width: 32%;
  padding-bottom: 10px;
  height: 100%;
  overflow-y: auto;
  margin-left: 69%;
}
.add-role-dial {
  width: 99%;
  height: 94%;
  right: 1px;
  left: -0.1%;
  position: fixed;
  top: 49px;
  display: flex;
  padding: 15px;
  flex-direction: column;
  border: 1px solid grey;
  border-radius: 3px;
  animation: slideIn 0.5s forwards;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 2;
}
.add-role-settings {
  background-color: white;
  padding: 15px;
  margin-right: -8px;
  margin-left: 68%;
  margin-top: -0.9%;
  height: 98%;
}
.add-role-header {
  position: relative;
  margin-top: -15px;
  margin-bottom: 0px;
  font-weight: 500;
  font-size: 16px;
  /* padding: 1px; */
  background-color: #f5f5f5;
  width: 615px;
  margin-left: -2.5%;
  padding: 4px;
  z-index: 1;
}
.add-task-template-dial {
  background-color: white;
  padding: 15px;
  margin-right: -8px;
  margin-left: 68%;
  margin-top: -0.9%;
  height: 98%;
}

.add-project-content::-webkit-scrollbar {
  display: none;
}

.add-project-user-heading {
  color: black;
  margin-top: 0px;
  margin-bottom: 1px;
  font-weight: 600;
  font-size: 16px;
  background-color: transparent;
}
.add-project-user-header {
  color: black;
  position: fixed;
  margin-top: -20px;
  margin-bottom: 0px;
  font-weight: 500;
  font-size: 16px;
  padding: 1px;
  background-color: #f5f5f5;
  width: 100%;
  margin-left: -20px;
  padding: 4px;
  z-index: 1;
}

.box-p-heading {
  color: black;
  font-size: 12px;
  margin-left: 25px;
  font-weight: 400;
  font-size: 16px;
  background-color: transparent;
  margin-top: -1px;
  margin-bottom: 5px;
}
.box-l-heading {
  color: black;
  font-size: 12px;
  margin-top: 1px;
  margin-left: 5px;
  font-weight: 500;
  font-size: 16px;
  background-color: transparent;
  margin-bottom: 5px;
}
.label-heading {
  color: black;
  font-size: 12px;
  background-color: transparent;
  margin-bottom: 5px;
}
.billing-select {
  background-color: none;
}
.start-date-due-date-container {
  display: flex;
  flex-direction: row;
  background-color: transparent;
  margin-bottom: 5px;
}
.settings-name-number-container {
  display: flex;
  flex-direction: row;
  background-color: transparent;
}
.name-container {
  background-color: transparent;
}
.start-dat-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: transparent;
}
.due-date-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: transparent;
  margin-left: 14px;
}
.settings-owner-budget-container {
  display: flex;
  flex-direction: row;
  background-color: transparent;
  margin-top: -5px;
}
.template-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 29px;
  width: 174px;
}
.owner-dropdown {
  background-color: white;
}
.role-input {
  padding-left: 5px;
  border: none;
  margin-bottom: 5px;
  background-color: white;
  border-radius: 3px;
  height: 30px;
  width: auto;
  font-family: "Lato", sans-serif;
  font-size: 15px;
  cursor: pointer;
}
.role-input:focus {
  border-color: white;
  outline: 3px solid #ffffff;
  outline-offset: 2px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
}
/* .box-input,
.select-input {
  padding-left: 5px;
  border: 0.5px solid;
  margin-bottom: 5px;
  background-color: white;
  border-radius: 3px;
  height: 30px;
  width: 320px;
} */
/* .box-des-input {
  padding-left: 5px;
  border: 0.5px solid;
  margin-bottom: 5px;
  background-color: white;
  border-radius: 3px;
  width: 663px;
  height: 51px;
} */
/* .select-d-input {
  padding-left: 5px;
  border: 0.5px solid;
  margin-bottom: 5px;
  background-color: white;
  border-radius: 3px;
  height: 30px;
  width: 327px;
} */
.owner-template-container {
  display: flex;
  flex-direction: row;
  background-color: transparent;
  margin-bottom: 5px;
}
.owner-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: transparent;
}
.template-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: transparent;
  margin-left: 18px;
}
.settings-strict-container {
  display: flex;
  flex-direction: row;
  background-color: transparent;
  margin-bottom: 5px;
}
.box-heading-label {
  color: black;
  font-size: 15px;
  background-color: transparent;
  margin-bottom: 5px;
  margin-left: 10px;
}
.add-description-container {
  flex-direction: column;
  background-color: transparent;
  margin-bottom: 5px;
}
.settings-select-container {
  display: flex;
  flex-direction: column;
  background-color: transparent;
  margin-bottom: 10px;
  margin-top: -12px;
}
.settings-new-project-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: transparent;
}
.tags-container {
  flex-direction: column;
  background-color: transparent;
  margin-bottom: 5px;
  margin-left: 15px;
  margin-top: -1px;
}
.add-project-hr-line {
  margin-bottom: 10px;
}
.settings-details-container {
  display: flex;
  flex-direction: column;
  background-color: transparent;
  margin-bottom: 10px;
  margin-left: 1px;
}
.rmsc .member-select-option {
  position: relative;
  outline: 0;
  background-color: white;
  border: 1px solid;
  border-radius: 3px;
  width: 670px;
  margin-left: -1px;
}
.currency-billing-method-container {
  display: flex;
  flex-direction: row;
  background-color: transparent;
  margin-bottom: 5px;
}
.currency-container {
  display: flex;
  flex-direction: column;
  background-color: transparent;
}
.billing-method-container {
  display: flex;
  flex-direction: column;
  background-color: transparent;
  margin-left: 18px;
}
.budget-container {
  background-color: transparent;
}
.customize-row-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: tr;
  background-color: transparent;
}
.customize-container {
  justify-content: space-around;
  background-color: transparent;
}
.individual-task-container {
  background-color: white;
  border: 1px solid #616161;
  padding: 10px;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 5px;
  width: 110px;
}
.check-box-content {
  margin-left: 20px;
}

.privacy-container {
  background-color: transparent;
}

.member-select-option .dropdown-container {
  position: relative;
  outline: 0;
  background-color: white;
  border: 1px solid;
  border-radius: 3px;
  width: 32%;
  margin-left: -1px;
  max-width: 100%;
}
.dropdown-heading,
.dropdown-heading-value,
.gray {
  background-color: white;
}
html::-webkit-scrollbar,
body::-webkit-scrollbar {
  display: none;
}
.edit-project-user-header {
  color: black;
  position: fixed;
  margin-top: -30px;
  margin-left: -35px;
  margin-bottom: 0px;
  font-weight: 500;
  font-size: 16px;
  padding: 1px;
  background-color: #f5f5f5;
  width: 100%;
  padding: 4px;
  z-index: 1;
}

.dialog-content {
  display: flex;
  flex-direction: column;
  min-height: auto;
  padding: 15px;
  overflow-y: scroll;
  max-height: 694px;
  padding-bottom: 50px;
}
.dialog-content::-webkit-scrollbar {
  display: none;
}

.settings-nav-bar-details {
  position: fixed;
  margin-left: 92px;
  top: 4%;
  width: 100%;
}
.proj-links {
  text-decoration: none;
  color: black;
  margin-right: 25px;
}
.proj-links.active {
  color: #6200ee;
  font-weight: 600;
}
.proj-line {
  margin-left: -23px;
  border-top: 1px solid;
}
.group-name {
  text-decoration: none;
  color: black;
}
.group-name:hover {
  color: #6200ee;
}
.email-settings-form {
  display: flex;
  flex-direction: column;
  width: 320px;
}
.email-service-header {
  margin-right: 10px;
  display: flex;
  justify-content: space-between;
  width: 300px;
  margin-top: 10px;
  margin-bottom: 4px;
}
.email-input {
  padding-left: 10px;
  height: 28px;
  border: 0.5px solid;
  margin: 5px;
  width: 250px;
  border-radius: 3px;
  font-family: "Lato", sans-serif;
  font-size: 15px;
}
.email-dropdown {
  padding-left: 10px;
  height: 33px;
  border: 0.5px solid;
  margin: 5px;
  width: 262px;
  border-radius: 3px;
  font-family: "Lato", sans-serif;
  font-size: 15px;
  cursor: pointer;
}
.email-checkbox {
  width: 13px;
  top: -4.5%;
  left: 50%;
  position: relative;
  cursor: pointer;
}
.role-list {
  display: flex;
  flex-direction: row;
}
.role-name {
  margin-top: 4px;
  margin-bottom: 2px;
}
.role-description {
  margin-top: 2px;
}
.estimate-task-container {
  width: 350px;
  height: 783px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding-left: 15px;
  padding-top: 10px;
}
.actions-container {
  display: flex;
  justify-content: space-between;
  width: 74px;
  margin-top: 10px;
}
.role-right-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 86%;
}
.close-icon {
  position: fixed;
  left: 1840px;
  color: blue;
  top: 134px;
  z-index: 2;
  cursor: pointer;
}
.checkbox-container {
  display: flex;
  flex-direction: row;
  position: relative;
  left: 155px;
  top: -35px;
}
.action-label {
  margin-right: 25px;
  cursor: pointer;
}
.action-div {
  display: flex;
  margin-left: 143px;
}
.actions {
  margin-right: 50px;
}
.add-user-settings-btn {
  position: fixed;
  top: 80px;
  left: 1765px;
}
.user-pagination {
  right: 18px;
  width: 260px;
  position: fixed;
  margin-left: 1678px;
  display: flex;
  height: 40px;
  background-color: transparent;
  top: 878px;
}
.user-pagination-close {
  right: 212px;
  margin-top: 753px;
  width: 260px;
  position: relative;
  margin-left: 1678px;
  display: flex;
  height: 40px;
  background-color: transparent;
}
.task-templates-container {
  display: flex;
  position: relative;
  top: -2px;
}
.task-container {
  width: 350px;
  height: 783px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding-left: 15px;
  padding-top: 10px;
}
.templates-container {
  margin-left: 40px;
  width: 67vw;
}
.new-task-btn {
  background-color: #6200ee;
  color: white;
  border: none;
  border-radius: 3px;
  padding: 5px 10px;
  height: 30px;
  font-weight: 600;
  position: fixed;
  left: 1782px;
  cursor: pointer;
  font-family: "Lato", sans-serif;
  font-size: 13px;
}
.template-list {
  list-style: none;
}
.est-settings-template-list {
  list-style: none;
}
.template-names {
  cursor: pointer;
  width: 450px;
  background-color: transparent;
  display: flex;
}
.estimate-template-names {
  cursor: pointer;
  width: 350px;
}
.estimate-template-names:hover .edit-icon-wrapper {
  visibility: visible;
  margin-left: 100px;
}
.template-names:hover .edit-icon-wrapper {
  visibility: visible;
  margin-left: 100px;
}
.template-names-list:hover .edit-icon-wrapper {
  visibility: visible;
  margin-left: 100px;
}
.add-task-dial {
  width: 99%;
  height: 94%;
  right: 1px;
  left: -0.1%;
}
.task-add-header {
  position: relative;
  margin-top: -15px;
  margin-bottom: 0px;
  font-weight: 500;
  font-size: 16px;
  padding: 1px;
  background-color: #f5f5f5;
  width: 615px;
  margin-left: -2.5%;
  padding: 4px;
  z-index: 1;
}
.edit-task-template-dial {
  background-color: white;
  padding: 15px;
  margin-right: -8px;
  margin-left: 68%;
  margin-top: -0.9%;
  height: 98%;
}
.edit-task-header {
  position: relative;
  margin-top: -15px;
  margin-bottom: 0px;
  font-weight: 500;
  font-size: 16px;
  padding: 1px;
  background-color: #f5f5f5;
  width: 615px;
  margin-left: -2.5%;
  padding: 4px;
  z-index: 1;
}
.task-btn-container {
  border-top: 0.5px solid;
  margin-top: 730px;
  margin-left: -15px;
  width: 105%;
}
.add-template {
  position: fixed;
  left: 928px;
  top: 0px;
  color: white;
  height: 29px;
  background-color: #6200ee;
  border: none;
  border-radius: 3px;
  font-weight: 600;
  left: 1731px;
  top: 82px;
  cursor: pointer;
  font-family: "Lato", sans-serif;
  font-size: 13px;
}
.add-templates {
  position: fixed;
  top: 0px;
  color: white;
  height: 29px;
  background-color: #6200ee;
  border: none;
  border-radius: 3px;
  font-weight: 600;
  left: 1731px;
  top: 82px;
  cursor: pointer;
  font-family: "Lato", sans-serif;
  font-size: 13px;
}
.delete-template {
  position: fixed;
  left: 1585px;
  background-color: red;
  height: 31px;
  border: none;
  border-radius: 3px;
  color: white;
  font-weight: 600;
  top: 81px;
  cursor: pointer;
  font-family: "Lato", sans-serif;
  font-size: 13px;
}
.delete-task {
  position: fixed;
  left: 1685px;
  background-color: red;
  cursor: pointer;
  font-family: "Lato", sans-serif;
  font-size: 13px;
}
.task-btn-containers {
  display: flex;
  position: relative;
  top: -48px;
  background-color: transparent;
}
.checkbox-task {
  margin-right: 30px;
  margin-top: 1px;
  cursor: pointer;
}
.role-btn {
  position: fixed;
  left: 1746px;
  top: 75px;
}
.reset-btn {
  background-color: #6200ee;
  border: none;
  border-radius: 3px;
  padding: 5px 10px;
  color: white;
  font-weight: 550;
  font-family: "Lato", sans-serif;
  font-size: 13px;
  cursor: pointer;
}
.user-initial {
  background-color: rgb(108, 160, 220);
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  font-size: 17px;
  text-align: center;
  margin-top: 0px;
  font-weight: 600;
}
.user-dp {
  background-color: rgb(108, 160, 220);
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  font-size: 17px;
  text-align: center;
  margin-top: 0px;
  font-weight: 800;
}
.users-name {
  margin-left: 12px;
  margin-top: 10px;
}
.add-module-btn {
  height: auto;
  width: auto;
  padding: 6px 10px;
  text-align: center;
  border: none;
  color: white;
  border-radius: 3px;
  background-color: #6200ee;
  margin-left: 22px;
  margin-top: 15px;
  font-weight: 750;
  cursor: pointer;
  position: fixed;
  left: 1637px;
  top: 75px;
  font-family: "Lato", sans-serif;
  font-size: 13px;
}
.role-permission-mapping-container {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  height: 700px;
}
.template-names-list {
  display: flex;
  justify-content: space-between;
  width: 870px;
  margin-bottom: 10px;
}
.template-task-name {
  text-align: left;
  margin-right: auto;
  cursor: pointer;
}
.task-mapping-select {
  border: 0.5px solid;
  border-radius: 3px;
  font-family: "Lato", sans-serif;
  font-size: 15px;
  cursor: pointer;
}
.sno-etasks {
  border: 0.5px solid;
  width: 20px;
  border-radius: 3px;
  text-align: center;
  margin-left: 45px;
  font-family: "Lato", sans-serif;
  font-size: 13px;
}
.eastimate-task-container {
  width: 350px;
  height: 783px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding-left: 15px;
}
@media (min-width: 1440px) and (max-width: 1919px) {
  .settings-content-table {
    top: 7%;
  }
  .close-icon {
    left: 1455px;
  }
  .member-select-option .dropdown-container {
    width: 60.05%;
  }
  .add-user-settings-btn {
    left: 1375px;
  }
  .email-settings-form {
    height: 550px;
  }
  .user-pagination {
    margin-left: 8px;
    top: 668px;
  }
  .user-pagination-close {
    right: 680px;
    margin-top: 548px;
    width: 260px;
    position: relative;
    margin-left: 1770px;
    display: flex;
    height: 40px;
    background-color: transparent;
  }
  .new-task-btn {
    left: 92%;
  }
  .delete-task {
    left: 85.7%;
  }
  .add-template {
    left: 88.5%;
  }
  .add-templates {
    left: 1359px;
  }
  .delete-template {
    left: 79%;
    top: 83px;
    height: 28px;
  }
  .estimate-task-container {
    width: 280px;
  }
  .task-templates-container {
    top: 0px;
  }
  .task-container {
    height: 560px;
  }
  .add-task-dial {
    width: 100.6%;
    height: 91%;
    right: 1px;
  }
  .task-btn-container {
    border-top: 0.5px solid;
    margin-top: 511px;
    width: 106%;
    margin-left: -13px;
  }
  .add-group-dial {
    margin-left: -60px;
  }
  .role-btn {
    left: 1390px;
  }
  .add-project-content {
    width: 32%;
    padding-bottom: 10px;
    height: 99.61%;
    overflow-y: auto;
  }
  .add-role-header {
    margin-left: -3%;
  }
  .add-module-btn {
    left: 1281px;
  }
  .role-permission-mapping-container {
    height: 507px;
  }
  .settings-content-table thead th {
    min-width: 65px;
  }
  .user-settings-tab-details {
    width: 83.6%;
  }
  .user-settings-tab-details-close {
    width: 88.4%;
  }
  .settings-content-table-close {
    top: -61.4%;
  }
  .estimate-template-names {
    width: 250px;
  }
  .task-mapping-select {
    font-size: 13px;
  }
  .edit-task-template-dial {
    margin-top: -1%;
  }
  .add-task-template-dial {
    margin-top: -1%;
  }
  .add-role-settings {
    margin-top: -1%;
  }
  .email-input {
    height: 21px;
  }
  .eastimate-task-container {
    height: 570px;
  }
  .est-settings-template-list {
    height: 536px;
    overflow-x: auto;
    margin-top: 40px;
  }
  .task-add-header {
    margin-left: -3%;
  }
  .add-project-user-heading {
    font-size: 15px;
  }
}
