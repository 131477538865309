.user-table-container {
  position: relative;
  top: 210px;
  margin: 0px;
}
.table-icon {
  margin-right: 5px;
  margin-left: 5px;
  margin-top: 0px;
  height: 16px;
  width: 16px;
}
.user-select-dropdown {
  padding: 10px;
  left: -120px;
  border: none;
  position: relative;
  color: #6200ee;
  font-weight: 100;
  font-size: 16px;
  background-color: transparent;
}
/* .loading {
    color: rgb(95, 160, 95);
  } */
.user-dropdown-container {
  position: fixed;
  top: 200px;
}
.add-user-container {
  position: fixed;
  display: flex;
  justify-content: flex-end;
  margin-right: 55px;
  top: 249px;
  left: 1100px;
}
.user-dropdown-add-user-container {
  background-color: #616161;
  z-index: 1;
}
.add-user-heading {
  font-weight: 100;
  position: relative;
  top: -45px;
  cursor: pointer;
  padding: 10px;
  text-align: center;
  border: none;
  background-color: #6200ee;
  color: white;
  border-radius: 7px;
  margin-right: 30px;
  margin-left: 40px;
}
.add-layout-user-dialog {
  width: 500px;
  position: fixed;
  top: 200px;
  left: 160px;
  overflow-x: hidden;
  /* background-color: white; */
  margin-left: 830px;
  display: flex;
  padding: 15px;
  flex-direction: column;
  border: 1px solid grey;
  border-radius: 7px;
}
.add-new-user-heading {
  margin-top: 0px;
  margin-bottom: 10px;
  background-color: transparent;
  margin-left: 10px;
}
.user-element {
  margin-bottom: 05px;
  font-weight: 400;
  border: 0.5px solid black;
  height: 30px;
  width: 555px;
  padding-left: 5px;
  border-radius: 3px;
  font-family: "Lato", sans-serif;
  font-size: 15px;
}
.user-element-password {
  margin-bottom: 05px;
  font-weight: 400;
  width: 558px;
  height: 30px;
  background-color: white;
  border: 0.5px solid black;
  margin-top: 26px;
  margin-left: -75px;
  border-radius: 3px;
  font-family: "Lato", sans-serif;
  font-size: 15px;
}
.btn-label {
  margin-top: 05px;
  background-color: white;
  align-items: center;
  margin-bottom: 10px;
}

.btn-contaier {
  display: flex;
  flex-direction: row;
}
.add-user-dialog-header {
  position: relative;
}
.dial-box-seperator {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 396px;
}
.add-new-user-btn {
  border: none;
  color: #6200ee;
  background-color: transparent;
  margin-top: 16px;
  margin-left: 270px;
  border-radius: 7px;
  height: 30px;
  border: 1px solid #6200ee;
}
@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
.add-layout-new-user-container {
  position: fixed;
  top: 49px;
  right: 1px;
  left: 0%;
  width: 99%;
  height: 100%;
  display: flex;
  padding: 15px;
  flex-direction: column;
  border: 1px solid grey;
  border-radius: 3px;
  animation: slideIn 0.5s forwards;
  background-color: rgba(0, 0, 0, 0.4);
}
.add-user-content {
  height: 897px;
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  margin-left: 68%;
  width: 610px;
}
.add-user-content::-webkit-scrollbar {
  display: none;
}
.add-header {
  position: relative;
  margin-top: -15px;
  margin-bottom: 0px;
  font-weight: 500;
  font-size: 16px;
  padding: 1px;
  background-color: #f5f5f5;
  width: 615px;
  margin-left: 1293px;
  padding: 4px;
  z-index: 1;
}
.add-new-user-button-a-container {
  display: flex;
  position: relative;
  top: -57px;
  left: 1293px;
  border-top: 0.5px solid;
  width: 108%;
  padding-bottom: 20px;
}
.add-user-gender {
  margin-bottom: 05px;
  font-weight: 400;
  border: 0.5px solid black;
  height: 30px;
  width: 566px;
  padding-left: 5px;
  border-radius: 3px;
  font-family: "Lato", sans-serif;
  font-size: 15px;
}
.existing-user-table {
  display: flex;
  position: relative;
  top: 54px;
  left: -250px;
}
.existing-user-table-details yh {
  display: flex;
  flex-direction: row;
  margin-right: 5px;
}
.existing-user-btn-container {
  position: fixed;
  top: 580px;
  right: 301px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.user-table-container.open,
.add-user-dialog.open,
.add-new-user-container.open {
  display: none; /* when dialBoxOpen is true */
}
.filter-container {
  position: fixed;
  top: 205px;
  left: 400px;
}
.show-password {
  font-size: 18px;
  margin-top: 8px;
  cursor: pointer;
  margin-left: 25px;
  background-color: white;
}
.password {
  font-size: 18px;
  margin-top: 8px;
  cursor: pointer;
  margin-left: 25px;
  background-color: white;
  display: block;
}
.password-input {
  display: flex;
  /* border: 1px solid; */
  /* padding: 5px; */
  /* background-color: white; */
  width: 600px;
  height: auto;
}

.password-icon {
  /* margin-left: 10px; */
  cursor: pointer;
  background-color: transparent;
  /* margin-top: 34px; */
  top: 3px;
  z-index: 3;
  position: relative;
  right: 34px;
}
.adduser-password-icon {
  /* margin-left: 10px; */
  cursor: pointer;
  background-color: transparent;
  /* margin-top: 34px; */
  top: 33px;
  z-index: 3;
  position: relative;
  right: 34px;
}
.add-user-label-header-element {
  margin-bottom: 05px;
  font-weight: 400;
  margin-top: 3px;
  font-size: 18px;
}
@media (min-width: 1440px) and (max-width: 1919px) {
  .add-layout-new-user-container {
    width: 102%;
    height: 100%;
    left: -20px;
  }
  .add-header {
    width: 29.8%;
    left: -228px;
  }
  .user-element {
    width: 433px;
  }
  .user-element-password {
    width: 433px;
  }
  .add-user-content {
    height: 90%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }
  .add-new-user-button-a-container {
    top: -52px;
    width: 106%;
    left: 1065px;
  }
  .add-user-gender {
    width: 441px;
  }
  .password-icon {
    top: 3px;
  }
  .adduser-password-icon {
    top: 33px;
  }
}
