.user-p-details-container {
  padding: 10px 5px;
  max-width: 95%;
  position: relative;
  top: 40px;
  left: 74px;
}
.user-details-element {
  margin-bottom: 05px;
  font-weight: 400;
  border: 0.5px solid black;
  height: 20px;
  width: 288px;
  padding: 5px;
  border-radius: 3px;
  font-family: "Lato", sans-serif;
  font-size: 15px;
}
.gender-dropdown {
  cursor: pointer;
  height: 30px;
  width: 299px;
  border-radius: 3px;
  padding: 4px;
  font-family: "Lato", sans-serif;
  font-size: 15px;
}
.user-details-exit-arrow {
  color: blue;
  height: 20px;
  width: 20px;
  position: fixed;
  top: 76px;
  left: 1885px;
}
.user-p-details-container-close {
  padding: 10px 5px;
  max-width: 98.1%;
  position: relative;
  top: 40px;
  left: 14px;
}
.Upload-Profile-Picture-dial {
  position: fixed;
  bottom: 0%;
  top: 5.1%;
  left: -53.3%;
  width: 101%;
  height: 95%;
  margin-left: 53%;
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  border-radius: 7px;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
  border-radius: 3px;
  overflow: auto;
  margin-right: 15px;
  border-radius: 3px;
  animation: slideIn 0.5s forwards;
}
.Upload-Profile-Picture {
  position: fixed;
  bottom: 0%;
  top: 0.2%;
  left: 26.2%;
  width: 20%;
  height: 37%;
  margin-left: 53%;
  border-radius: 3px;
}
.Upload-Profile-header {
  background-color: #f5f5f5;
  margin-top: -20px;
  width: 100%;
  border-radius: 3px;
  height: 28px;
}
@media (min-width: 1440px) and (max-width: 1919px) {
  .user-details-exit-arrow {
    left: 1485px;
  }
  .Upload-Profile-Picture {
    top: 1.9%;
    left: 23.2%;
    width: 23%;
    height: 50%;
  }
}
