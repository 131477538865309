@keyframes slideOut {
  from {
    transform: translateX(0);
    opacity: 0;
  }
  to {
    transform: translateX(100%);
    opacity: 1;
  }
}
.dashboard-main-container {
  position: fixed;
  left: 72px;
  width: 100vw;
  top: 138px;
  overflow: auto;
  height: 100vh;
}
.dashboard-main-container-close::-webkit-scrollbar {
  display: none;
}
.dashboard-main-container-close {
  position: fixed;
  left: 2px;
  width: 100vw;
  top: 138px;
  overflow: auto;
  height: 100vh;
}
.dashboard-container {
  box-sizing: border-box;
  display: flex;
  height: 500px;
  width: 95.5vw;
  position: relative;
  left: 19px;
  top: 8px;
  margin-bottom: 30px;
}
.dashboard-container-close {
  box-sizing: border-box;
  display: flex;
  height: 500px;
  width: 99.1vw;
  position: relative;
  left: 20px;
  top: 8px;
  margin-bottom: 30px;
}
.container-one {
  box-sizing: border-box;
  display: flex;
  flex-grow: 1;
  width: 100%;
  border: 0.5px solid #ddd;
  margin-right: 28px;
  padding: 29px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  animation: slideIn 1s forwards;
}
.container-two {
  box-sizing: border-box;
  display: flex;
  flex-grow: 1;
  width: 100%;
  border: 0.5px solid #ddd;
  margin-right: 28px;
  padding: 19px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  animation: slideIn 1s forwards;
}
.dashboard-image-extra {
  position: relative;
  left: 160px;
}
.user-details-list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 450px;
  margin-bottom: 0px;
  height: 35px;
}
.piechart {
  height: 350px;
  width: 350px;
  margin-left: 200px;
  background-color: transparent;
  box-sizing: border-box;
  display: block;
}
.ul-dashboard {
  margin-top: 50px;
  height: 397px;
  overflow-y: auto;
  margin-left: -40px;
  width: 660px;
}
.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr); /* 7 days in a week */
  gap: 10px;
}
.calendar-day {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: center;
  height: 34px;
  width: 86px;
}
.calendar-day div {
  /* margin-top: 5px; */
}
.calendar-controls {
  display: flex;
  justify-content: space-between;
  margin-top: -28px;
}
.calendar-controls-duration {
  display: flex;
  justify-content: space-between;
  margin-top: -28px;
}
.duration-image {
  height: 300px;
  width: 300px;
  position: relative;
  /* left: 200px; */
  width: fit-content;
}
.billable-image {
  height: 300px;
  width: 300px;
  position: relative;
  /* left: 200px; */
  top: 32px;
  background-color: transparent;
  margin-bottom: 35px;
  width: fit-content;
}
.members-image {
  height: 300px;
  width: 300px;
  position: relative;
  left: -67px;
  top: 50px;
  background-color: transparent;
  margin-bottom: 55px;
  width: fit-content;
}
.duration-image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 142%;
  background-color: transparent;
}
.last-container {
  margin-bottom: 10%;
}
.last-container-close {
  margin-bottom: 10%;
}
.last-h-container {
  margin-bottom: 5%;
}
.last-h-container-close {
  margin-bottom: 5%;
}
@media (min-width: 1440px) and (max-width: 1919px) {
  .last-container {
    margin-bottom: 5%;
  }
  .last-container-close {
    margin-bottom: 5%;
  }
  .dashboard-main-container {
    height: 611px;
    left: 0px;
  }
  .dashboard-main-container-close {
    height: 611px;
    left: 0px;
  }
  .dashboard-main-container::-webkit-scrollbar {
    display: none;
  }
  .dashboard-main-container-close::-webkit-scrollbar {
    display: none;
  }
  .dashboard-container {
    left: 90px;
    width: 94.5vw;
  }
  .piechart {
    margin-left: 124px;
  }
  .calendar-day {
    width: 61px;
  }
  .members-image {
    top: 85px;
    left: -44px;
  }
  .ul-dashboard {
    margin-left: -140px;
  }
}
