.avatar-icon2 {
  text-decoration: none;
  margin-left: 5px;
  width: 32px;
  margin-top: -34px;
  border-radius: var(--br-181xl);
  height: 30px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  object-fit: cover;
  border: 0.5px solid #ddd;
}
.profile-container {
  width: 20%;
  height: 16%;
  background-color: white;
  top: 0.99%;
  position: relative;
  left: 79.4%;
  border-radius: 3px;
}
.supporting-text1,
.text24 {
  position: relative;
  line-height: 24px;
}
.text24 {
  font-weight: 600;
}
.supporting-text1 {
  color: var(--gray-2);
}
.avatar-label-group,
.text-and-supporting-text1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.text24,
.supporting-text1,
.user-role,
.user-designation,
.avatar-label-group,
.text-and-supporting-text1,
.profile,
.close-ring-light-icon,
.sign-out-squre-light-parent,
.sign-out,
.sign-out-squre-light-icon,
.mobile-parent,
.android-icon1,
.android-icon2 {
  background-color: white;
  border-radius: 12;
}
.avatar-label-group {
  position: absolute;
  top: 20px;
  left: 20px;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  font-size: var(--body-text-body-text-6-size);
  font-family: var(--text-sm-semibold);
}
.user-role {
  position: absolute;
  top: 81px;
  left: 78px;
}
.user-designation {
  position: absolute;
  top: 55px;
  left: 78px;
}
.sign-out-squre-light-icon {
  width: 24px;
  position: relative;
  height: 24px;
  object-fit: contain;
  cursor: pointer;
}
.sign-out {
  position: relative;
  cursor: pointer;
  margin-right: 10px;
}
.sign-out-squre-light-parent {
  position: absolute;
  top: 114px;
  left: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  color: var(--primary-color);
}
.android-icon1 {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
  margin-right: 10px;
}
.mobile-parent {
  position: absolute;
  top: 135px;
  left: calc(50% - 159px);
  border-radius: 5px;
  background-color: var(--white);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-8xs) 208px var(--padding-8xs) var(--padding-3xs);
  gap: var(--gap-5xs);
}
.close-ring-light-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}
@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
.profile {
  width: 101%;
  border: 1px solid #a3a3a3;
  position: fixed;
  top: -0.9%;
  border-radius: 8px;
  left: -0.6%;
  z-index: 101;
  height: 101%;
  overflow: hidden;
  margin-right: 80px;
  font-size: var(--body-text-body-text-5-size);
  color: var(--black);
  font-family: var(--body-text-body-text-7);
  animation: slideIn 0.5s forwards;
  background-color: rgba(0, 0, 0, 0.7);
}

.change-password {
  margin-left: 165px;
  background-color: white;
  cursor: pointer;
  margin-top: -19px;
}
.change-password-dial {
  position: fixed;
  bottom: 0%;
  top: 16.7%;
  left: 26.7%;
  width: 20%;
  height: 36%;
  margin-left: 53%;
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  border-radius: 7px;
  background-color: white;
  z-index: 200;
  border-radius: 3px;
  overflow: auto;
  margin-right: 15px;
  border-radius: 3px;
  animation: slideIn 0.5s forwards;
}
.change-password-dial::-webkit-scrollbar {
  display: none;
}
.change-password-header {
  background-color: #f5f5f5;
  margin-top: -15px;
  width: 100%;
}
.password-element {
  margin-top: 15px;
  margin-bottom: -15px;
  background-color: transparent;
  padding: 0px;
  font-weight: 400;
  margin-left: 10px;
}
.password-element-input {
  margin-top: 20px;
  background-color: transparent;
  padding-left: 5px;
  margin-left: 10px;
  border: 0.5px solid;
  border-radius: 3px;
  width: 349px;
}
.password-header {
  margin-top: 20px;
  background-color: transparent;
  padding: 0px;
  margin-left: 10px;
  font-weight: 400;
}

.password-btn-container {
  background-color: white;
  position: relative;
  top: -1%;
  margin-top: 49px;
  border-top: 0.5px solid;
}
.form-input {
  background-color: white;
}
.password-icon {
  background-color: white;
}
.password-message {
  color: red;
  font-size: 10px;
}
/* .picture-dial {
  position: relative;
  left: 140px;
} */
.profilepicture {
  margin-top: -42px;
  background-color: transparent;
  cursor: pointer;
}
.pictureDial {
  position: fixed;
  bottom: 0%;
  top: 25%;
  left: 27.4%;
  width: 45%; /* Adjusted width for better fit */
  height: 60%; /* Adjusted height for better content fitting */
  margin-left: 48%;
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  border-radius: 7px;
  background-color: aquamarine;
  z-index: 4;
  border-radius: 3px;
  overflow: auto;
  margin-right: 15px;
  animation: slideIn 1s forwards;
}

/* Upload picture form starts */
.picture-dial {
  background: white;
  border: 1px solid black;
  padding: 20px;
  border-radius: 3px;
  width: 13%;
  text-align: center;
  position: relative;
  top: 156px;
  left: 1541px;
  z-index: 1;
}

.picture-dial-header {
  font-size: 20px;
  margin-bottom: 20px;
}

/* Form Container for Upload */
.picture-form {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  gap: 20px;
  width: 100%;
}

/* Label */
.picture-label {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
  text-align: center;
}

/* Input Styling */
.picture-input {
  margin-bottom: 15px;
  width: 100%;
  max-width: 350px; /* Restricting the width of inputs */
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
}

/* Preview Picture Styling */
.preview-picture {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #ddd;
  position: relative;
  left: 100px;
}

/* Button Container */
.picture-btn-container {
  display: flex;
  width: 100%;
  gap: 10px;
  margin-top: 1px;
  border-top: 0.5px solid black;
}

.picture-btn-container {
  display: flex;
  width: 100%;
  gap: 10px;
  margin-top: 145px;
  border-top: 0.5px solid black;
  background-color: transparent;
}
.picture-btn-container-2 {
  display: flex;
  width: 100%;
  gap: 10px;
  background-color: transparent;
  margin-top: 0px;
  border-top: 0.5px solid;
}
.upload-input {
  margin-top: 5px;
  background-color: transparent;
  padding-left: 5px;
  margin-left: 10px;
  border: 0.5px solid;
  border-radius: 3px;
  width: 320px;
  height: 30px;
  padding-top: 10px;
}
/* Upload picture form ends */

@media (min-width: 1440px) and (max-width: 1919px) {
  .profile {
    left: -0.6%;
  }
  .profile-container {
    width: 25%;
    height: 20%;
    top: 0.99%;
    position: relative;
    left: 75.4%;
  }
  .change-password-dial {
    position: fixed;
    bottom: 0%;
    top: 20.9%;
    left: 22.7%;
    width: 24.3%;
    height: 47%;
    margin-left: 53%;
    display: flex;
    flex-direction: column;
    border: 1px solid #ddd;
    border-radius: 7px;
    background-color: white;
    border-radius: 3px;
    overflow: auto;
    margin-right: 15px;
  }
  .password-element-input {
    width: 337px;
    margin-top: 19px;
  }
}
