* {
  background-color: white;
}
@keyframes slideOut {
  from {
    transform: translateX(0);
    opacity: 0;
  }
  to {
    transform: translateX(100%);
    opacity: 1;
  }
}

@keyframes color-change {
  /* 0% {
    color: #ff0000;
  } */
  25% {
    color: #ed1b26;
  }
  50% {
    color: #02217c;
  }
  75% {
    color: #fab613;
  }
  100% {
    color: #010101;
  }
}

.loading-animation {
  font-size: 24px;
  font-weight: bold;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: color-change 3s infinite;
}
/* @keyframes gradientText {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.loading-animation {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  color: transparent;
  background-image: linear-gradient(90deg, #ff6ec4, #7873f5, #ff9e48);
  background-clip: text;
  -webkit-background-clip: text;
  animation: gradientText 5s ease infinite;
} */
.left-side-navbar {
  position: fixed;
  height: 100vh;
  width: 72px;
  top: 0px;
  left: -75px;
  bottom: 0;
  background-color: #333333;
  overflow: hidden;
  z-index: 100;
  animation: slideOut 1s forwards;
}
.project-image {
  top: 55px;
  height: 56px;
  object-fit: cover;
  position: absolute;
  left: -35px;
  background-color: #333333;
  width: auto;
  -webkit-user-select: none;
  user-select: none;
  animation: slideOut 1s forwards;
}
.analytics-image {
  top: 125px;
  height: 65px;
  object-fit: cover;
  position: absolute;
  left: -4px;
  background-color: #333333;
  width: 80px;
  user-select: none;
}
.home-layout {
  width: 100vw;
  position: fixed;
  margin-bottom: 0px;
  z-index: 99;
  padding-bottom: 17px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: white;
  height: 32px;
  top: 0;
}
.left-side-layout-group {
  position: fixed;
  top: -2px;
  left: 18%;
  width: 724px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  text-align: center;
  background-color: inherit;
}
.tabs-parent {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
  position: fixed;
  top: 5px;
  left: 95px;
}
.select-project {
  color: #010101;
  font-weight: 400;
  font-style: normal;
  margin-right: 16px;
  text-decoration: none;
  user-select: none;
}
/* .select-project.active {
  color: #6200ee;
  font-weight: 600;
} */
.navbar-select-project {
  color: black;
  font-weight: 400;
  text-decoration: none;
  position: relative;
  margin-right: 16px;
  transition: color 0.3s ease-in-out;
}

/* Default underline for all items (hidden initially) */
.navbar-select-project::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -8px;
  width: 100%;
  height: 2px; /* Adjust thickness */
  background: linear-gradient(
    to right,
    rgba(98, 0, 238, 0) 0%,
    rgba(98, 0, 238, 1) 50%,
    rgba(98, 0, 238, 0) 100%
  );
  transform: translateX(-50%) scaleX(0);
  transition: transform 0.3s ease-in-out;
}

/* Hover effect - underline appears */
.navbar-select-project:hover::after {
  transform: translateX(-50%) scaleX(1);
  font-weight: 400;
}
.navbar-select-project:hover {
  font-weight: 400;
}
/* Active state (keeps hover effect on active page) */
.navbar-select-project.active {
  font-weight: 600;
  color: #6200ee; /* Keeps the text color */
}

/* Keep the underline always visible for active */
.navbar-select-project.active::after {
  transform: translateX(-50%) scaleX(1);
  font-weight: 600;
}

/* Hover effect on active item - slightly expand */
.navbar-select-project.active:hover::after {
  transform: translateX(-50%) scaleX(1.2);
  font-weight: 400;
}
.select-project-parent {
  align-items: flex-start;
  padding: 0 3px;
  gap: 3px;
  text-align: left;
  margin-top: 5px;
}
.project-search-input {
  box-sizing: border-box;
  border: 0.5px solid #010101;
  height: 30px;
  font-weight: 400;
  width: 470px;
  box-sizing: border-box;
  padding: 10px;
  border-radius: 3px;
  font-family: "Lato", sans-serif;
  font-size: 15px;
}
.project-search-input.active {
  color: #000000;
  font-weight: 400;
}
.project-search-input:hover {
  width: 470px;
  box-sizing: border-box;
  border: 0.5px solid black;
}
.project-dropdown {
  position: fixed;
  list-style-type: none;
  margin: 0;
  width: 470px;
  background-color: white;
  border-radius: 3px;
  z-index: 15;
  border-top: none;
  min-height: auto;
  max-height: 188px;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.3);
}
.project-dropdown::-webkit-scrollbar {
  display: none;
}
.project-dropdown-item {
  background-color: white;
  padding: 10px;
}
.project-dropdown-item:hover {
  background-color: #f0f0f0;
  color: rgb(0, 0, 0);
  width: auto;
  cursor: pointer;
}
.right-side-parent {
  position: fixed;
  top: 5px;
  right: -46px;
  width: 265px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  background-color: white;
  box-sizing: border-box;
}
.plus-icon {
  cursor: pointer;
  height: 30px;
  margin-right: -55px;
  width: 25px;
  margin-top: 3px;
  margin-right: -71px;
}
.search-icon {
  width: 24px;
  position: relative;
  height: 24px;
  object-fit: cover;
  margin-right: -74px;
  margin-left: 5px;
  margin-top: 2px;
}
.settings-icon {
  width: 35px;
  color: black;
  position: relative;
  height: 24px;
  object-fit: cover;
  margin-right: -51px;
  margin-left: 5px;
  margin-top: 4px;
}

.notification-icon {
  width: 23px;
  color: black;
  position: relative;
  height: 21px;
  object-fit: cover;
  margin-right: -54px;
  margin-left: -26px;
  cursor: pointer;
}
.settings-icon.active,
.notification-icon.active {
  color: #6200ee;
}
.avatar-first-letter {
  margin-right: -55px;
  cursor: pointer;
}
.last-access-date {
  font-size: 11px;
  margin-top: 2%;
}
.welcome-sivabalan {
  margin: 0;
  position: relative;
  font-size: inherit;
  font-weight: 600;
  font-family: inherit;
}
.horizontal-line {
  position: fixed;
  top: 40px;
  z-index: -1;
  border-top: 1px solid;
  width: 100%;
}
.add-dial-box-container {
  position: fixed;
  top: 50px;
  height: auto;
  right: 24px;
  border-radius: 3px;
  z-index: 15;
  width: auto;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.3);
}
.add-icon-plus {
  background-color: rgba(255, 255, 255, 0);
  margin-top: 5px;
}
.add-user-layout {
  margin-top: -1px;
  margin-left: 4px;
  background-color: rgba(255, 255, 255, 0);
  border: none;
  padding-bottom: 5px;
  padding-top: 5px;
  margin-right: 40px;
}
.add-user-dial-container {
  position: fixed;
  top: 150px;
  z-index: 6;
}
.add-container {
  display: flex;
  flex-direction: row;
  background-color: white;
  padding: 6px;
  padding-left: 15px;
}
.add-timesheet-btn {
  border: none;
}
h3 {
  font-size: 16px;
}
h1 {
  font-size: 16px;
}
a {
  font-size: 16px;
}
a:hover {
  font-weight: 600;
  color: #6200ee;
}
textarea {
  font-family: "Lato";
}
.arrow-nav-icon {
  position: fixed;
  left: 19px;
  top: 9px;
  z-index: 100;
  height: 21px;
  color: black;
  width: 60px;
  z-index: 101;
  background-color: transparent;
  animation: slideIn 1s forwards;
}
.slide-icon {
  animation: slideOut 1s forwards;
  left: -45px;
  cursor: pointer;
}
.icon {
  height: 30px;
  width: 30px;
  color: white;
  z-index: 101;
  background-color: transparent;
}
.add-container:hover {
  background-color: #f0f0f0; /* Light background on hover */
  /*transform: scale(1.05); /* Slight scale effect to make it pop */
}
.project-table-con {
  margin-top: 2px;
  display: flex;
  justify-content: space-between;
}
.existing-hrs {
  position: absolute;
  top: -1px;
  color: red;
  font-size: 12px;
  background-color: transparent;
  left: 18px;
  font-weight: 600;
}
