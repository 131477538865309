.main-container {
  position: fixed;
  top: 142px;
  left: 67px;
  height: 764px;
  width: 99.8%;
  overflow-y: auto;
  overflow-x: auto;
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
  /* box-sizing: border-box; */
  scrollbar-gutter: stable both-edges;
}
.main-container-close {
  position: fixed;
  top: 142px;
  left: 12px;
  height: 765px;
  width: 99.8%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}
.main-container::-webkit-scrollbar {
  display: inline;
}
.main-container-close::-webkit-scrollbar {
  display: inherit;
}
.hide {
  display: none;
}
.filter-label {
  margin-right: 20px;
}
.filter-u-label {
  margin-right: 20px;
}
.filter-categories {
  position: relative;
  left: 1px;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.select-group-by {
  margin-left: 3px;
  height: 38px;
  width: 140px;
  margin-bottom: 11px;
  border: none;
  color: #6200ee;
  padding: 5px;
  font-weight: 600;
  -webkit-appearance: none;
  outline: none;
  font-family: "Lato", sans-serif;
  font-size: 15px;
  cursor: pointer;
}
.user-name {
  margin-bottom: 3px;
}
.from {
  margin-bottom: 3px;
}
.to {
  margin-bottom: 3px;
}
.bill {
  margin-bottom: 3px;
  font-size: 18px !important;
}
.overall-summary {
  position: fixed;
  display: flex;
  top: 96%;
  left: 74px;
  border-top: 1px solid #ddd;
  height: 63px;
  padding: 0px;
  width: 100%;
  background-color: white;
  z-index: 5;
}
.overall-summary-close {
  position: fixed;
  display: flex;
  top: 96%;
  left: 6px;
  border-top: 1px solid #ddd;
  height: 63px;
  padding: 0px;
  width: 100%;
  background-color: white;
  z-index: 5;
}
.over-all-summary-header {
  font-weight: 600;
  font-size: 15px;
  margin-right: 25px;
  margin-left: 11px;
}
.billable {
  color: #3acce6;
  margin-right: 30px;
  background-color: transparent;
  font-weight: 700;
}
.non-billable {
  color: #faad51;
  margin-right: 30px;
  background-color: transparent;
  font-weight: 700;
}
.approved {
  color: #40af48;
  margin-right: 30px;
  background-color: transparent;
  font-weight: 700;
}
.un-approved {
  color: #78909c;
  margin-right: 30px;
  background-color: transparent;
  font-weight: 700;
}
.table-container-timesheet {
  margin-left: 7px;
  overflow-x: scroll;
  overflow-y: scroll;
  max-height: 500px;
  min-height: 0%;
  width: 96%;
  margin-top: 8px;
  border: 0.5px solid #ddd;
}
.single-phase {
  position: fixed;
  margin-left: 7px;
  /* overflow-x: scroll; */
  overflow-y: scroll;
  height: 690px;
  width: 95%;
  margin-top: 8px;
  border: 0.5px solid #ddd;
}
.single-phase::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
.single-phase::-webkit-scrollbar-thumb {
  background-color: #ddd; /* Scrollbar thumb (the part you can drag) color */
  border-radius: 6px; /* Rounded corners */
  border: 3px solid transparent; /* Space around the thumb */
}
.single-phase::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Thumb color when hovered */
}

.single-phase::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Scrollbar track (the background) */
  border-radius: 6px; /* Rounded corners for track */
}

.table-container-timesheet::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
.table-container-timesheet::-webkit-scrollbar-thumb {
  background-color: #ddd; /* Scrollbar thumb (the part you can drag) color */
  border-radius: 6px; /* Rounded corners */
  border: 3px solid transparent; /* Space around the thumb */
}
.table-container-timesheet::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Thumb color when hovered */
}

.table-container-timesheet::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Scrollbar track (the background) */
  border-radius: 6px; /* Rounded corners for track */
}

.timesheet-phase-header {
  color: black;
  font-size: 18px;
  font-weight: 400;
  font-size: 17px;
  margin-top: 4px;
  margin-left: 15px;
  margin-bottom: 53px;
}
.timesheet-phase-details {
  color: black;
  margin-left: 0px;
  font-weight: 600;
}
.filter-by {
  color: black;
  margin-right: 30px;
  font-size: 16px;
  font-weight: 550;
  background-color: transparent;
  position: relative;
}
.category-details {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  margin-left: 13px;
  height: 38px;
  margin-top: -11px;
  margin-bottom: 3px;
}
.table-main-container {
  max-height: 600px;
  width: 95%;
  overflow-y: auto;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-top: 20px;
  overflow-y: auto;
  overflow-x: auto;
}
.table-parent {
  padding: 5px;
  margin-right: 10px;
  padding-bottom: 0px;
  position: relative;
  top: -85px;
  background-color: transparent;
}

.timesheet-phase-table {
  border-collapse: collapse;
  position: relative;
  top: 4px;
  left: -50px;
  margin-left: 58px;
  border-radius: 8px;
  text-wrap: nowrap;
  width: 100%;
  border-radius: 1px solid #ddd;
  margin-top: 0;
  margin-bottom: 50px;
}
.timesheet-phase-table thead th {
  background-color: white;
  color: black;
  text-wrap: nowrap;
  text-align: center;
  padding: 0px;
  position: relative;
  border: 1px solid #ddd;
  min-width: 250px;
  max-width: 250px;
  text-align: left;
}
.timesheet-phase-table tbody tr {
  text-align: left;
  min-width: 200px;
  max-width: 250px;
  border: 1px solid #ddd;
  background-color: white;
}
.timesheet-phase-table td {
  font-weight: 500;
}
.group-summary-each-table {
  padding: 0px;
  display: flex;
  position: relative;
}
.table-icons {
  margin-right: 0px;
  display: flex;
  margin-top: 15px;
}
.table-header {
  margin-top: -4px;
}
.category-summary {
  font-weight: 400;
  font-size: 16px;
  margin-right: 30px;
  margin-left: 10px;
  background-color: transparent;
}
.phase-summary {
  display: flex;
  position: sticky;
  left: 0%;
  height: 35px;
  top: 459px;
  background-color: transparent;
  z-index: 1;
  background-color: white;
  margin-left: 3px;
}
.single-summary {
  display: flex;
  position: sticky;
  left: 0%;
  height: 35px;
  top: 662px;
  background-color: transparent;
  z-index: 1;
  background-color: white;
  margin-left: 3px;
}
@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
.timesheet-overlay-add-time-log {
  position: fixed;
  text-align: left;
  top: 3.9%;
  left: -1.1%;
  z-index: 2;
  transform: translate(-50%, -20%);
  padding-bottom: 0px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100vw;
  height: 100%;
  border-radius: 3px;
  animation: slideIn 0.5s forwards;
  z-index: 11;
  background-color: rgba(0, 0, 0, 0.7);
}

.add-btn {
  height: auto;
  width: auto;
  padding: 6px 10px;
  text-align: center;
  border: none;
  color: white;
  border-radius: 3px;
  background-color: #6200ee;
  margin-left: 22px;
  margin-top: 15px;
  font-weight: 750;
  cursor: pointer;
  font-family: "Lato", sans-serif;
  font-size: 13px;
}
.cancel-btn {
  height: auto;
  width: auto;
  padding: 5px 10px;
  text-align: center;
  border: none;
  color: #6200ee;
  border-radius: 3px;
  font-weight: 400;
  border: 1px solid #6200ee;
  background-color: transparent;
  margin-right: 30px;
  margin-left: 10px;
  margin-top: 15px;
  cursor: pointer;
  font-family: "Lato", sans-serif;
  font-size: 13px;
}
.new-time-log {
  font-weight: 400px;
  background-color: #f5f5f5;
  padding: 1px;
  width: 100%;
  margin-top: -0.5%;
  margin-left: 68.6%;
}
.new-time-log-dial {
  font-weight: 400px;
  background-color: #f5f5f5;
  padding: 1px;
  width: 100%;
  margin-top: -0.5%;
  margin-left: 68.6%;
  height: 40px;
}
.overview-filter-scrollable-t-content {
  width: 23%;
  padding-bottom: 10px;
  height: 88%;
  overflow-y: auto;
  margin-left: 77.6%;
  /* padding-left: 35px; */
  padding-top: 20px;
  margin-top: -1%;
}
.overview-filter-scrollable-t-content::-webkit-scrollbar {
  display: none;
}
.overview-filter-btn-container-add-timesheet {
  border-top: 1px solid #616161;
  padding-top: 0px;
  position: fixed;
  top: 93%;
  height: 46px;
  width: 99.9%;
  left: 1496px;
  background-color: transparent;
  background-color: white;
  padding-bottom: 20px;
}
.scrollable-t-content {
  width: 31%;
  padding-bottom: 10px;
  height: 83%;
  overflow-y: auto;
  margin-left: 68.6%;
  padding-left: 20px;
}
.time-log-add-btn {
  border: none;
  position: fixed;
  margin-right: 108px;
  top: 5px;
  padding: 7px 10px;
  height: auto;
  width: auto;
  background-color: #6200ee;
  color: white;
  border-radius: 3px;
  font-weight: 600;
  right: 33px;
  text-wrap: nowrap;
  cursor: pointer;
}

.btn-container-add-timesheet {
  border-top: 1px solid #616161;
  padding-top: 0px;
  position: fixed;
  top: 86%;
  height: 46px;
  width: 100%;
  left: 68.2%;
  background-color: transparent;
  background-color: white;
  padding-bottom: 20px;
}
.scrollable-t-content::-webkit-scrollbar {
  display: none;
}
.user-container,
.from-to-container,
.from-time-container,
.to-time-container,
.project-select-container,
.phase-container,
.log-category-container,
.task-template-container,
.date-container,
.radio-container,
label.radio-label,
h3.user-name,
.bill,
.from,
.to,
.Title-container {
  font-weight: 400;
  color: black;
  font-size: 18px;
}
.user-name {
  margin-bottom: 3px;
  font-size: 18 !important;
  margin-top: 10px;
}
label.radio-label {
  cursor: pointer;
}
.timesheet-input {
  padding-left: 5px;
  border: 0.5px solid black;
  height: 34px;
  width: 570px;
  border-radius: 3px;
  font-family: "Lato", sans-serif;
  font-size: 15px;
  cursor: pointer;
}
.timesheet-filter-dial {
  z-index: 10;
  padding-left: 0px;
  padding-right: 0px;
  top: 3.9%;
  left: -0.6%;
  animation: slideIn 0.5s forwards;
  height: 93%;
  position: fixed;
  text-align: left;
  transform: translate(-50%, -20%);
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 99%;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.7);
}
.new-time-log {
  font-weight: 400px;
  background-color: #f5f5f5;
  padding: 1px;
  width: 100%;
  margin-top: -0.5%;
  margin-left: 77.6%;
}
.timesheet-btn-container-add-timesheet {
  border-top: 1px solid #616161;
  padding-top: 0px;
  position: fixed;
  top: 93%;
  height: 46px;
  width: 99.9%;
  left: 1496px;
  background-color: transparent;
  background-color: white;
  padding-bottom: 20px;
}
.timesheet-scrollable-t-content {
  width: 23%;
  padding-bottom: 10px;
  height: 90.61%;
  overflow-y: auto;
  margin-left: 77.6%;
  /* padding-left: 35px; */
  padding-top: 20px;
  margin-top: -1%;
}
.timesheet-scrollable-t-content::-webkit-scrollbar {
  display: none;
}
.timesheet-d-input {
  padding-left: 5px;
  border: 0.5px solid black;
  height: 30px;
  width: 560px;
  border-radius: 3px;
  font-family: "Lato", sans-serif;
  font-size: 15px;
}
.timesheet-add-filter {
  display: flex;
  right: -9px;
  position: relative;
}
.overview-add-filter {
  display: flex;
  left: 984px;
  position: relative;
  top: 3px;
}
.overview-add-filter-close {
  display: flex;
  left: 1019px;
  position: relative;
  top: 3px;
}
.timesheet-add-filter-close {
  display: flex;
  right: -63px;
  position: relative;
}
.time-log-filter-btn {
  border: none;
  position: relative;
  color: #6200ee;
  border-radius: 12px;
  margin-top: 48px;
  right: 1px;
  margin-top: 1px;
  height: 30px;
  width: 25px;
  margin-right: -89px;
  margin-left: -322px;
  cursor: pointer;
}
.time-log-filter-btn-close {
  border: none;
  position: relative;
  color: #6200ee;
  border-radius: 12px;
  margin-top: 48px;
  right: 1px;
  margin-top: 1px;
  height: 30px;
  width: 25px;
  margin-right: -89px;
  margin-left: -317px;
  cursor: pointer;
}
.filter-field {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 12px;
}
.filter-name-field {
  display: flex;
  justify-content: space-between;
  /* height: 133px; */
  padding-top: 2px;
  overflow-y: auto;
  margin-bottom: 2px;
}
.filter-name-field::-webkit-scrollbar {
  display: none;
}
.filter-d-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.date-filter-field {
  display: flex;

  margin-bottom: 10px;
}
.filter-input {
  height: 30px;
  width: 250px;
  border: 0.5px solid;
  padding-left: 5px;
}
.filter-n-input {
  height: 30px;
  border: 0.5px solid;
  width: 243px;
  padding-left: 5px;
  margin-left: 120px;
  border-radius: 3px;
}
.filter-t-label {
  margin-right: -7px;
}
.filter-t-input {
  height: 26px;
  border: 0.5px solid;
  width: 407px;
  padding-left: 5px;
  margin-left: 7px;
  border-radius: 3px;
  font-family: "Lato", sans-serif;
  font-size: 13px;
}
.filter-specific-input {
  height: 30px;
  border: 0.5px solid;
  width: 250px;
  margin-left: 99px;
  padding-left: 5px;
}
.filter-dropdown {
  height: 30px;
  width: auto;
  border: 0.5px solid;
  margin-top: 54px;
  margin-right: 24px;
}
.clear-filter-btn {
  position: absolute;
  top: 4px;
  border-radius: 3px;
  background-color: white;
  color: red;
  border: 1px solid red;
  height: 28px;
  right: 388px;
  margin-top: 2px;
  font-weight: 600;
  cursor: pointer;
  font-family: "Lato", sans-serif;
  font-size: 15px;
  width: 100px;
}
.clear-btn-overview {
  right: 265px;
  top: 4px;
  position: absolute;
}
.filter-radio {
  margin-left: 81px;
}
.from-to {
  margin-left: 97px;
  display: flex;
  flex-direction: row;
}
.from-con {
  display: flex;
  flex-direction: column;
  margin-right: 40px;
}
.filter-from-label {
  margin-right: 15px;
}
.filter-to-label {
  margin-right: 55px;
}
.filter-category-dropdown {
  width: 250px;
  margin-right: 40px;
}
.filter-billing-dropdown {
  width: 245px;
  margin-left: 120px;
  height: 30px;
  border-radius: 3px;
}
.status-dropdown {
  border: none;
  width: 100%;
  padding-top: 5px;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 5px;
  appearance: none;
  font-weight: 550;
  outline: none;
  cursor: pointer;
  border-radius: 3px;
  text-align: center;
  font-family: "Lato", sans-serif;
  font-size: 13px;
}
.status-dropdown-user {
  border: none;
  width: auto;
  vertical-align: middle;
  height: 25px;
  font-weight: 400;
  font-family: "Lato", sans-serif;
  font-size: 15px;
  color: #000;
  background-color: transparent;
  outline: none;
  border-radius: 3px;
}
.export-btn {
  height: 28px;
  width: auto;
  padding: 4px;
  text-align: center;
  border: 1px solid #6200ee;
  color: #6200ee;
  border-radius: 3px;
  margin-right: 60px;
  margin-top: 6px;
  text-wrap: nowrap;
  font-weight: 800;
  cursor: pointer;
  font-family: "Lato", sans-serif;
  font-size: 15px;
}
.export-btn-close {
  height: 28px;
  width: auto;
  padding: 4px;
  text-align: center;
  border: 1px solid #6200ee;
  color: #6200ee;
  border-radius: 3px;
  margin-right: 21px;
  margin-top: 6px;
  text-wrap: nowrap;
  font-weight: 800;
  cursor: pointer;
  font-family: "Lato", sans-serif;
  font-size: 15px;
}
.date-range {
  position: absolute;
  top: 0px;
  left: 659px;
}
.timesheet-overlay-add-filter {
  position: fixed;
  text-align: left;
  top: 23%;
  left: 89.4%;
  z-index: 2;
  transform: translate(-50%, -20%);
  padding-bottom: 0px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 20%;
  height: 86.71%;
  border-radius: 3px;
}
.accordion-header {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 0.5px solid #ddd;
  border-bottom: 0.5px solid #ddd;
  height: 27px;
  padding: 3px;
  background-color: #e6e0fd;
}
.accordion-u-header {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 0.5px solid #ddd;
  border-bottom: 0.5px solid #ddd;
  height: 30px;
  margin-bottom: -21px;
}
.accordion-header:hover,
.accordion-u-header:hover {
  color: #6200ee;
}
.arrow-d-icon {
  height: 12px;
  width: 24px;
  margin-top: 10px;
  margin-left: 143px;
  color: #6200ee;
  background-color: transparent;
}
.arrow-icon {
  height: 12px;
  width: 24px;
  margin-top: 10px;
  margin-left: 220px;
  color: #6200ee;
  background-color: transparent;
}
.is-not {
  border: none;
  height: 25px;
  width: 62px;
  background-color: white;
  margin-top: 0px;
  border-radius: 3px;
  font-family: "Lato", sans-serif;
  font-size: 13px;
}
.accordion-label {
  background-color: transparent;
  margin-left: 10px;
  margin-top: 2px;
  text-wrap: nowrap;
  user-select: none;
}
.span-element {
  background-color: transparent;
  margin-right: 15px;
  margin-top: -4px;
}
.user-name-list {
  cursor: pointer;
  display: flex;
  width: 436px;
  margin-left: -42px;
  background-color: transparent;
  margin-bottom: 10px;
}
.user-n-list {
  cursor: pointer;
  display: flex;
  width: 384px;
  margin-left: -42px;
  background-color: #e6e0fd;
}
.user-name-list:hover {
  background-color: #e6e0fd;
}
.user-ul {
  margin-top: 2px;
  margin-left: 42px;
  margin-bottom: -10px;
}
.users-ul {
  margin-top: 2px;
  margin-left: 42px;
  margin-bottom: -10px;
}
.initial {
  background-color: color;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  font-size: 12px;
  text-align: center;
  margin-top: 0px;
  font-weight: 800;
  background-color: rgb(108, 160, 220);
  margin-right: 10px;
  margin-left: 20px;
}
.filter-billing-checkbox {
  display: flex;
  flex-direction: column;
  margin-left: 18px;
}
.users-count {
  background-color: #6200ee;
  margin-left: 5px;
  color: white;
  font-weight: 800;
  padding: 2px 5px;
  border-radius: 3px;
  text-align: center;
  font-size: 10px;
}
@keyframes slideInRight {
  from {
    transform: translateX(100%); /* Start from right */
    opacity: 0;
  }
  to {
    transform: translateX(0); /* End in place with existing transform */
    opacity: 1;
  }
}
.filter-dial {
  z-index: 10;
  padding-left: 0px;
  padding-right: 0px;
  top: 3.9%;
  left: -0.6%;
  animation: slideIn 0.5s forwards;
  height: 93%;
  position: fixed;
  text-align: left;
  transform: translate(-50%, -20%);
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 99%;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.7);
}

.span-input {
  z-index: 9;
  border: none;
  background-color: transparent;
  margin-left: 0px;
  position: relative;
  left: -1295px;
}
.overview-main-container {
  height: 883px;
  margin-top: -82px;
}
.additional {
  height: 787px;
}
.additional-summary {
  top: 749px;
}
.timesheet-message {
  position: relative;
  top: -20px;
  left: 678px;
}
.issue-timesheet-message {
  position: relative;
  top: -55px;
  left: 678px;
}
.timesheet-message-t {
  position: relative;
  top: -20px;
  left: 758px;
}
.timesheet-image {
  height: 300px;
  width: 300px;
  position: relative;
  left: 700px;
}
.issue-timesheet-image {
  height: 300px;
  width: 300px;
  position: relative;
  left: 610px;
  top: 4px;
  background-color: transparent;
  margin-bottom: 35px;
  width: -moz-fit-content;
  width: fit-content;
}
.admin-with-export {
  background-color: red;
  margin-top: 6px;
  margin-right: 116px;
  height: 28px;
  font-weight: 700;
  width: auto;
  padding: 5px 10px;
  text-align: center;
  border: none;
  color: white;
  border-radius: 3px;
  margin-left: 22px;
  cursor: pointer;
  font-family: "Lato", sans-serif;
  font-size: 15px;
}
.user-without-export {
  background-color: red;
  margin-top: 6px;
  margin-right: 52px;
  height: 28px;
  font-weight: 700;
  width: auto;
  padding: 5px 10px;
  text-align: center;
  border: none;
  color: white;
  border-radius: 3px;
  margin-left: 22px;
  cursor: pointer;
}
.toggle-switch {
  position: relative;
  width: 60px;
  height: 30px;
}

.toggle-checkbox {
  display: none;
}

.toggle-label {
  display: block;
  position: relative;
  width: 85%;
  height: 75%;
  background-color: #ccc;
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.toggle-label .toggle-inner {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  border-radius: 15px;
  background-color: #ccc;
  transition: background-color 0.3s;
}

.toggle-label .toggle-switch {
  position: absolute;
  top: 3.2px;
  left: 3px;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-radius: 50%;
  transition: transform 0.3s;
}

.toggle-checkbox:checked + .toggle-label .toggle-inner {
  background-color: #6200ee;
}

.toggle-checkbox:checked + .toggle-label .toggle-switch {
  transform: translateX(30px);
}
.react-datepicker__header {
  background-color: transparent;
}
.timesheet-datepicker {
  border: none;
  padding: 5px;
  width: 80px;
}
.timesheet-datepicker:focus {
  border-color: white;
  outline: 3px solid #ffffff;
  outline-offset: 2px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
}
.timesheet-duration {
  text-align: center;
  border: none;
  width: 110px;
  padding: 5px;
  height: 25px;
  font-weight: 400;
  font-family: "Lato", sans-serif;
  font-size: 15.5px;
  color: #000;
  background-color: transparent;
}
.timesheet-duration:focus {
  border-color: white;
  height: 35px;
  outline: 3px solid #ffffff;
  outline-offset: 2px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
}
.timesheet-billable-status {
  border: none;
  padding: 3px;
  color: rgb(255, 255, 255);
  width: 127px;
  height: 38px;
  font-weight: 400;
  font-family: "Lato", sans-serif;
  font-size: 15.5px;
}
.timesheet-billable-status option {
  color: black;
  background-color: white;
}
.timesheet-billable-status:focus {
  border-color: white;
  outline: 3px solid #ffffff;
  outline-offset: 2px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
}
.project-display-box {
  width: 567px;
  border-radius: 3px;
  background-color: grey;
  max-height: 200px;
  position: relative;
  top: -16px;
  overflow: auto;
  border: 1px solid;
}
.filter-p-input {
  border: 0.5px solid;
  padding: 5px;
  border-radius: 3px;
  margin-left: 7px;
  margin-top: 5px;
  width: 401px;
  font-family: "Lato", sans-serif;
  font-size: 13px;
}
.project-display-box-overview {
  width: 410px;
  border-radius: 3px;
  background-color: grey;
  max-height: 200px;
  position: relative;
  top: -17px;
  overflow: auto;
  border: 1px solid;
  left: 7px;
  height: 70px;
}
.bulk-update-overview-btn {
  background-color: rgb(98, 0, 238);
  position: absolute;
  height: 28px;
  font-weight: 700;
  width: auto;
  padding: 5px 10px;
  text-align: center;
  border: none;
  color: white;
  border-radius: 3px;
  left: 140px;
  cursor: pointer;
  top: 7px;
}
.bulk-approval {
  top: 5px;
  left: 250px;
  background-color: white;
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 140px;
  border: 0.5px solid black;
  padding-right: 16px;
  padding-bottom: 16px;
  border-radius: 3px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
  z-index: 11;
}

.bulk-approval-dropdown {
  border: 0.5px solid black;
  padding: 5px;
  border-radius: 3px;
  margin-left: 20px;
  margin-top: 13px;
  text-align: center;
  font-family: "Lato", sans-serif;
  font-size: 13px;
}

.bulk-approval-header {
  margin-left: 16px;
  margin-top: 16px;
  margin-bottom: 0px;
}
.overview-scrolling-content {
  position: fixed;
  overflow: auto;
  height: 84.2vh;
  width: 98.8vw;
}
.overview-scrolling-content-close {
  position: fixed;
  overflow: auto;
  height: 797px;
  width: 1913px;
}
.overview-scrolling-content-close::-webkit-scrollbar {
  display: none;
}
.remaining-hours {
  position: relative;
  top: -335px;
  left: 50px;
}
.remaining-hours-fromto {
  position: relative;
  top: -420px;
  left: 50px;
}

.users-timesheet {
  position: absolute;
  top: 6px;
  left: -270px;
  font-size: 22px;
  color: #6200ee;
  cursor: pointer;
}
.usertimesheetdial {
  position: relative;
  top: -6px;
  left: 1595px;
  z-index: 11;
  overflow-y: auto;
  height: 820px;
  width: 286px;
  padding-left: 30px;
  padding-bottom: 30px;
}
.usertimesheetdial-con {
  position: fixed;
  text-align: left;
  top: 3.9%;
  left: -0.6%;
  z-index: 2;
  transform: translate(-50%, -20%);
  padding-bottom: 0px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 92.9%;
  border-radius: 3px;
  animation: slideIn 0.5s forwards;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.7);
}
.usertimesheetdial-con ::-webkit-scrollbar {
  display: none;
}
.overview-user-timesheet-close {
  position: fixed;
  left: 1891px;
  height: 20px;
  width: 20px;
  color: #6200ee;
  cursor: pointer;
  z-index: 2;
  top: 22px;
  background-color: transparent;
}
.user-timesheet-header-con {
  color: black;
  position: fixed;
  margin-top: -40px;
  margin-bottom: 0px;
  font-weight: 500;
  font-size: 16px;
  padding: 1px;
  background-color: #f5f5f5;
  width: 100%;
  margin-left: -30px;
  padding: 8px;
  z-index: 1;
  padding-left: 20px;
}
.user-timesheet-header {
  background-color: transparent;
  font-size: 16px;
  margin: 0px;
}
.user-table-overview {
  position: relative;
  top: 40px;
  width: 320px;
}
.user-timesheet-datepicker {
  background-color: white;
  border-radius: 3px;
  width: 100px;
  height: 20px;
  border: 0.5px solid;
  padding-left: 8px;
  margin-left: 8px;
  font-family: "Lato", sans-serif;
  font-size: 15px;
  cursor: pointer;
}
.user-timesheet-datepicker:focus {
  border-color: white;
  outline: 3px solid #ffffff;
  outline-offset: 2px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
}
.user-timesheet-total-hours {
  border-top: 1px solid #616161;
  padding-top: 0px;
  position: fixed;
  top: 94%;
  height: 35px;
  width: 15.9%;
  left: 1615px;
  background-color: white;
  padding-bottom: 10px;
  padding-left: 23px;
}
.user-timesheet-table {
  width: 14vw;
  border-collapse: collapse;
  margin: 0px 0;
  position: relative;
  left: -5px;
  overflow-x: auto;
  top: 8px;
}
.user-timesheet-table th {
  border: 1px solid #ddd;
  padding: 10px;
}

@media (min-width: 1440px) and (max-width: 1919px) {
  .timesheet-overlay-add-time-log {
    top: 5%;
    width: 59%;
    left: -1.4%;
    padding-bottom: 5px;
  }
  .timesheet-filter-dial {
    top: 5.9%;
  }
  .timesheet-btn-container-add-timesheet {
    top: 89%;
    left: 1206px;
  }
  .new-time-log {
    font-weight: 400px;
    background-color: #f5f5f5;
    padding: 1px;
    width: 67%;
    margin-top: -1.5%;
    margin-left: 77.6%;
  }
  .new-time-log-dial {
    font-weight: 400px;
    background-color: #f5f5f5;
    padding: 1px;
    width: 67%;
    margin-top: -1.5%;
    margin-left: 102.6%;
  }
  .overview-filter-btn-container-add-timesheet {
    top: 91%;
    left: 1212px;
  }
  .filter-dial {
    left: -1.3%;
    width: 100%;
    top: 6%;
    padding-bottom: 0px;
  }
  .scrollable-t-content {
    width: 100%;
    padding-bottom: 10px;
    height: 80.61%;
    overflow-y: auto;
    position: relative;
    left: 305px;
  }
  .span-input {
    left: -1116px;
  }
  .single-phase {
    height: 486px;
  }
  .single-summary {
    top: 456px;
  }
  .table-container-timesheet {
    max-height: 300px;
  }
  .main-container {
    height: 553px;
  }
  .main-container-close {
    height: 553px;
  }
  .overview-main-container {
    height: 641px;
  }
  .additional {
    height: 579px;
  }
  .additional-summary {
    top: 540px;
  }
  .overview-add-filter {
    left: 796px;
  }
  .overview-add-filter-close {
    left: 839px;
  }
  .timesheet-message {
    left: 480px;
  }
  .timesheet-message-t {
    top: 0px;
    left: 558px;
  }
  .timesheet-image {
    left: 500px;
  }
  .btn-container-add-timesheet {
    top: 85%;
    width: 65%;
    left: 946px;
  }
  .issue-timesheet-image {
    left: 32%;
  }
  .issue-timesheet-message {
    left: 36%;
  }
  .time-log-filter-btn-close {
    margin-left: -333px;
  }
  h3.user-name {
    font-size: 16px;
  }
  .bill {
    font-size: 16px !important;
  }
  .export-btn {
    font-size: 13px;
  }
  .export-btn-close {
    font-size: 13px;
  }
  .filter-project-search {
    display: flex;
    flex-direction: column;
  }
  .filter-p-input {
    width: 317px;
    margin-left: 8px;
    margin-top: 5px;
  }
  .project-display-box-overview {
    width: 327px;
  }
  .filter-t-input {
    width: 320px;
  }
  .overview-filter-scrollable-t-content {
    height: 85%;
    width: 22.4%;
  }
  .clear-filter-btn {
    right: 376px;
    font-size: 13px;
  }
  .admin-with-export {
    font-size: 13px;
    margin-right: 118px;
  }
  .overview-scrolling-content {
    width: 1500px;
    height: 593px;
  }
  .overview-scrolling-content-close {
    width: 1538px;
    height: 593px;
  }
  .clear-btn-overview {
    right: 250px;
    font-size: 13px;
  }
  .remaining-hours {
    top: -319px;
    left: 50px;
  }
}
