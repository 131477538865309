.main-container-signin {
  background-size: cover;
  height: 100vh;
  width: 100vw;
  background-image: url("../../../public/AppLayout/signin-bg.png");
}
.button {
  position: absolute;
  top: 236px;
  left: 83px;
  cursor: pointer;
}
.sign-in1 {
  margin: 0;
  position: absolute;
  top: 209px;
  left: 293px;
}
.sign-in1 {
  top: 86px;
  left: 83px;
  font-size: var(--body-text-body-text-6-size);
  font-weight: 400;
  font-family: inherit;
  color: var(--black);
}
.group-158-1 {
  top: 32px;
  left: 83px;
  width: 184px;
  height: 48px;
}
.type-email-parent {
  position: absolute;
  top: calc(50% - 168.5px);
  left: calc(50% - 439px);
  box-shadow: var(--drop-shadow-1);
  border-radius: var(--br-base);
  background-color: var(--white);
  width: 879px;
  height: 336px;
  overflow: hidden;
}
.sign-in {
  width: 100%;
  position: relative;
  background-color: var(--background);
  height: 773px;
  overflow: hidden;
  cursor: pointer;
  text-align: left;
  font-size: var(--text-sm-semibold-size);
  color: var(--primary-color);
  font-family: var(--body-text-body-text-4);
}
.button2 {
  position: absolute;
  top: 236px;
  left: 83px;
  cursor: pointer;
  background-color: #6200ee;
  padding-left: 12px;
  padding-right: 12px;
  padding-left: 12px;
  font-size: 16;
  border: none;
  color: white;
  width: 320px;
  height: 45px;
  border-radius: 8px;
  font-family: "Lato", sans-serif;
  font-size: 15px;
}
.forgot-password1 {
  margin: 0;
  position: absolute;
  top: 63%;
  left: 34%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  font-size: 1rem;
}
.sign-in3 {
  margin: 0;
  position: absolute;
  top: 209px;
  left: 293px;
}
.sign-in3 {
  top: 86px;
  left: 83px;
  font-size: var(--text-md-regular-size);
  font-weight: 400;
  font-family: inherit;
  color: var(--black);
}
.group-158-1,
.task-bro-1-icon1 {
  position: absolute;
  object-fit: cover;
}
.task-bro-1-icon1 {
  top: 1px;
  right: 45px;
  width: 461px;
  height: 334px;
  overflow: hidden;
  padding-left: 50px;
  border-left: 0.5px solid #dddd;
}
.group-158-1 {
  top: 32px;
  left: 83px;
  width: 184px;
  height: 48px;
}
.type-email-parent {
  position: absolute;
  top: calc(50% - 168.5px);
  left: calc(50% - 439px);
  box-shadow: 5px 5px 14px 1px rgba(0, 0, 0, 0.25);
  border-radius: var(--br-base);
  background-color: var(--white);
  width: 1000px;
  height: 336px;
  overflow: hidden;
  text-align: left;
  font-size: var(--body-text-body-text-5-size);
  color: var(--primary-color);
  font-family: var(--body-text-body-text-5);
  background-color: white;
  border-radius: 3px;
}
.type-email {
  border: 0.5px solid;
  padding-left: 5px;
  outline: 0;
  position: absolute;
  top: 115px;
  left: 83px;
  box-sizing: border-box;
  width: 320px;
  height: 35px;
  border-radius: 3px;
}
.login-error-message {
  position: relative;
  top: 188px;
  left: 222px;
  color: red;
}
.sigin-error {
  color: red;
  /* margin-left: -193px; */
  position: fixed;
  top: 510px;
  left: 608px;
}
.login-icon {
  position: relative;
  top: 174px;
  left: 379px;
  cursor: pointer;
  background-color: transparent;
}
.forgot-error {
  top: 456px;
  left: 665px;
  text-wrap: nowrap;
  color: red;
}
.forgot-success {
  top: 458px;
  left: 725px;
  text-wrap: nowrap;
  color: green;
}
.forgot-to-signin {
  top: 481px;
  width: 106px;
  left: 817px;
}
.reset-password-message {
  top: 501px;
  left: 679px;
  color: green;
}
.reset-password-error {
  top: 501px;
  left: 679px;
  color: red;
}
@media (min-width: 1440px) and (max-width: 1919px) {
  .forgot-password1 {
    top: 425px;
    left: 603px;
  }
  .sigin-error {
    top: 402px;
    left: 414px;
  }
  .forgot-error {
    top: 356px;
    left: 469px;
  }
  .forgot-success {
    top: 357px;
    left: 527px;
  }
  .forgot-to-signin {
    top: 376px;
    left: 617px;
  }
}
