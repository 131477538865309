.settings-main-details {
  position: fixed;
  bottom: 80px;
  left: 148px;
  top: 90px;
  height: 95vh;
  padding-bottom: 59px;
  display: flex;
}
.settings-main-details-close {
  position: fixed;
  bottom: 80px;
  left: 77px;
  top: 90px;
  height: 100%;
  padding-bottom: 59px;
  display: flex;
}
.left-Navbar-p {
  position: relative;
  background-color: transparent;
  height: 95vh;
  width: 115px;
  top: -24px;
  padding: 5px 10px;
}
.left-Navbar {
  position: relative;
  background-color: transparent;
  height: 95vh;
  width: 115px;
  top: 26px;
  padding: 5px 10px;
}
.settings-label {
  position: relative;
  display: inline-block;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 100px;
  transition: color 0.3s ease-in-out;
  width: 105%;
  margin-bottom: 0px;
  margin-top: 5px;
}

/* Hover & Active Background Effect */
.settings-label::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 0%;
  height: 100%;
  background-color: rgba(137, 43, 226, 0.177); /* Initial background color */
  border-radius: 10px;
  transform: translate(-50%, -50%);
  transition: width 0.3s ease-in-out;
}

/* Hover and Active - Background Expands */
.settings-label:hover::before,
.settings-label.active::before {
  width: 100%;
}

/* Text Color Change on Hover & Active */
.settings-label:hover,
.settings-label.active {
  color: #892be2; /* Change text color slightly */
}

/* Keep Background for Active State */
.settings-label.active::before {
  /* background-color: rgba(137, 43, 226, 0.3);  */
  background-color: rgba(137, 43, 226, 0.177);
}

/* Active State - Font Weight & Color */
.settings-label.active {
  font-weight: 600;
  color: #6200ee; /* Keeps the text color */
}
.setting-container-close {
  display: flex;
  flex-direction: column;
  padding: 14px;
  margin-top: -22px;
  margin-left: -8px;
  margin-right: 24px;
  text-align: left;
  justify-content: flex-start;
  box-shadow: 5px 5px 14px 1px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  width: 88.7vw;
  height: 88vh;
  overflow: hidden;
}
.setting-container {
  display: flex;
  flex-direction: column;
  padding: 14px;
  margin-top: -22px;
  margin-left: -8px;
  margin-right: 24px;
  text-align: left;
  justify-content: flex-start;
  box-shadow: 5px 5px 14px 1px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  width: 85vw;
  height: 88vh;
  overflow: hidden;
}

.setting-container::-webkit-scrollbar {
  display: none;
}
.setting-container-close::-webkit-scrollbar {
  display: none;
}
.email-test-settings {
  display: flex;
  flex-direction: row;
}
.left-right-con {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: -55px;
  margin-left: -75px;
}
.project-content {
  display: flex;
  flex-direction: column;
  padding: 14px;
  margin-right: 24px;
  text-align: left;
  justify-content: flex-start;
  box-shadow: 5px 5px 14px 1px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  overflow: auto;
  margin-top: -926px;
  margin-left: 188px;
  width: 82vw;
  height: 79vh;
}
.project-content-close {
  display: flex;
  flex-direction: column;
  padding: 14px;
  margin-right: 24px;
  text-align: left;
  justify-content: flex-start;
  box-shadow: 5px 5px 14px 1px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  overflow: auto;
  margin-top: -926px;
  margin-left: 188px;
  width: 85.8vw;
  height: 79vh;
}
@media (min-width: 1440px) and (max-width: 1919px) {
  .setting-container {
    width: 82vw;
    height: 85vh;
  }
  .project-content {
    margin-top: -710px;
    width: 79vw;
    height: 71vh;
  }
  .project-content-close {
    margin-top: -710px;
    width: 79vw;
    height: 71vh;
  }
  .project-content::-webkit-scrollbar {
    display: none;
  }
  .project-content-close::-webkit-scrollbar {
    display: none;
  }
  .settings-main-details {
    height: 79vh;
  }
  .setting-container-close {
    width: 86.7vw;
    height: 85vh;
  }
}
