/* ConvertToProject.css */

/* Make sure the body and html take up full height */
/* html,
body {
  height: 100%;
  margin: 0;
  font-family: Arial, sans-serif;
} */

/* Full page container for the convert project */
.full-page-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 20px;
  box-sizing: border-box;
  position: fixed;
  z-index: 10;
  overflow: auto;
  height: 104vh;
  width: 97.5vw;
  top: 49px;
}
.full-page-container-close {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 20px;
  box-sizing: border-box;
  position: fixed;
  z-index: 10;
  overflow: auto;
  height: 104vh;
  width: 101vw;
  top: 49px;
}
.estimate-conver-close {
  position: relative;

  top: -9px;
  left: 1586px;
  color: blue;
  font-size: 20px;
  cursor: pointer;
}
.estimate-conver-close-close {
  position: relative;

  top: -9px;
  left: 1654px;
  color: blue;
  font-size: 20px;
  cursor: pointer;
}
/* Add a header if you need a title at the top */
.page-header {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
  display: flex;
}

/* Phase section style */
.phase-section {
  margin-bottom: 20px;
}

/* Template section styling */
.template-section {
  margin-bottom: 20px;
}

/* Table styling */
.task-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.task-table th {
  padding: 10px;
  text-align: left;
  border: 1px solid #ccc;
}

.task-table td {
  border: 1px solid #ddd;
  text-align: left;
  white-space: nowrap;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
}
.task-table thead th {
  background-color: white;
  color: black;
  text-wrap: nowrap;
  user-select: none;
  text-align: left;
  padding: 10px;
  position: relative;
  border: 1px solid #ddd;
  padding-top: 6px;
  padding-bottom: 6px;
}
/* Submit button styling */
.submit-btn {
  font-weight: 600;
  cursor: pointer;
  margin-bottom: 10px;
  height: auto;
  width: 67px;
  padding: 7px 10px;
  border: none;
  color: white;
  background-color: #6200ee;
  border-radius: 3px;
  position: relative;
  top: -7.9%;
  left: 0%;
  margin-top: 89px;
}

.estimate-select-tasktemplate {
  padding: 5px;
  margin-bottom: 10px;
  border-radius: 3px;
  width: 11%;
  cursor: pointer;
  font-family: "Lato", sans-serif;
  font-size: 14px;
}
.estimate-select-user {
  padding: 5px;
  width: 29%;
  height: 30px;
  border-radius: 3px;
  /* margin-top: -20px; */
  cursor: pointer;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  position: relative;
  top: 11px;
}
.estimate-table-dropdown {
  padding: 5px;
  width: 91%;
  border-color: white;
  cursor: pointer;
  font-family: "Lato", sans-serif;
  font-size: 14px;
}
.estimate-table-dropdown:focus {
  border-color: white;
  outline: 3px solid #ffffff;
  outline-offset: 2px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
  background-color: transparent;
}
.task-name-estimate {
  width: 70px;
}

.est-phase-details-text {
  position: fixed;
  top: 95.5%;
  display: flex;
  justify-content: space-between;
  width: 96vw;
  left: 4%;
  font-weight: 500;
  border-top: 1px solid #ddd;
}
.est-phase-details-text-close {
  position: fixed;
  top: 905px;
  display: flex;
  justify-content: space-between;
  width: 1952px;
  left: 21px;
  font-weight: 500;
  border-top: 1px solid #ddd;
}
.phase-total {
  color: green;
  font-weight: 800;
  position: fixed;
  right: 1443px;
}

.phase-total-close {
  color: green;
  font-weight: 800;
  position: fixed;
  right: 1510px;
}

.phase-design-total {
  color: orange;
  font-weight: 800;
  position: fixed;
  right: 1560px;
}
.phase-design-total-close {
  color: orange;
  font-weight: 800;
  position: fixed;
  right: 1624px;
}
.phase-detailing-total {
  color: blue;
  font-weight: 800;
  position: fixed;
  right: 1501px;
}
.phase-detailing-total-close {
  color: blue;
  font-weight: 800;
  position: fixed;
  right: 1567px;
}
.est-phase-pagination {
  display: flex;
  margin-top: 1%;
  left: 81%;
  position: relative;
}
.est-phase-pagination-close {
  display: flex;
  margin-top: 21px;
  left: 81%;
  position: relative;
}
.convert-to-project-header {
  position: fixed;
  top: 81px;
  left: 86px;
  background-color: transparent;
  display: flex;
  white-space: nowrap;
}
.convert-to-project-project-manager {
  margin-top: -3.9%;
  margin-left: 27%;
}
.convert-to-project-table-con {
  overflow-y: auto;
  border: solid 0.5px #ddd;
  margin-top: 25px;
  border-radius: 3px;
  padding-left: 10px;
  padding-right: 10px;
}
.convert-to-project-table-con-close {
  overflow-y: auto;
  border: solid 0.5px #ddd;
  margin-top: 26px;
  border-radius: 3px;
  padding-left: 10px;
  padding-right: 10px;
}
.convert-to-project-header-close {
  position: fixed;
  top: 81px;
  left: 22px;
  background-color: transparent;
  display: flex;
  white-space: nowrap;
}
@media (min-width: 1440px) and (max-width: 1919px) {
  .estimate-conver-close {
    position: relative;
    top: -9px;
    left: 1196px;
  }
  .estimate-conver-close-close {
    position: relative;
    font-size: 20px;
    top: -9px;
    left: 1264px;
    color: blue;
    font-size: 20px;
    cursor: pointer;
  }
  .submit-btn {
    top: -10.9%;
    left: 0%;
  }
  .est-phase-details-text {
    top: 686px;
    left: 78px;
  }
  .phase-total {
    right: 1080px;
  }
  .phase-detailing-total {
    right: 1133px;
  }
  .phase-design-total {
    right: 1186px;
  }
  .est-phase-pagination {
    left: 84%;
    position: fixed;
    margin-top: 15px;
  }
  .est-phase-details-text-close {
    top: 686px;
    left: 16px;
  }
  .phase-total-close {
    right: 1150px;
  }
  .phase-detailing-total-close {
    right: 1198px;
  }
  .phase-design-total-close {
    right: 1246px;
  }
  .est-phase-pagination-close {
    left: 84%;
    margin-top: -195px;
    position: fixed;
  }
  .convert-to-project-table-con {
    width: 97.6%;
  }
  .convert-to-project-table-con-close {
    width: 98.6%;
  }
  .task-name-estimate {
    width: 90px;
  }
  .estimate-select-user {
    width: 26%;
  }
  .estimate-select-tasktemplate {
    width: 12%;
  }
}
