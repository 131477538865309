.greeting {
  font-weight: normal;
  margin-left: 2px;
  font-size: 12px;
}
.greeting-content {
  position: relative;
  top: 0%;
  left: 21px;
  font-size: 16px;
  font-weight: 400;
}
.greeting-content-close {
  position: relative;
  top: 0%;
  left: 21px;
  font-size: 16px;
  font-weight: 400;
}
.home-container {
  position: relative;
  left: 79px;
}
.temp-text {
  margin-left: 100px;
  font-size: 50px;
  margin-top: 275px;
}
.home-dashboard-main-container {
  position: fixed;
  left: 72px;
  width: 100vw;
  top: 46px;
  overflow: auto;
  height: 100vh;
  box-sizing: border-box;
}
.home-dashboard-main-container::-webkit-scrollbar {
  display: none;
}
.home-dashboard-main-container-close::-webkit-scrollbar {
  display: none;
}
.home-dashboard-main-container-close {
  position: fixed;
  left: 2px;
  width: 100vw;
  top: 50px;
  overflow: auto;
  height: 100vh;
  box-sizing: border-box;
}
.project-status {
  height: 350px;
  width: 100%;
  background-color: transparent;
}
.project-bar-status {
  height: 373px;
  width: 100%;
  background-color: transparent;
}
.manager {
  box-sizing: border-box;
  display: block;
  height: 300px;
  width: 772px;
}
.timeline-summary {
  height: 400px;
  width: 800px;
  position: relative;
  box-sizing: border-box;
  display: block;
}
.pie-header {
  font-size: 16px;
  position: relative;
  top: -10px;
}
.pie-header-p {
  font-size: 16px;
  position: relative;
  top: -20px;
}
@media (min-width: 1440px) and (max-width: 1919px) {
  .temp-text {
    font-size: 38px;
  }
  .greeting-content {
    top: 0%;
    left: 90px;
  }
  .greeting-content-close {
    top: 0%;
    left: 30x;
  }
  .home-dashboard-main-container {
    left: 4px;
  }
}
