.phase-details {
  position: fixed;
  top: 139px;
  margin-left: 47px;
  z-index: 0;
  left: 0px;
  background-color: white;
  height: 85%;
  width: 96%;
  overflow: auto;
}
.phase-details::-webkit-scrollbar {
  display: none;
}
.phase-details-close {
  position: fixed;
  top: 2px;
  /* margin-left: 47px; */
  z-index: -1;
  left: 23px;
  background-color: white;
  height: 100%;
  width: 100%;
  overflow: auto;
}

.phase-title {
  font-weight: normal;
  font-size: 16px;
  margin-right: 35px;
}

.tabs {
  display: flex;
  border-bottom: 1px solid #ddd;
}

.task-name {
  font-weight: 600;
  text-align: left;
  width: 310px;
}
.task-user-name {
  font-weight: 600;
  text-align: left;
  width: 320px;
}
.phase-table-title {
  font-size: 16px;
  margin-right: 35px;
  font-weight: 600;
}
.tab-button {
  padding: 10px 20px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  margin-right: 5px;
  padding-left: 0px;
  font-size: 16px;
}

.tab-button.active {
  color: #6200ee;
  font-weight: 550;
}

.tab-content {
  padding: 20px;
  border: 1px solid #ddd;
  border-top: none;
  border-radius: 5px;
}
.accordion {
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 20px;
  width: 93vw;
}
.accordion-close {
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 20px;
  width: 96.7vw;
}

.accordion-summary {
  cursor: pointer;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.accordion-summary h6 {
  margin: 0;
}

.accordion-details {
  padding: 10px;
  background-color: transparent;
}

.accordion-table {
  background-color: transparent;
}

.accordion-table {
  width: 100%;
  border-collapse: collapse;
}

.accordion-table th,
td {
  padding: 10px;
  border: 1px solid #ddd;
  background-color: transparent;
  font-size: 15.5px;
  white-space: nowrap;
}

.accordion-table thead {
  background-color: #f4f4f4;
  font-size: 15px;
}

.variation-btn {
  position: fixed;
  top: 222px;
  text-wrap: nowrap;
  left: 92%;
  width: 100px;
  font-weight: 600;
  padding: 7px;
  padding-right: 18px;
  padding-left: 9px;
}
.vara {
  width: 101px;
}

.error-message {
  position: relative;
  top: 267px;
}
.add-variation-dial {
  display: flex;
  flex-direction: column;
  top: 5.3%;
  position: fixed;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  height: 100%;
  left: -0.1%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 3px;
  border-top: none;
  transition: left 1s ease;
  overflow: auto;
  animation: slideIn 0.5s forwards;
}
.add-variation-dial::-webkit-scrollbar {
  display: none;
}
.mass-update-var-issue {
  position: relative;
  left: -20px;
  top: -7px;
  cursor: pointer;
  font-family: "Lato", sans-serif;
  font-size: 13px;
}
.action-box-variation {
  top: -32px;
  left: 114px;
  position: relative;
  background-color: white;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 196px;
  border: 0.5px solid black;
  padding-right: 22px;
  padding-bottom: 15px;
  border-radius: 3px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
}

.delete-var-issue-dial {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 101%;
  background-color: rgba(0, 0, 0, 0.7);
  height: 94.4%;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 3px;
  overflow: auto;
  top: 4.58%;
  left: -0.7%;
  animation: fadeInCenter 1s forwards;
  z-index: 10;
}
.delete-var-dial {
  width: 25%;
  margin-left: 38%;
  margin-top: 17%;
  background-color: white;
  padding: 16px;
  border-radius: 3px;
}
.delete-btn-issue-del-con {
  background-color: white;
  display: flex;
  flex-direction: row;
  margin-left: -15px;
  margin-top: 9px;
  border-top: 0.5px solid;
  width: 106%;
}
.delete-issue-dial {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 101%;
  background-color: rgba(0, 0, 0, 0.7);
  height: 94.4%;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 3px;
  overflow: auto;
  top: 4.58%;
  left: -0.7%;
  animation: fadeInCenter 1s forwards;
}
.delete-var-issue-dial::-webkit-scrollbar {
  display: none;
}
.delete-issue-dial::-webkit-scrollbar {
  display: none;
}
.dial-v-header {
  background-color: #f5f5f5;
  width: 33%;
  z-index: 1;
  margin-top: -10px;
  margin-left: 67%;
}

.add-edit-variation {
  margin-left: 15px;
  font-weight: bold;
  margin-top: 8px;
  background-color: transparent;
  text-align: left;
}
.add-edit-issue-btn {
  height: auto;
  width: auto;
  text-align: center;
  border: none;
  color: white;
  border-radius: 3px;
  background-color: #6200ee;
  margin-right: 10px;
  margin-left: 22px;
  margin-top: 15px;
  padding-right: 12px;
  padding-left: 12px;
  font-weight: 600;
  cursor: pointer;
  font-family: "Lato", sans-serif;
  font-size: 14px;
}
.add-variation-content {
  background-color: white;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 67%;
  margin-top: -10px;
  width: 31%;
  height: 90.5%;
  padding-left: 15px;
  padding-right: 15px;
}
.variation-number,
.variation-name,
.variation-hours,
.issue-hour,
.issue-name,
.issue-number {
  background-color: white;
  border: 0.5px solid;
  padding: 6px;
  border-radius: 3px;
  font-family: "Lato", sans-serif;
  font-size: 15px;
}
.variation-name {
  max-width: 580px;
  max-height: 100px;
}
.variation-hours {
  padding: 6px;
}
.issue-number {
  background-color: white;
  border: 0.5px solid;
  padding: 8px;
  border-radius: 3px;
  font-family: "Lato", sans-serif;
  font-size: 15px;
}

.variation-label,
.issue-label {
  background-color: white;
  font-weight: 400;
  margin-bottom: 3px;
}
.delete-var-header {
  background-color: #f5f5f5;
  width: 105%;
  /* z-index: 1; */
  /* margin-top: -6px; */
  /* margin-left: -5px; */
  position: relative;
  top: -32px;
  left: -12px;
  height: 34px;
}
.delete-variation {
  background-color: transparent;
  text-align: left;
  margin-left: 4px;
  position: relative;
  top: 6px;
  left: 16px;
}
.delete-t-para {
  background-color: white;
  margin-left: 10px;
  margin-top: -25px;
}
.btn-issue-con {
  background-color: white;
  display: flex;
  flex-direction: row;
  margin-left: 67%;
  border-top: 0.5px solid;
  margin-top: -3%;
}
.btn-var-con {
  background-color: white;
  display: flex;
  flex-direction: row;
  margin-left: 67%;
  border-top: 0.5px solid;
  position: relative;
  top: -6%;
}
.btn-var-del-con {
  background-color: white;
  display: flex;
  flex-direction: row;
  margin-left: -4px;
  margin-top: 5px;
  border-top: 0.5px solid;
}
.btn-issue-del-con {
  background-color: white;
  display: flex;
  flex-direction: row;

  margin-left: -4px;
  margin-top: 4px;
  border-top: 0.5px solid;
}
.variation-input {
  margin-bottom: 10px;
  margin-left: -23px;
  border: 1px solid;
  width: 150px;
}
.add-issue-btn {
  position: fixed;
  top: 223px;
  text-wrap: nowrap;
  left: 92%;
  width: auto;
  font-weight: 600;
  padding: 7px 18px;
}
.variation-table-container {
  position: fixed;
  top: 269px;
  left: 76px;
  height: 63%;
  width: 96%;
  overflow-y: auto;
  overflow-x: auto;
  padding-bottom: 46px;
  background-color: transparent;
}
.variation-table-container-close {
  position: fixed;
  top: 269px;
  left: 2px;
  height: 63%;
  width: 99.8%;
  overflow-y: auto;
  overflow-x: auto;
  padding-bottom: 46px;
  background-color: transparent;
}
.variation-summary {
  display: flex;
  position: fixed;
  top: 908px;
  width: 106%;
  border-top: 0.5px solid #ddd;
}
/* .variation-table-container::-webkit-scrollbar {
display: none;
} */
.issue-summary {
  display: flex;
  position: fixed;
  top: 907px;
  height: 50px;
  width: 106%;
  border-top: 0.5px solid #ddd;
}
.variation-para {
  position: fixed;
  top: 901px;
  left: 1728px;
}
.variation-para-hours {
  position: relative;
  top: -9px;
  left: 11px;
}
.issue-para {
  position: fixed;
  top: 901px;
  left: 1748px;
}
.variation-table {
  width: 98%;
  border-collapse: collapse;
  position: relative;
  top: 3%;
  left: 17px;
  overflow-x: auto;
}
.variation-table thead th {
  background-color: white;
  color: black;
  font-weight: 600;
  text-wrap: nowrap;
  text-align: left;
  padding: 10px;
  position: relative;
  border: 1px solid #ddd;
}
.variation-table tbody tr {
  text-align: left;
  border: 1px solid #ddd;
  background-color: white;
}
.variation-table-close {
  width: 98%;
  border-collapse: collapse;
  position: relative;
  top: 2.9%;
  left: 17px;
  overflow-x: auto;
}
.variation-table-close thead th {
  background-color: white;
  color: black;
  font-weight: 600;
  text-wrap: nowrap;
  text-align: left;
  padding: 10px;
  position: relative;
  border: 1px solid #ddd;
}
.variation-table-close tbody tr {
  text-align: left;
  border: 1px solid #ddd;
  background-color: white;
}
.delete-var-issue-btn {
  background-color: red;
  width: auto;
  left: 1654px;
}
.btn-con-second {
  background-color: white;
  display: flex;
  flex-direction: row;
  margin-left: 67%;
  border-top: 0.5px solid;
  margin-top: 0%;
  padding-bottom: 14px;
}
.add-issue-btn-con-second {
  background-color: white;
  display: flex;
  flex-direction: row;
  margin-left: 67%;
  border-top: 0.5px solid;
  margin-top: -3%;
}
.add-variation-btn-con-second {
  background-color: white;
  display: flex;
  flex-direction: row;
  margin-left: 67%;
  border-top: 0.5px solid;
  margin-top: -56px;
}

.actual-hours,
.planned-hours {
  width: 100px;
  text-align: left;
}
.planned-hous-phase-input {
  border: none;
  padding: 5px;
  width: 107px;
  height: 23px;
  text-align: center;
  font-weight: 400;
  font-family: "Lato", sans-serif;
  font-size: 15.5px;
  color: #000;
  background-color: transparent;
}

.general-associated-task-table {
  width: 100px;
  text-align: left;
}
.planned-hous-phase-input:focus {
  border-color: white;
  outline: 3px solid #ffffff;
  height: 35px;
  outline-offset: 2px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
}
.save-action {
  text-align: left;
  width: 150px;
}
.invoice-input {
  padding-left: 5px;
  border: 0.5px solid transparent;
  height: 30px;
  width: 90px;
  border-radius: 3px;
  outline: none;
  font-weight: 400;
  font-family: "Lato", sans-serif;
  font-size: 15px;
  color: black;
  background-color: transparent;
}
.tick-tick {
  height: 30px;
  width: 30px;
  background-color: transparent;
}
.phase-save-btn {
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 3px;
  font-weight: 550;
  margin-left: 30px;
  background-color: transparent;
}
.invoice-element {
  border: 0.5px solid transparent;
  border-radius: 3px;
  padding: 5px;
  width: 200px;
  outline: none;
  font-weight: 400;
  font-family: "Lato", sans-serif;
  font-size: 15px;
  color: black;
  background-color: transparent;
  cursor: pointer;
}
.add-user-task-btn {
  height: 24px;
  width: auto;
  padding: 4px 10px;
  text-align: center;
  color: white;
  border-radius: 3px;
  margin-top: 15px;
  font-weight: 750;
  margin-left: auto;
  margin-right: 10px;
  border: none;
  cursor: pointer;
  background-color: red;
  position: relative;
  left: -1564px;
  top: -1px;
  font-family: "Lato", sans-serif;
  font-size: 13px;
}
.add-user-task-btn-close {
  height: 24px;
  width: auto;
  padding: 4px 10px;
  text-align: center;
  color: white;
  border-radius: 3px;
  margin-top: 15px;
  font-weight: 750;
  margin-left: auto;
  margin-right: 10px;
  border: none;
  cursor: pointer;
  background-color: red;
  position: relative;
  left: -1674px;
  top: -1px;
  font-family: "Lato", sans-serif;
  font-size: 13px;
}
.edit-phase-content {
  background-color: white;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 67%;
  margin-top: -10px;
  width: 31%;
  height: 90.5%;
  padding-left: 15px;
  padding-right: 15px;
}
.export-issue {
  height: auto;
  width: auto;
  padding: 5px 10px;
  text-align: center;
  border: none;
  color: #6200ee;
  border: 1px solid #6200ee;
  border-radius: 3px;
  margin-left: 22px;
  margin-top: 15px;
  font-weight: 750;
  cursor: pointer;
  position: fixed;
  text-wrap: nowrap;
  left: 84%;
  font-weight: 600;
  padding: 6px 18px;
  top: 222px;
  font-family: "Lato", sans-serif;
  font-size: 14px;
}
.task-date-input {
  border: 0.5px solid transparent;
  height: 32px;
  width: 105px;
  padding: 5px;
  font-weight: 400;
  font-family: "Lato", sans-serif;
  font-size: 15px;
  color: black;
  background-color: transparent;
  text-align: center;
}
.task-date-input:focus {
  border-color: white;
  outline: 3px solid #ffffff;
  outline-offset: 2px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
}
.react-datepicker__input-container {
  position: static;
}
.task-planned-hours {
  display: flex;
  text-align: center;
  width: 110px;
}
.task-assigned-user {
  border: 0.5px solid transparent;
  border-radius: 3px;
  width: 210px;
  position: relative;
  left: -82px;
  background-color: transparent;
}
.phase-task-template-table {
  width: 99.9%;
}
.rmsc .task-assigned-user .dropdown-container {
  position: relative;
  width: 322px;
}
.task-assigned-user:focus {
  border-color: white;
  outline: 3px solid #ffffff;
  outline-offset: 2px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
}
.add-general-btn {
  height: auto;
  width: auto;
  padding: 6px 10px;
  text-align: center;
  border: none;
  color: white;
  border-radius: 3px;
  background-color: #6200ee;
  margin-left: 1px;
  margin-top: 15px;
  font-weight: 750;
  cursor: pointer;
  font-family: "Lato", sans-serif;
  font-size: 13px;
}
.variation-status-dropdown {
  border: 0.5px solid;
  border-radius: 4px;
  padding: 5px;
  color: white;
  cursor: pointer;
  font-family: "Lato", sans-serif;
  font-size: 15px;
  height: 34px;
  width: 113px;
  text-align: center;
  outline: none;
}
.variation-status-dropdown option {
  background-color: white; /* Background of the dropdown */
  color: black; /* Text color */
}
.multiselect-issue .dropdown-container {
  position: relative;
  outline: 0;
  background-color: white;
  border: 1px solid;
  border-radius: 3px;
  width: 100%;
  margin-left: -1px;
  max-width: 100%;
}
.modal-overlay {
  position: absolute;
  background-color: white;
  left: 870px;
  top: 81px;
}
.modal-dial {
  position: relative;
  margin-left: 81.5%;
  height: 103%;
  margin-top: -0.7%;
  padding-top: 3px;
  padding-left: 10px;
  padding-right: 20px;
}

.checkbox-group {
  display: flex;
  flex-direction: column;
}

.modal-actions {
  border-top: 1px solid #616161;
  padding-top: 0px;
  position: fixed;
  top: 93%;
  height: 56px;
  width: 17.9%;
  left: 1585px;
  padding-left: 10px;
}

.var-xl-export {
  margin-top: 13px;
  font-weight: 400;
  margin-right: 10px;
  height: 28px;
  background-color: #6200ee;
  color: white;
  font-size: 14px;
}

.var-can-xl-export {
  margin-top: 13px;
  font-weight: 400;
  height: 28px;
  font-size: 14px;
  margin-left: 0px;
}
.filter-var-dial {
  position: fixed;
  text-align: left;
  top: 3.9%;
  left: -0.6%;
  z-index: 2;
  transform: translate(-50%, -20%);
  padding-bottom: 0px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 92.9%;
  border-radius: 3px;
  animation: slideIn 0.5s forwards;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.7);
}
.var-xl-xport-header {
  background-color: #f5f5f5;
  width: 103%;
  z-index: 1;
  margin-top: 3px;
  margin-left: -3%;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  margin-bottom: 0px;
}
.add-variation-select-template {
  border: 0.5px solid black;
  padding: 5px;
  border-radius: 3px;
  margin-top: 13px;
  text-align: center;
  font-family: "Lato", sans-serif;
  font-size: 13px;
  margin-right: 5%;
  cursor: pointer;
}
.add-variation-template {
  color: rgb(98, 0, 238);
  position: relative;
  top: 23%;
  cursor: pointer;
  left: 61%;
  font-size: 20px;
}
.edit-variation-template {
  top: 72%;
  left: 33%;
}

@media (min-width: 1440px) and (max-width: 1919px) {
  .phase-details {
    height: 80%;
  }
  .task-planned-hours {
    width: 142px;
  }
  /* .tick-tick {
    position: relative;
    top: 7px;
  } */
  .export-issue {
    left: 82%;
    font-size: 13px;
  }
  .planned-hours {
    width: 100px;
  }
  .variation-summary {
    top: 693px;
  }
  .issue-summary {
    top: 687px;
  }
  .variation-para {
    top: 688px;
    left: 1328px;
  }
  .issue-para {
    top: 687px;
    left: 1329px;
  }
  .add-variation-dial {
    top: 6.7%;
    left: -0.8%;
    height: 156%;
  }
  .delete-var-issue-btn {
    left: 1312px;
  }
  .delete-var-issue-dial,
  .delete-issue-dial {
    height: 95%;
  }
  .variation-table {
    width: 91%;
    top: 2%;
  }
  .btn-var-con {
    top: 318px;
  }
  .btn-issue-con {
    margin-top: 10px;
  }
  .accordion {
    width: 92vw;
  }
  .btn-con-second {
    margin-top: -22px;
    width: 33.4%;
  }
  .add-variation-content {
    height: 51.8%;
    width: 31.5%;
  }
  .add-variation-btn-con-second {
    margin-top: -4px;
    padding-bottom: 10px;
    width: 33.5%;
  }
  .add-issue-btn-con-second {
    margin-top: -4px;
    padding-bottom: 10px;
    width: 33.5%;
  }
  .add-user-task-btn {
    left: -1194px;
  }
  .add-user-task-btn-close {
    left: -1285px;
  }
  .edit-phase-content {
    height: 57.1%;
    width: 31.5%;
  }
  .delete-btn-issue-del-con {
    width: 108%;
  }
  .task-name {
    width: 250px;
  }
  .add-edit-issue-btn {
    font-size: 13px;
  }
  .dial-v-header {
    width: 34%;
  }
  .variation-table-container {
    top: 271px;
    height: 367px;
  }

  .variation-table-container-close {
    top: 268px;
    height: 367px;
    width: 108.1%;
  }
  .modal-actions {
    top: 90%;
    width: 16.9%;
    left: 1272px;
  }
  .var-xl-xport-header {
    margin-top: 6px;
  }
  .variation-table-close {
    width: 90.2%;
  }
}
