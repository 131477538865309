/* Full-page container */
.phases-fullpage-container {
  /* display: flex;
  flex-direction: column;
  height: 90vh;
  padding: 50px;
  box-sizing: border-box;
  background-color: #f8f9fa;
  position: fixed;
  top: 0;
  left: 25;
  width: 90%;
  z-index: 9999; */
  position: fixed;
  top: 6%;
  left: 5%;
  transform: translate(-50%, -50%);
  width: 94%;
  height: 860px;
  border: 0.5px solid #ddd;
  animation: fadeInCenter 1s forwards;
  overflow: auto;
}

/* Header with Back button */
.phases-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px;
}
.add-e-phase-message {
  position: relative;
  top: -10px;
  left: 211px;
  margin-right: 249px;
}
.back-button {
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  font-size: 22px;
  color: blue;
}

/* Phases List */
.phases-list {
  flex-grow: 1;
  /* Take up available space */
  overflow-y: auto;
  list-style: none;
  padding: 0;
}
.update-e-template-btn {
  height: 27px;
  width: auto;
  padding: 6px 8px;
  text-align: center;
  border: none;
  color: white;
  border-radius: 3px;
  background-color: #6200ee;
  font-weight: 750;
  cursor: pointer;
  position: relative;
  top: 13px;
  left: 4px;
}
.e-ph-hr {
  border: none;
  width: 127px;
  height: 29px;
  padding: 5px;
  text-align: center;
  border-radius: 3px;
  position: relative;
  cursor: pointer;
  font-family: "Lato", sans-serif;
  font-size: 15px;
}
.e-ph-hr:focus {
  border-color: white;
  outline: 3px solid #ffffff;
  outline-offset: 2px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
}
.task-e-name {
  flex-grow: 1;
}
.task-pl-hr {
  margin-right: 45px;
}
.e-ph-hr {
  margin-right: 444px;
}
.e-select-template {
  width: 200px;
  height: 24px;
  border: 0.5px solid;
  border-radius: 3px;
  position: relative;
  left: 27px;
  top: 14px;
  padding: 3px;
  margin-right: 50px;
  cursor: pointer;
  font-family: "Lato", sans-serif;
  font-size: 13px;
}
.e-task-list {
  width: 1000px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.phase-item {
  padding: 10px;
  margin-bottom: 8px;
  border: 0.5px solid;
  border-radius: 3px;
  margin: 14px;
}

/* Input Section */
.phase-e-input {
  display: flex;
  gap: 10px;
  position: relative;
  left: -717px;
  top: 38px;
}
.add-e-phase-btn {
  width: auto;
  padding: 5px 10px;
  text-align: center;
  border: none;
  color: white;
  border-radius: 3px;
  background-color: #6200ee;
  font-weight: 750;
  cursor: pointer;
  position: relative;
  top: 3px;
  height: 25px;
}

.phase-e-input input {
  flex-grow: 1;
  padding: 5px;
  border: 0.5px solid;
  border-radius: 3px;
  height: 20px;
  width: 250px;
}

/* .phase-e-input button {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 4px;
}

.phase-e-input button:hover {
  background-color: #218838;
} */

/* Error Message */
.error {
  color: red;
  font-size: 14px;
  margin-bottom: 10px;
}
.Toastify__toast-theme--light {
  background: transparent;
}
@media (min-width: 1440px) and (max-width: 1919px) {
}
